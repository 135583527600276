import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { QuotationService } from '../quotation.service';

@Component({
  selector: 'app-delete-quotation-dialog',
  templateUrl: './delete-quotation-dialog.component.html',
  styleUrls: ['./delete-quotation-dialog.component.scss']
})
export class DeleteQuotationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteQuotationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private quotationService: QuotationService,
    private snackBar: MatSnackBar) { 
      dialogRef.disableClose = true;
    }

    onNoClick(): void {
      this.dialogRef.close();
    }
  
    ngOnInit() {}
  
    handleDeleteQuotation() {
      
      this.apiService.deleteQuotation(this.object.quotation_id).subscribe(
        (response: any) => {
          this.apiService.getAllQuotations().subscribe((data: any) => {
            this.quotationService.bindQuotations(data);
          });
          this.snackBar.open("Deleted successfully", null, {
            duration: 2000,
            horizontalPosition: "right"
          });
        },
        error => {
          this.snackBar.open("Delete failed!", null, {
            duration: 2000,
            panelClass: ["red-snackbar"],
            horizontalPosition: "right"
          });
        }
      );
    }
}
