import {Component, OnInit} from '@angular/core';
import {ActivationStart, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {filter, map} from 'rxjs/operators';
import { UserLoginService } from './shared/userlogin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
              private title: Title,
              private userLoginService: UserLoginService) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(x => x instanceof ActivationStart),
        filter(x => x['snapshot']),
        filter(x => x['snapshot']['data']),
        filter(x => x['snapshot']['data']['title']),
        map(x => x['snapshot']['data']['title'])
      ).subscribe(event => {
      this.title.setTitle(event);
    });

    const timer = JSON.parse(localStorage.getItem('timer'));
    if (timer && (Date.now() > timer)) {
      this.signOut();
      this.router.navigate(['/login']);
    }
  }

  signOut() {
    this.userLoginService.deleteToken();
    this.router.navigateByUrl('');
  }
}
