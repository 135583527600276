import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  click_directory: Array<object>;
  arr_directory: any;
  sub_directory: Array<object>;
  path_directory: any;
  files: Array<object>;
  parent_id: string;
  list_selected_id: Array<string>;
  activeDirectory: string;
  directoriesChangeStatus: boolean = false;

  private behavior_subject_click_directory = new BehaviorSubject<any>(this.click_directory);
  private behavior_subject_list_directory = new BehaviorSubject<any>(this.arr_directory); 
  private behavior_subject_sub_directory = new BehaviorSubject<any>(this.sub_directory); 
  private behavior_subject_path_directory = new BehaviorSubject<any>(this.path_directory); 
  private behavior_subject_files = new BehaviorSubject<any>(this.files); 
  private behavior_subject_parent_id = new BehaviorSubject<string>(this.parent_id); 
  private behavior_subject_list_selected_id = new BehaviorSubject<any>(this.list_selected_id); 
  
  clickedDir = this.behavior_subject_click_directory.asObservable();
  newListDir = this.behavior_subject_list_directory.asObservable();
  getDirectories = this.behavior_subject_sub_directory.asObservable();
  pathDir = this.behavior_subject_path_directory.asObservable();
  getFiles = this.behavior_subject_files.asObservable();
  getParentID = this.behavior_subject_parent_id.asObservable();
  getListSelectedID = this.behavior_subject_list_selected_id.asObservable();

  constructor() { }

  bindClickDirectory(click_dir: any) { 
    this.behavior_subject_click_directory.next(click_dir);
  }

  updateDirBlock(list_directory: any) {
    this.behavior_subject_list_directory.next(list_directory);
  }

  bindSubDir(sub_item: any) {
    this.behavior_subject_sub_directory.next(sub_item);
  }

  bindPath(path_dir: any) {
    this.behavior_subject_path_directory.next(path_dir);
  }

  bindFiles(files: any) {
    this.behavior_subject_files.next(files);
  }

  bindParentID(ID: string) {
    this.behavior_subject_parent_id.next(ID);
  }

  bindListSelectedID(list: any) {
    this.behavior_subject_list_selected_id.next(list);
  }
}
