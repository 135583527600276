import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectFileService {

  projectFiles: Array<object> = [];
  selectedProjectFile: any;
  private bs_all_project_files = new BehaviorSubject<any>(this.projectFiles);
  private bs_selected_project_file = new BehaviorSubject<any>(this.selectedProjectFile);



  getAllProjectFiles = this.bs_all_project_files.asObservable();
  getSelectedProjectFile = this.bs_selected_project_file.asObservable();

  constructor() {}

  bindProjectFiles(projectFiles: any) {
    this.bs_all_project_files.next(projectFiles);
  }

  bindSelectedProjectFile(projectFile: any) {
    this.bs_selected_project_file.next(projectFile);
  }
}