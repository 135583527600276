import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component, OnInit, Injectable, Input } from '@angular/core';
import { UserService } from 'src/app/shared/user.service';

@Component({
    selector: 'confirm-cancel',
    templateUrl: 'confirm-cancel.component.html',
    styleUrls: ['confirm-cancel.component.css']
  })
  export class ConfirmCancelComponent implements OnInit {
  
    constructor(public dialogRef: MatDialogRef<ConfirmCancelComponent>, 
                @Inject(MAT_DIALOG_DATA) public object: any, 
                private apiService: ApiService,  
                private userService: UserService,
                private snackBar: MatSnackBar) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }

    ngOnInit() {
    }
  
    handleCancelLeaveRequest() {
      this.apiService.cancelLeaveRequest(this.object.request._id)
      .subscribe(response => {
        if (response.status) {
          this.object.request.status = 'canceled';
          this.dialogRef.close();
          this.snackBar.open('Canceled successfully', null, {duration: 2000, horizontalPosition: 'right'});
        }
      });
    }
}








 