import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../../shared/api.service';
import {MatDatepickerInputEvent, MatDialog, MatSnackBar, MatTabChangeEvent} from '@angular/material';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AttendanceStatusType} from '../../../../models/attendance.model';
import {filter, map, pairwise} from 'rxjs/operators';
import {LeaveTypeModel} from '../../../../models/leave-type.model';
import {format} from 'date-fns';
import {FileModel, LeaveRequestModel, LeaveShift} from '../../../../models/leave-request.model';
import {HttpEventType} from '@angular/common/http';
import {ContentScrollService} from '../../../../shared/content-scroll.service';
import { UserPagination } from 'src/app/models/user.model';
import { UserLoginService } from 'src/app/shared/userlogin.service';
import { ConfirmCancelComponent } from './confirm-cancel-dialogs/confirm-cancel.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger('leaveForm', [
      state('void', style({opacity: 0, transform: 'translateY(20px) scale(.95)'})),
      transition('void => *', animate('.3s ease'))
    ]),
    trigger('fade', [
      state('void', style({opacity: 0, transform: 'translateY(20px)'})),
      transition('void => *', animate('.5s ease'))
    ]),
  ]
})
export class AttendanceComponent implements OnInit, OnDestroy {

  showLeaveForm = false;
  leaveForm: FormGroup;
  currentDate = new Date();
  isSameDate = true;
  attendanceStatus: AttendanceStatusType;
  leaveTypes: LeaveTypeModel[] = [];
  publicHoliday = new Map<string, boolean>();
  files: File[] = [];
  checkInOrOutLoading = true;
  leaveRequests: LeaveRequestModel[] = [];
  leaveRequestsLoading = true;
  leaveRequestsLoadingAll = true;
  remainingAnnualLeaves = 0;
  isEditingLeaveForm = false;
  count_leave_requests: number = 0;
  userPagination = new UserPagination();
  users: Array<object> = [];
  currentUserRoleName: string;
  managers: Array<object> = [];
  roleNameManager: string = "Manager";
  roleNameCEO: string = "CEO";
  roleNameCTO: string = "CTO";
  roleNameStaff: string = "Staff";
  currentUserID: string = '';
  leaveRequestID: string = '';
  currentUserLeaveRequests: LeaveRequestModel[] = [];
  delete_file_ids: Array<string> = [];
  oldFiles: any = [];
  newFiles: any = [];
  isCheckedAll: boolean = false; 
  isLoading: boolean = true;

  private currentPage = 1;
  private currentPageAll = 1;
  private limit = 10;
  private limitAll = 10;
  private maxPage = 1;
  private maxPageAll = 1;

  constructor(private api: ApiService,
              private dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private User: MatSnackBar,
              private datePipe: DatePipe,
              private scroll: ContentScrollService,
              private userLoginService: UserLoginService) {

    this.leaveForm = this.formBuilder.group({
      startDate: [this.currentDate, Validators.required],
      endDate: [this.currentDate, Validators.required],
      startMorningShift: [true, Validators.required],
      startAfternoonShift: [true, Validators.required],
      endMorningShift: [true, Validators.required],
      endAfternoonShift: [true, Validators.required],
      leaveType: ['', Validators.required],
      manager: ['', Validators.required],
      reason: ['', Validators.required],
      files: [[]],
    });


    this.validateDates(this.currentDate, this.currentDate);
  }


  ngOnDestroy(): void {

  }

  onTabChanged(event: MatTabChangeEvent) 
  {
    if(event.index == 0) {
      this.isCheckedAll = true;
    } else {
      this.isCheckedAll = false;
    }
    console.log(this.isCheckedAll);
  }

  private validateDates(startDate: Date, endDate: Date) {
    endDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    const isTheSameDate = startDate.getTime() === endDate.getTime();
    this.isSameDate = isTheSameDate;

    if (isTheSameDate) {
      this.leaveForm.controls['endAfternoonShift'].disable();
      this.leaveForm.controls['endMorningShift'].disable();

      if (startDate.getDay() === 6) {
        this.leaveForm.controls['startMorningShift'].disable();
        this.leaveForm.controls['startAfternoonShift'].disable();
        this.leaveForm.patchValue({
          startMorningShift: true,
          startAfternoonShift: false,
        });
      } else {
        this.leaveForm.controls['startAfternoonShift'].enable();
        this.leaveForm.controls['startMorningShift'].enable();
        this.leaveForm.patchValue({
          startMorningShift: true,
          startAfternoonShift: true,
        });
      }

      this.leaveForm.patchValue({
        endMorningShift: false,
        endAfternoonShift: false,
      });

    } else {

      this.leaveForm.controls['startMorningShift'].enable();
      this.leaveForm.controls['startAfternoonShift'].disable();
      this.leaveForm.controls['endAfternoonShift'].enable();
      this.leaveForm.controls['endMorningShift'].disable();

      this.leaveForm.patchValue({
        endAfternoonShift: true,
        endMorningShift: true,
      });


      if (startDate.getDay() === 6) {
        this.leaveForm.patchValue({
          startMorningShift: true,
          startAfternoonShift: false,
        });
        this.leaveForm.controls['startMorningShift'].disable();
      } else {
        this.leaveForm.controls['startMorningShift'].enable();
        this.leaveForm.patchValue({
          startAfternoonShift: true,
        });
      }


      if (endDate.getDay() === 6) {
        this.leaveForm.controls['endAfternoonShift'].disable();
        this.leaveForm.patchValue({
          endAfternoonShift: false,
        });
      }
    }
  }


  holidayDateFilter(date: Date): boolean {
    let isHoliday = false;
    if (this.publicHoliday) {
      if (this.publicHoliday.size) {
        if (this.publicHoliday.has(format(date, 'DD/MM/YYYY'))) {
          isHoliday = true;
        }
      }
    }

    const day = date.getDay();
    return day !== 0 && !isHoliday;
  }


  ngOnInit(): void {
    this.isLoading = true;
    this.api.getManagers().subscribe((managers: any) => {
      this.managers = managers;
    });
    this.currentUserID = this.userLoginService.getCurrentUserID();
    this.currentUserRoleName = this.userLoginService.getCurrentUserRoleName();

    this.api.getLeaveNotification(this.currentUserID).subscribe((response: any) => {
      this.count_leave_requests = response.leave_requests.length;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    });

    this.route.queryParams.subscribe(params => {
      const showForm = params['show-form'];
      this.showLeaveForm = !!showForm && (showForm === 'true');
    });

    this.leaveRequests = [];
    this.currentUserLeaveRequests = [];
    this.refreshAttendanceStatus();
    this.refreshLeaveTypes();
    this.getPublicHolidays();
    this.getLeaveRequests();
    this.getMyLeaveRequests();
    this.getRemainingAnnualLeaves();

    const offset = 1000;
    const offsetAll = 1000;

    const scrollDownAll = this.scroll.getContentScrollObservable()
      .pipe(filter(x => {
          const el = x.target as HTMLDivElement;
          const a = el.scrollHeight - el.scrollTop - offsetAll;
          return a <= el.clientHeight && !this.leaveRequestsLoadingAll && this.currentPageAll <= this.maxPageAll;
        }),
        map(x => {
          const el = x.target as HTMLDivElement;
          return el.scrollHeight - el.scrollTop;
        }), pairwise(), filter(arr => arr[0] > arr[1]));

    scrollDownAll.subscribe(res => {
      if(this.isCheckedAll) {
        this.getLeaveRequests(10, this.currentPageAll + 1);
      }
    });

    const scrollDownMe = this.scroll.getContentScrollObservable()
      .pipe(filter(x => {
          const el = x.target as HTMLDivElement;
          const a = el.scrollHeight - el.scrollTop - offset;
          return a <= el.clientHeight && !this.leaveRequestsLoading && this.currentPage <= this.maxPage;
        }),
        map(x => {
          const el = x.target as HTMLDivElement;
          return el.scrollHeight - el.scrollTop;
        }), pairwise(), filter(arr => arr[0] > arr[1]));

    scrollDownMe.subscribe(res => {
      if(!this.isCheckedAll) {
        this.getMyLeaveRequests(10, this.currentPage + 1);   
      }
    });
  }


  getPublicHolidays() {
    this.api.getArrayOfPublicHoliday()
      .pipe(map(x => x.public_holidays))
      .subscribe(holidays => {
        if(holidays != undefined)
          this.mapHoliday(holidays);
      });
  }

  refreshAttendanceStatus() {
    this.checkInOrOutLoading = true;
    this.api.getAttendanceStatus()
      .pipe(map(x => x.type))
      .subscribe(response => {
        this.attendanceStatus = response;
        this.checkInOrOutLoading = false;
      });
  }


  refreshLeaveTypes() {
    this.api.getLeaveTypes()
      .pipe(map(x => x.leave_types))
      .subscribe(types => {
        this.leaveTypes = types;
        if (this.leaveTypes.length) {
          this.leaveForm.patchValue({
            leaveType: types[0]._id,
          });
        }
      });
  }

  toggleShowLeaveForm() {
    this.showLeaveForm = !this.showLeaveForm;
    this.navigateShowLeaveForm(this.showLeaveForm);
  }

  navigateShowLeaveForm(showForm: boolean) {
    this.router.navigate([], {
      queryParams: {
        'show-form': showForm,
      }, replaceUrl: true
    });
  }

  checkOut() {
    this.checkInOrOutLoading = true;
    this.api.attendanceCheckOut().subscribe(response => {
      if (response.status) {
        this.refreshAttendanceStatus();
        this.showCheckInOrOutMessage('Check out successfully');
        this.checkInOrOutLoading = false;
      }
      this.checkInOrOutLoading = true;
    });
  }

  checkIn() {
    this.checkInOrOutLoading = true;
    this.api.attendanceCheckIn()
      .subscribe(response => {
        if (response.status) {
          this.checkInOrOutLoading = false;
          this.showCheckInOrOutMessage('Check in successfully');
          this.refreshAttendanceStatus();
        }
        this.checkInOrOutLoading = true;
      });
  }

  private showCheckInOrOutMessage(message: string) {
    this.snackBar.open(message, null, {duration: 2000, horizontalPosition: 'end'});
  }

  private mapHoliday(holidays: string[]) {
    this.publicHoliday.clear();
    holidays.forEach(date => {
      const formattedDate = format(date, 'DD/MM/YYYY');
      this.publicHoliday.set(formattedDate, true);
    });
  }

  fileChanged(fileEvent: Event) {
    const target = fileEvent.target as HTMLInputElement;
    this.newFiles.push(...Array.from(target.files));

    this.leaveForm.patchValue({
      files: [...this.leaveForm.value.files, ...this.newFiles],
    });
  }

  requestForApproval() {
    const value = this.leaveForm.getRawValue() as AttendanceFormValues;
    const startShift = this.getStartShift(value);
    const endShift = this.getEndShift(value);

    if (startShift === null) {
      this.snackBar.open('Start time shift is needed', null, {duration: 2000, horizontalPosition: 'right'});
      return;
    }

    this.leaveForm.disable();
    this.api.createLeaveRequest({
      start_date: this.datePipe.transform(value.startDate, 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(value.endDate, 'yyyy-MM-dd'),
      start_date_shift: startShift,
      end_date_shift: endShift,
      reason: value.reason,
      leave_type: value.leaveType,
      manager: value.manager
    }, value.files).subscribe(response => {
      switch (response.type) {
        case HttpEventType.Sent:
          this.leaveForm.disable();
          break;
        case HttpEventType.UploadProgress:
          // this.snackBar.open('Uploading...', null, {duration: 2000, horizontalPosition: 'right'});
          break;
        case HttpEventType.ResponseHeader:

          break;
        case HttpEventType.DownloadProgress:

          break;
        case HttpEventType.Response:
          this.leaveRequests.length = 0;
          this.currentUserLeaveRequests.length = 0;
          this.leaveForm.enable();
          this.resetLeaveForm();
          this.getMyLeaveRequests();
          this.snackBar.open('Created successfully', null, {duration: 2000, horizontalPosition: 'right'});
          break;
      }
    }, error => {
      this.leaveForm.enable();
      this.snackBar.open('Error while uploading...', 'Retry', {duration: 2000, horizontalPosition: 'right'});
    });

  }

  viewFile(file: FileModel) {
    window.open(this.api.getLeaveRequestDownloadUrl(file._id), '_blank');
  }

  removeOldFile(file: FileModel, index: number) {
    this.delete_file_ids.push(file._id);
    this.oldFiles.splice(index, 1);

    const value = this.leaveForm.getRawValue() as AttendanceFormValues;
    value.files.splice(index, 1);
    this.leaveForm.patchValue({
      files: value.files
    })
  }

  removeNewFile(file: FileModel, index: number) {
    this.newFiles.splice(index, 1);

    const value = this.leaveForm.getRawValue() as AttendanceFormValues;
    value.files.splice(this.oldFiles.length + index, 1);
    this.leaveForm.patchValue({
      files: value.files
    })
  }

  clearAllFiles(files: Array<FileModel>) {
    this.delete_file_ids = [];
    this.newFiles = [];
    this.oldFiles = [];
    files.forEach((file: FileModel) => {
      this.delete_file_ids.push(file._id);
    });

    this.leaveForm.patchValue({
      files: [],
    });
  }


  private getEndShift(value: AttendanceFormValues): LeaveShift {
    if (!value.endAfternoonShift && !value.endMorningShift) {
      return null;
    }

    if (value.endAfternoonShift && value.endMorningShift) {
      return 'full';
    }

    if (value.endAfternoonShift) {
      return 'afternoon';
    }

    return 'morning';
  }

  private getStartShift(value: AttendanceFormValues): LeaveShift {
    if (!value.startAfternoonShift && !value.startMorningShift) {
      return null;
    }

    if (value.startAfternoonShift && value.startMorningShift) {
      return 'full';
    }

    if (value.startAfternoonShift) {
      return 'afternoon';
    }

    return 'morning';
  }

  startDateChanged(event: MatDatepickerInputEvent<Date>) {
    const startDate = new Date(event.target.value);
    this.leaveForm.patchValue({
      endDate: startDate
    });

    this.startAndEndChanges();

    const isSat = startDate.getDay() === 6;
    if(isSat) {
      this.leaveForm.patchValue({
        endMorningShift: false,
        endAfternoonShift: false,
      });
      this.leaveForm.controls['endAfternoonShift'].disable();
      this.leaveForm.controls['endMorningShift'].disable();
    }
  }

  endDateChanged(event: MatDatepickerInputEvent<Date>) {
    const endDate = new Date(event.target.value);
    this.leaveForm.patchValue({
      endDate: endDate
    });

    this.startAndEndChanges();
  }

  startAndEndChanges() {
    const startDate = this.leaveForm.value.startDate as Date;
    const endDate = this.leaveForm.value.endDate as Date;
    this.validateDates(startDate, endDate);
  }


  calculateNumberOfDays(start: string, end: string, startShift: string = 'full', endShift: string = 'full') {
    const startDate = new Date(start);
    const endDate = new Date(end);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(0, 0, 0, 0);

    let days = 0;

    for (const date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      const formattedDate = format(date, 'DD/MM/YYYY');
      const isSunday = date.getDay() === 0;
      const isSat = date.getDay() === 6;
      const isHoliday = this.publicHoliday.has(formattedDate);


      if (isHoliday) {
        continue;
      }

      if (isSat) {
        days += 0.5;
        continue;
      }


      if (!isSunday) {
        days++;
      }
    }

    if (startDate.getDay() !== 6 && startShift !== 'full') {
      days -= 0.5;
    }

    if ((endShift === 'morning') && endDate.getDay() !== 6) {
      days -= 0.5;
    }



  }

  private getLeaveRequests(limit: number = 10, currentPage = 1) {
    this.leaveRequestsLoadingAll = true;
    this.getRemainingAnnualLeaves();
    this.api.getAnnualLeaveRequests(limit, currentPage).subscribe(response => {
      console.log("all leave request");
      console.log(response);
      if (response.status) {
        this.leaveRequests.push(...response.annual_leaves);

        this.currentPageAll = currentPage;

        this.limitAll = limit;
        this.maxPageAll = response.pagination.total_pages;
      }
      this.leaveRequestsLoadingAll = false;
    }, error => {
      this.leaveRequestsLoadingAll = false;
      console.error(error);
    });
  }

  
  public getMyLeaveRequests(limit: number = 10, currentPage = 1) {
    this.leaveRequestsLoading = true;
    this.getRemainingAnnualLeaves();
    this.api.getMyAnnualLeaveRequests(limit, currentPage, this.currentUserID).subscribe(response => {
      console.log("my leave resquest");
      console.log(response);
      if (response.status) {
        this.currentUserLeaveRequests.push(...response.annual_leaves);

        this.currentPage = currentPage;
        this.limit = limit;
        this.maxPage = response.pagination.total_pages;
      }
      this.leaveRequestsLoading = false;
    }, error => {
      this.leaveRequestsLoading = false;
      console.error(error);
    });
  }

  normalizeShift(start: string, end: string): string {
    if (start === end) {
      return this.firstCap(start);
    }

    if (start && end) {
      return `${this.firstCap(start)} - ${this.firstCap(end)}`;
    }

    if (start) {
      return this.firstCap(start);
    }

    return null;
  }

  firstCap(value: string): string {
    if (value === 'full') {
      return value.charAt(0).toUpperCase() + value.slice(1) + ' day';
    }

    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  private resetLeaveForm() {
    if (this.isEditingLeaveForm) {
      this.isEditingLeaveForm = false;
    }

    let leaveType = null;
    if (this.leaveTypes.length) {
      leaveType = this.leaveTypes[0]._id;
    }

    this.leaveForm.reset({
      files: [],
      startDate: this.currentDate,
      endDate: this.currentDate,
      reason: '',
      leaveType
    });

    this.validateDates(this.currentDate, this.currentDate);
  }

  private getRemainingAnnualLeaves() {
    this.api.getAnnualLeaveCount().subscribe(response => {
      if (response.annual_leave != null) {
        this.remainingAnnualLeaves = response.annual_leave.remaining_days;
      }
    });
  }


  showConfirmCancelDialog(request: any) {
    const dialogRef = this.dialog.open(ConfirmCancelComponent, {
      width: '400px',
      height: 'auto',
      data: {
        request: request
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        this.currentUserLeaveRequests = [];
        this.getMyLeaveRequests();
        this.getRemainingAnnualLeaves();
    });
  }

  showRequestDataForEdit(request: LeaveRequestModel) {
    this.isEditingLeaveForm = true;
    this.showLeaveForm = true;
    this.navigateShowLeaveForm(true);
    this.patchDataToLeaveForm(request);
  }

  private patchDataToLeaveForm(request: LeaveRequestModel) {
    const startDate = new Date(request.start_date);
    const endDate = new Date(request.end_date);

    const formattedStartDate = format(startDate, 'DD/MM/YYYY');
    const formattedEndDate = format(endDate, 'DD/MM/YYYY');
    let startMorningShift = false;
    let startAfternoonShift = false;
    let endMorningShift = false;
    let endAfternoonShift = false;
    this.leaveRequestID = request._id;

    if (formattedEndDate === formattedStartDate) {
      if (request.start_date_shift === 'full') {
        startMorningShift = true;
        startAfternoonShift = true;
      } else if (request.start_date_shift === 'morning') {
        startMorningShift = true;
        startAfternoonShift = false;
      } else {
        startMorningShift = false;
        startAfternoonShift = true;
      }
      endMorningShift = false;
      endAfternoonShift = false;
    } else {
      endMorningShift = true;
      if (request.end_date_shift) {
        endAfternoonShift = request.end_date_shift === 'full';
      }
    }
    
    const valueToPatch = {
      startDate,
      endDate,
      startMorningShift,
      startAfternoonShift,
      endMorningShift,
      endAfternoonShift,
      leaveType: request.leave_type._id,
      manager: request.manager,
      reason: request.reason,
      files: request.files
    };

    this.oldFiles = [];
    this.newFiles = [];
    this.oldFiles.push(...request.files);
    this.leaveForm.patchValue(valueToPatch);
    this.validateDates(startDate, endDate);
  }

  cancelLeaveForm() {
    this.resetLeaveForm();
    this.showLeaveForm = false;
  }

  editLeaveRequest() {
    const value = this.leaveForm.getRawValue() as AttendanceFormValues;
    const startShift = this.getStartShift(value);
    const endShift = this.getEndShift(value);

    if (startShift === null) {
      this.snackBar.open('Start time shift is needed', null, {duration: 2000, horizontalPosition: 'right'});
      return;
    }

    this.leaveForm.disable();

    this.api.createLeaveRequest({
      _id: this.leaveRequestID,
      start_date: this.datePipe.transform(value.startDate, 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(value.endDate, 'yyyy-MM-dd'),
      start_date_shift: startShift,
      end_date_shift: endShift,
      reason: value.reason,
      leave_type: value.leaveType,
      manager: value.manager
    }, value.files, this.delete_file_ids).subscribe(response => {
      switch (response.type) {
        case HttpEventType.Sent:
          this.leaveForm.disable();
          break;
        case HttpEventType.UploadProgress:
          // this.snackBar.open('Uploading...', null, {duration: 2000, horizontalPosition: 'right'});
          break;
        case HttpEventType.ResponseHeader:

          break;
        case HttpEventType.DownloadProgress:

          break;
        case HttpEventType.Response:
          // this.leaveRequests.length = 0;
          this.currentUserLeaveRequests.length = 0;
          this.leaveForm.enable();
          this.resetLeaveForm();
          
          this.getMyLeaveRequests();
          this.snackBar.open('Updated successfully', null, {duration: 2000, horizontalPosition: 'right'});
          break;
      }
      this.delete_file_ids = [];
      this.newFiles = [];
      this.oldFiles = [];
    }, error => {
      console.error(error);
      this.leaveForm.enable();
      this.snackBar.open('Error while uploading...', 'Retry', {duration: 2000, horizontalPosition: 'right'});
    });
  }
}

export interface AttendanceFormValues {
  startDate?: Date;
  endDate?: Date;
  files?: File[];
  startMorningShift?: boolean;
  startAfternoonShift?: boolean;
  endMorningShift?: boolean;
  endAfternoonShift?: boolean;
  leaveType?: string;
  manager?: string;
  reason?: string;
}
