import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../../../shared/api.service';
import { OfficeModel } from '../../../../models/office.model';
import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/shared/excel.service';


@Component({
  selector: 'app-attendance-report',
  templateUrl: './attendance-report.component.html',
  styleUrls: ['./attendance-report.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0, transform: 'translateY(20px)' })),
      transition('void => *', animate('.5s ease'))
    ]),
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateX(-40px)' }),
            stagger(
              '60ms',
              animate(
                '400ms ease',
                style({ opacity: 1, transform: 'translateX(0px)' })
              )
            )
          ],
          { optional: true }
        ),
        query(':leave', animate('50ms', style({ opacity: 0 })), {
          optional: true
        })
      ])
    ])
  ]
})
export class AttendanceReportComponent implements OnInit, OnDestroy {

  offices: OfficeModel[] = [];
  isLoading = true;
  collapseHash = new Map<string, boolean>();
  subscription = new Subscription();
  formGroup: FormGroup;
  date = new Date();
  start_date: any;
  end_date: any;
  attendanceList: any = [{
    no: "",
    name: "",
    position: "",
    office: "",
    used_leave: "",
    remaining_leave: ""
  }];
  public displayedColumns = ['no', 'name', 'position', 'office', 'used_leave', 'remaining_leave'];


  constructor(private api: ApiService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private excelService: ExcelService,
    private dialog: MatDialog) {

    this.formGroup = this.formBuilder.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.patchValueToForm();
    this.getAttendanceReport();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  refreshListReports() {
    this.getAttendanceReport();
  }

  getAttendanceReport() {
    this.isLoading = true;
    let start_date = this.datePipe.transform(this.formGroup.value.start_date, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.formGroup.value.end_date, 'yyyy-MM-dd');
    this.api.getAttendanceReport(start_date, end_date).subscribe((res) => {
      this.attendanceList  = [];
      this.attendanceList = res;

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    });
  }

  patchValueToForm() {
    this.formGroup.patchValue({
      start_date: new Date(this.date.getFullYear(), this.date.getMonth(), 1),
      end_date: new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
    });
  }

  printAttendanceReport() {
    const values = this.formGroup.value;
    this.start_date = this.datePipe.transform(values.start_date, 'dd-MM-yy');
    this.end_date = this.datePipe.transform(values.end_date, 'dd-MM-yy');
    var mywindow = window.open("", "PRINT", "height=700,width=1000");

    mywindow.document.write(
      "<html><head><title>" +
      document.title +
      "</title>" +
      `   
          <style>
            @font-face {
              font-family: 'KhmerOS';
              src: url('./../../../../../assets/fonts/KhmerOS.ttf');
            }
        
            @font-face {
              font-family: 'KhmerOSMoulLight';
              src: url('./../../../../../assets/fonts/KhmerOSMoulLight.ttf');
            }
        
            html, body {
              width: 750px;
              padding: 40px;
              font-family: 'KhmerOS';
              font-size: 14px;
              line-height: 18px;
              position: relative;
            } 
        
            table {
              width: 100%;
              border-collapse: collapse;
            }
        
            table, th, td {
              border: 1px solid #000000;
            }
        
            th, td {
              padding: 5px !important;
              font-size: 14px;
            }
        
            .center {
              text-align: center;
            }
        
            .space {
              height: 40px;
            }
        
            .top-header {
              font-family: 'KhmerOSMoulLight';
              font-size: 15px;
              margin-top: 60px;
            }

            .logo-style {
              width: 160px;
              position: absolute;
              top: 40px;
            }

            .logo-right {
              display: block;
              text-align: right;
              color: #185f9f;
              font-size: 13px;
              line-height: 12px;
              font-family: 'KhmerOSMoulLight';
            }

            .block-logo-right {
              position: absolute;
              top: 35px;
              right: 42px;
            }

          </style>
        `
    );
    mywindow.document.write("</head><body >" + `

      <img class="logo-style" src="./../../../../../assets/images/logo@800x.png"/>
      
      <div class="block-logo-right">
        <p class="logo-right">ក្រុមហ៊ុន ឧបធីតេជ</p>
        <p class="logo-right">OPTISTECH</p>
      </div>
            
      <p class="center top-header">របាយការណ៍</p>
      <p class="center">ចាប់ពីថ្ងៃទី `+ this.start_date + ` ដល់ ‌‌‌‌` + this.end_date + `</p>
      <div class="space"></div>
      `);
    console.log(document.getElementById("print_report"));
    mywindow.document.write(document.getElementById("print_report").innerHTML);
    mywindow.document.write("</body></html>");
    
    setTimeout(function() {
      mywindow.print();
      mywindow.close();
    }, 1000); 
  }

  exportAttendanceReport() {
    let exportAttendanceList = [];
    this.attendanceList.forEach(element => {
      exportAttendanceList.push({
        no: this.attendanceList.indexOf(element) + 1,
        name: element.first_name + element.last_name,
        position: element.position.name,
        office: element.office.name,
        used_leave: element.num_absence,
        remaining_leave: element.remaining_days
      });
    });
    this.excelService.exportAsExcelFile(exportAttendanceList, 'attendance_report');
  }
}
