import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  suppliers: Array<object> = [];
  selectedSupplier: any;
  private bs_all_suppliers = new BehaviorSubject<any>(this.suppliers);
  private bs_selected_supplier = new BehaviorSubject<any>(this.selectedSupplier);



  getAllSuppliers = this.bs_all_suppliers.asObservable();
  getSelectedSupplier = this.bs_selected_supplier.asObservable();

  constructor() {}

  bindSuppliers(suppliers: any) {
    this.bs_all_suppliers.next(suppliers);
  }

  bindSelectedSupplier(supplier: any){
    this.bs_selected_supplier.next(supplier);
  }
}
