import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  companies: Array<object> = [];
  selectedCompany: any;
  private bs_all_companies = new BehaviorSubject<any>(this.companies);
  private bs_selected_company = new BehaviorSubject<any>(this.selectedCompany);



  getAllCompanies = this.bs_all_companies.asObservable();
  getSelectedCompany = this.bs_selected_company.asObservable();

  constructor() {}

  bindCompanies(companies: any) {
    this.bs_all_companies.next(companies);
  }

  bindSelectedCompany(company: any) {
    this.bs_selected_company.next(company);
  }
}
