import { Component, Inject, OnDestroy } from '@angular/core';
import { ApiService } from '../../../../shared/api.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { PublicHolidayModel } from '../../../../models/public-holiday.model';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-holiday-dialog',
  templateUrl: './holiday-dialog.component.html',
  styleUrls: ['./holiday-dialog.component.scss'],
  providers: [DatePipe]
})
export class HolidayDialogComponent implements OnDestroy {

  subscription = new Subscription();
  form: FormGroup;
  dialogTitle = 'Add Public Holiday';


  constructor(private api: ApiService,
    private ref: MatDialogRef<HolidayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public holiday: PublicHolidayModel,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar) {

    ref.disableClose = true;

    this.form = formBuilder.group({
      name: ['', Validators.required],
      name_en: ['', Validators.required],
      description: [''],
      start_date: ['', Validators.required],
      end_date: [],
      hasEndDate: [false]
    });

    const endDateControl = this.form.controls['end_date'];

    if (this.holiday) {
      this.form.patchValue({
        name: holiday.name,
        name_en: holiday.name_en,
        description: holiday.description,
        start_date: holiday.start_date,
        end_date: holiday.end_date,
        hasEndDate: !!holiday.end_date,
      });

    }

    if (!this.form.value.hasEndDate) {
      endDateControl.disable();
    }

    this.form.controls['hasEndDate'].valueChanges.subscribe(value => {
      if (value) {
        endDateControl.enable();
        this.form.patchValue({
          end_date: this.form.value.start_date
        });
      } else {
        endDateControl.disable();
      }
    });




  }


  saveClick() {
    if (this.form.valid) {
      if (this.holiday) {
        this.editPublicHoliday(this.holiday);
        return;
      } else {
        this.createPublicHoliday();
        return;
      }
    }
  }

  createPublicHoliday() {
    let { name, name_en, description, start_date, end_date, hasEndDate } = this.form.value;
    this.form.disable();
    start_date = this.datePipe.transform(start_date, 'yyyy-MM-dd');
    end_date = this.datePipe.transform(end_date, 'yyyy-MM-dd');
    this.api.createPublicHoliday({
      name, name_en, description, start_date, end_date: hasEndDate ? end_date : null
    }).subscribe(response => {
      if (response.status) {
        this.snackBar.open('Created successfully', null, { duration: 2000, horizontalPosition: 'right' });
        this.ref.close(response);
      } else {
        this.form.enable();
        this.snackBar.open('Create failed!', null, { duration: 2000, horizontalPosition: 'right' });
      }
    }, error => {
      this.form.enable();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private editPublicHoliday(holiday: PublicHolidayModel) {
    let { name, name_en, description, start_date, end_date, hasEndDate } = this.form.value;
    this.form.disable();
    start_date = this.datePipe.transform(start_date, 'yyyy-MM-dd');
    end_date = this.datePipe.transform(end_date, 'yyyy-MM-dd');
    this.api.editPublicHoliday(holiday._id, {
      name, name_en, description, start_date, end_date: hasEndDate ? end_date : null
    }).subscribe(response => {
      if (response) {
        this.ref.close(response);
        this.snackBar.open('Updated successfully', null, { duration: 2000, horizontalPosition: 'right' });
      } else {
        this.form.enable();
        this.snackBar.open('Update failed!', null, { duration: 2000, horizontalPosition: 'right' });
      }
    }, error => {
      this.form.enable();
    });
  }
}
