import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { UserLoginService } from "../shared/userlogin.service";
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";
import { ContentScrollService } from "../shared/content-scroll.service";
import { MatDialog } from "@angular/material";
import { ChangePasswordComponent } from "./change-password-dialogs/change-password.component";
import { ApiService } from "../shared/api.service";

@Component({
  selector: "app-admin-page",
  templateUrl: "./admin-page.component.html",
  styleUrls: ["./admin-page.component.scss"],
  animations: [
    trigger("transformIcon", [
      state("hide", style({ opacity: 0 })),
      state("show", style({ opacity: 1 })),
      transition(
        "show => hide",
        animate(
          ".3s cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({
            opacity: 0,
            transform: "rotate(90deg) scale(0)"
          })
        )
      ),
      transition("hide => show", [
        style({ transform: "rotate(-90deg)" }),
        animate(
          ".3s cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({
            opacity: 1,
            transform: "rotate(0)"
          })
        )
      ])
    ]),
    trigger("logoIcon", [
      state("show", style({ transform: "translateX(10px)" })),
      state("hide", style({ transform: "translateX(0)" })),
      transition("show <=> hide", animate(".2s cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ])
  ]
})
export class AdminPageComponent implements OnInit {
  username: string;
  hideText = false;
  notifications: any = [];
  notification_number: any;
  availableForNotification = false;
  activityLogs: any;
  screenWidth: number;
  hideMenuBar: boolean = false;

  constructor(
    private route: Router,
    private userService: UserLoginService,
    private scroll: ContentScrollService,
    public dialog: MatDialog,
    private apiService: ApiService
  ) {
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
    };
    
    if(this.screenWidth <= 767) {
      this.hideText = true;
    }
  }

  ngOnInit() {
    this.username = this.userService.getUsername();
    this.checkAvailabilityForNotification();
    this.getNotifications();
    this.getActivityLogs();
  }

  getActivityLogs() {
    this.apiService.getActivityLogs().subscribe(response => {
      this.activityLogs = response;
    });
  }

  signOut() {
    this.userService.deleteToken();
    this.route.navigateByUrl("");
  }

  scrollEvent(e: Event) {
    this.scroll.emitScroll(e);
  }

  toggleNav() {
    this.hideText = !this.hideText;
  }

  openChangePasswordDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: "400px",
      height: "auto",
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
  getNotifications() {
    this.apiService.getQuotationNotifications().subscribe((response: any) => {
      if(response.data !== undefined) {
        this.notifications = [];
        this.notifications = response.data;
        this.notification_number = response.data.length;
      }
    });
  }

  checkAvailabilityForNotification() {
    const role = this.userService.getCurrentUserRoleName();
    console.log(role);
    if (role === "Manager") {
      this.availableForNotification = true;
    } else if (role === "CEO") {
      this.availableForNotification = true;
    } else if (role === "CTO") {
      this.availableForNotification = true;
    } else if (role === "Admin") {
      this.availableForNotification = true;
    } else {
      this.availableForNotification = false;
    }
  }
}
