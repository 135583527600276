import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  projects: Array<object> = [];
  selectedProject: any;
  private bs_all_projects = new BehaviorSubject<any>(this.projects);
  private bs_selected_project = new BehaviorSubject<any>(this.selectedProject);



  getAllProjects = this.bs_all_projects.asObservable();
  getSelectedProject = this.bs_selected_project.asObservable();

  constructor() {}

  bindProjects(projects: any) {
    this.bs_all_projects.next(projects);
  }

  bindSelectedProject(project: any){
    this.bs_selected_project.next(project);
  }
}
