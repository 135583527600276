import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatDatepickerInputEvent
} from "@angular/material";
import { QuotationFileInfo } from "../quotation.component";
import { ApiService } from "src/app/shared/api.service";
import { QuotationService } from "../quotation.service";
import { QuotationFileService } from "../quotation-file.service";

@Component({
  selector: "app-create-quotation-file-dialog",
  templateUrl: "./create-quotation-file-dialog.component.html",
  styleUrls: ["./create-quotation-file-dialog.component.scss"]
})
export class CreateQuotationFileDialogComponent implements OnInit {
  fileName: string = "";
  currentDate = new Date();
  maxDate: Date;
  minDate: Date;
  parentFiles: any;
  issued_date: Date = this.currentDate;
  expired_date: Date = this.currentDate;
  files: any;
  tmpFiles: any;

  constructor(
    public dialogRef: MatDialogRef<CreateQuotationFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: QuotationFileInfo,
    private apiService: ApiService,
    private quotationFileService: QuotationFileService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  revisionFormControl: FormControl = new FormControl("", [Validators.required]);
  totalPriceFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  parentFileFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);
  issuedDateFormControl: FormControl = new FormControl(this.currentDate, [
    Validators.required
  ]);
  expiredDateFormControl: FormControl = new FormControl(this.currentDate, [
    Validators.required
  ]);
  fileFormControl: FormControl = new FormControl("", [Validators.required]);

  fileNameFormControl: FormControl = new FormControl("", [Validators.required]);

  quotationFileFormGroup: FormGroup = new FormGroup({
    revision: this.revisionFormControl,
    total_price: this.totalPriceFormControl,
    parent_file: this.parentFileFormControl,
    issued_date: this.issuedDateFormControl,
    expired_date: this.expiredDateFormControl,
    file: this.fileFormControl,
    file_name: this.fileNameFormControl
  });

  getRequiredErrorMessage(field) {
    return this.quotationFileFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.quotationFileFormGroup.invalid) {
        this.dialogRef.close();
      }
    }
  }

  fileChanged(fileEvent: Event) {
    const target = fileEvent.target as HTMLInputElement;
    this.fileName = target.files[0].name;
    this.files = target.files;
    this.tmpFiles = Array.from(this.files);
  }

  ngOnInit() {
    this.getParentFiles();
  }

  getParentFiles() {
    this.parentFiles = [];
    this.apiService
      .getAllQuotationFiles(this.object.quotation_id)
      .subscribe(files => {
        this.parentFiles = [];
        this.parentFiles = files;
      });
  }

  issuedDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.issued_date = event.value;
    this.minDate = new Date(event.value);
  }

  expiredDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.expired_date = event.value;
    this.maxDate = new Date(event.value);
  }

  onDelete(file: any) {
    var index = this.tmpFiles.indexOf(file);
    if (index > -1) {
      this.tmpFiles.splice(index, 1);
    }
  }

  handleCreateQuotationFile() {
    const formData = new FormData();
    formData.append("quotation_id", this.object.quotation_id);
    formData.append("revision", this.object.revision);
    formData.append("total_price", this.object.total_price.toString());
    if (this.object.parent) formData.append("parent", this.object.parent);
    formData.append("issued_date", this.issued_date.toString());
    formData.append("expired_date", this.expired_date.toString());
    for (let i = 0; i < this.tmpFiles.length; i++) {
      formData.append("file", this.tmpFiles[i]);
    }
    this.apiService.createQuotationFile(formData).subscribe(
      response => {
        this.snackBar.open("Created successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Create failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }
}
