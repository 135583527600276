import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../../../shared/api.service';
import { OfficeModel } from '../../../../models/office.model';
import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/shared/excel.service';

@Component({
  selector: 'app-financial-report',
  templateUrl: './financial-report.component.html',
  styleUrls: ['./financial-report.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0, transform: 'translateY(20px)' })),
      transition('void => *', animate('.5s ease'))
    ]),
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateX(-40px)' }),
            stagger(
              '60ms',
              animate(
                '400ms ease',
                style({ opacity: 1, transform: 'translateX(0px)' })
              )
            )
          ],
          { optional: true }
        ),
        query(':leave', animate('50ms', style({ opacity: 0 })), {
          optional: true
        })
      ])
    ])
  ]
})
export class FinancialReportComponent implements OnInit, OnDestroy {

  offices: OfficeModel[] = [];
  isLoading = false;
  collapseHash = new Map<string, boolean>();
  subscription = new Subscription();
  formGroup: FormGroup;
  date = new Date();
  start_date: any;
  end_date: any;
  additionList: any = [{ add: "", average_add: "" }];
  promotionList: any = [{ promotion: "", tour: "", guide: "", total: "" }, {}];
  tourList: any = [{ tour: "", using: "", not_use: "", total: "" }];
  BankList: any = [{ acleda: "", campu: "", tour: "", guide: "", total: "" }];
  tour_status: any = [];
  income_by_categories: any = [
    {
      acleda_amount: 0,
      campu_amount: 0,
      tour_amount: 0, 
      guide_amount: 0
    }
  ];
  deposit: any = [
    {
      amount: 0,
      count: 1
    }
  ];
  redeem: any = [
    {
      _id: "TG",
      amount: 0
    },
    {
      _id: "TA",
      amount: 0
    }
  ];
  promotion: any = [
    {
      _id: "TG",
      amount: 0
    },
    {
      _id: "TA",
      amount: 0
    }
  ];
  secondTableData: any = [
    {
      title: "ទឹកប្រាក់ប្តូរពីពិន្ទុ",
      tour: 0,
      guide: 0
    },
    {
      title: "ប្រូម៉ូសិន",
      tour: 0,
      guide: 0
    }
  ];

  total_tour_status: number = 0;
  total_income_category: number = 0;
  total_table_two: number = 0;

  public displayedColumnsTableOne = ['add', 'average_add'];
  public displayedColumnsTableTwo = ['promotion', 'tour', 'guide', 'total'];
  public displayedColumnsTableThree = ['tour', 'using', 'not_use', 'total'];
  public displayedColumnsTableFour = ['acleda', 'campu', 'tour', 'guide', 'total'];

  constructor(private api: ApiService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private excelService: ExcelService,
    private dialog: MatDialog) {

    this.formGroup = this.formBuilder.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.patchValueToForm();
    this.getPaymentRedeemAndPromotion();
    this.getPaymentDeposit();
    this.getPaymentIncomeByCategory();
    this.getPaymentTourStatus();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  refreshFinancialReports() {
    this.total_tour_status = 0;
    this.total_income_category = 0;
    this.total_table_two = 0;

    this.getPaymentRedeemAndPromotion();
    this.getPaymentDeposit();
    this.getPaymentIncomeByCategory();
    this.getPaymentTourStatus();
  }

  patchValueToForm() {
    this.formGroup.patchValue({
      start_date: new Date(this.date.getFullYear(), this.date.getMonth(), 1),
      end_date: new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
    });
  }

  getPaymentDeposit() {
    let start_date = this.datePipe.transform(this.formGroup.value.start_date, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.formGroup.value.end_date, 'yyyy-MM-dd');
    this.api.getPaymentDeposit(start_date, end_date).subscribe((response: any) => {
      if(response.deposit.length != 0) {
        this.deposit = response.deposit;
      } else {
        this.deposit = [
          {
            amount: 0,
            count: 1
          }
        ]
      }
    });
  }

  getPaymentRedeemAndPromotion() {
    let start_date = this.datePipe.transform(this.formGroup.value.start_date, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.formGroup.value.end_date, 'yyyy-MM-dd');

    this.refreshPaymentTableTwo();
    this.api.getPaymentRedeem(start_date, end_date).subscribe((resRedeem: any) => {
      if(resRedeem.redeem.length != 0) {
        resRedeem.redeem.forEach((item, index) => {
          if(item._id === 'TG') {
            this.redeem[0].amount = item.amount;
          } else if(item._id === 'TA') {
            this.redeem[1].amount = item.amount;
          }
        });
      } else {
        this.redeem = [
          {
            _id: "TG",
            amount: 0
          },
          {
            _id: "TA",
            amount: 0
          }
        ];
      }
      
      this.api.getPaymentPromotion(start_date, end_date).subscribe((resPromotion: any) => {
        if(resPromotion.promotion.length != 0) {
          resPromotion.promotion.forEach((item, index) => {
            if(item._id === 'TG') {
              this.promotion[0].amount = item.amount;
            } else if(item._id === 'TA') {
              this.promotion[1].amount = item.amount;
            }
          });
        } else {
          this.promotion = [
            {
              _id: "TG",
              amount: 0
            },
            {
              _id: "TA",
              amount: 0
            }
          ];
        }

        this.secondTableData[1].guide = this.promotion[0].amount;
        this.secondTableData[1].tour = this.promotion[1].amount;
        this.total_table_two += this.promotion[0].amount;
        this.total_table_two += this.promotion[1].amount;

        this.secondTableData[0].guide = this.redeem[0].amount;
        this.secondTableData[0].tour = this.redeem[1].amount;
        this.total_table_two += this.redeem[0].amount;
        this.total_table_two += this.redeem[1].amount;
      });
    });
  }

  getPaymentIncomeByCategory() {
    let start_date = this.datePipe.transform(this.formGroup.value.start_date, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.formGroup.value.end_date, 'yyyy-MM-dd');
    this.api.getPaymentIncomeByCategory(start_date, end_date).subscribe((response: any) => {
      if(response.acleda.length != 0) {
        this.income_by_categories[0].acleda_amount = response.acleda[0].amount;
      }
      if(response.campu.length != 0) {
        this.income_by_categories[0].campu_amount = response.campu[0].amount;
      }

      response.tour.forEach(item => {
        if(item._id === 'TG') {
          this.income_by_categories[0].guide_amount = item.amount;
        } else if(item._id === 'TA') {
          this.income_by_categories[0].tour_amount = item.amount;
        }
      });
    });
  }

  getPaymentTourStatus() {
    let start_date = this.datePipe.transform(this.formGroup.value.start_date, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.formGroup.value.end_date, 'yyyy-MM-dd');
    this.api.getPaymentTourStatus(start_date, end_date).subscribe((response: any) => {
      this.tour_status = response.tour_status;
      this.tour_status.forEach(item => {
        this.total_tour_status += item.active + item.inactive;
      });
    });
  }

  refreshPaymentTableTwo() {
    this.secondTableData = [
      {
        title: "ទឹកប្រាក់ប្តូរពីពិន្ទុ",
        tour: 0,
        guide: 0
      },
      {
        title: "ប្រូម៉ូសិន",
        tour: 0,
        guide: 0
      }
    ];
  }
}
