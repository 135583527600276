import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UserService } from '../../../../shared/user.service';
import { ApiService } from '../../../../shared/api.service';
import { MatDialog, MatTabChangeEvent, MatSnackBar, MatOption } from '@angular/material';
import { PositionPagination } from 'src/app/models/position.model';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormBuilder, FormGroup, Validators, FormControl, SelectControlValueAccessor, NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ContentScrollService } from 'src/app/shared/content-scroll.service';
import { filter, map, pairwise } from 'rxjs/operators';
import { FileModel } from 'src/app/models/contact.model';
import { HttpEventType } from '@angular/common/http';
import { DISABLED } from '@angular/forms/src/model';
import { AddMemberComponent } from './add-member-dialogs/add-member.component';
import { GroupModel } from 'src/app/models/group.model';
import { DeleteGroupComponent } from './delete-group-dialogs/delete-group.component';
import { DeleteContactComponent } from './delete-contact-dialogs/delete-contact.component';
import { ContactDetailComponent } from './contact-detail-dialogs/contact-detail.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  animations: [
    trigger('leaveForm', [
      state('void', style({ opacity: 0, transform: 'translateY(20px) scale(.95)' })),
      transition('void => *', animate('.3s ease'))
    ]),
    trigger('fade', [
      state('void', style({ opacity: 0, transform: 'translateY(20px)' })),
      transition('void => *', animate('.5s ease'))
    ]),
  ]
})
export class ContactComponent implements OnInit {
  isLoading: boolean = true;
  enableContactForm: boolean = true;
  enableGroupForm: boolean = false;
  enableSearchBox: boolean = false;
  showContactForm: boolean = false;
  showGroupForm: boolean = false;
  showAllMembers: boolean = false;
  createButton: string = "Contact";
  allGroup: string = "";
  contacts: Array<any> = [];
  firstPageContacts: Array<object> = [];
  groups: Array<GroupModel> = [];
  groupContact: Array<any> = [];
  formSearch: FormGroup;
  formContact: FormGroup;
  formGroup: FormGroup;
  myFormGroupSubs: Subscription;
  contactFile: File = null;
  groupFile: File = null;
  phoneIncrement: number = 1;
  emailIncrement: number = 1;
  phones: Array<number> = Array(1).fill(0).map((x, i) => i);
  emails: Array<number> = Array(1).fill(0).map((x, i) => i);
  removedPhoneIndex: Array<number> = [];
  removedEmailIndex: Array<number> = [];
  contactProfileUrl: string = './../../../../../assets/images/user.png';
  groupProfileUrl: string = './../../../../../assets/images/user.png';
  leaveRequestsLoading = true;
  isCheckedContact: boolean = true;
  isCheckedGroup: boolean = false;
  clickedShowGroupMembers: string = '';
  selectedGroupID: string = '';
  selectedContactID: string = '';
  selectedGroups: Array<string> = [];
  txtSearchGroup: string = '';
  txtSearchByName: string = '';

  addedNewMembers: Array<object> = [];
  removedOldMemberIDs: Array<string> = [];
  newListMembers: Array<object> = [];
  oldListMembers: Array<object> = [];
  temporaryNewMembers: Array<object> = [];
  temporaryNewMemberIDs: Array<string> = [];
  temporaryOldMemberIDs: Array<string> = [];
  removedMemberID: string = '';
  editedGroup: any;
  editedContact: any;
  isCreatingGroup: boolean = false;
  isEditingGroup: boolean = false;
  isCreatingContact: boolean = false;
  isEditingContact: boolean = false;

  private currentPage_contact = 1;
  private limit_contact = 10;
  private isGotAllContact: boolean = false;

  constructor(private userService: UserService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private scroll: ContentScrollService,
    private snackBar: MatSnackBar,
    private route: Router) {

    this.formSearch = this.formBuilder.group({
      search_txt: ['',],
      group: ['',],
    });

    this.formContact = this.formBuilder.group({
      name: ['', Validators.required],
      phone0: ['', Validators.required],
      email0: ['', Validators.email],
      website: ['',],
      fax: ['',],
      note: ['',],
      group_ids: [[], Validators.required],
      file: ['',]
    });

    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      file: ['',]
    });
  }

  ngOnInit() {
    const offset_contact = 1000;
    const scrollDownContact = this.scroll.getContentScrollObservable()
      .pipe(filter(x => {
        const el = x.target as HTMLDivElement;
        const a = el.scrollHeight - el.scrollTop - offset_contact;
        return a <= el.clientHeight && !this.leaveRequestsLoading && !this.isGotAllContact;
      }),
        map(x => {
          const el = x.target as HTMLDivElement;
          return el.scrollHeight - el.scrollTop;
        }), pairwise(), filter(arr => arr[0] > arr[1]));

    scrollDownContact.subscribe(res => {
      if (this.isCheckedContact && !this.isGotAllContact && this.txtSearchGroup === '' && this.txtSearchByName === '') {
        this.getAllContacts(this.limit_contact, this.currentPage_contact + 1);
      }
    });

    if (this.currentPage_contact == 1) this.getAllContacts(this.limit_contact, this.currentPage_contact);
    this.getAllGroups();
  }

  getAllContacts(limit: number = 10, currentPage: number = 1) {
    this.leaveRequestsLoading = true;
    this.isLoading = true;
    this.apiService.getAllContacts(limit, currentPage).subscribe((data: any) => {
      if(this.txtSearchGroup === '') {
        this.contacts.push(...data);
      } else {
        this.contacts.push(data.filter((item: any) => item._id == this.txtSearchGroup));
      }

      if (data.length == 0) this.isGotAllContact = true;

      if (this.isEditingContact) {
        // this.editedContact = this.contacts.find((item: any) => item._id == this.selectedContactID);
        // this.showRequestDataEditContact(this.editedContact);
        this.showContactForm = false;
      }

      if (currentPage === 1) this.firstPageContacts = data;

      this.currentPage_contact = currentPage;
      this.limit_contact = limit;
      this.leaveRequestsLoading = false;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }, error => {
      this.leaveRequestsLoading = false;
      console.error(error);
    });
  }

  getAllGroups() {
    this.leaveRequestsLoading = true;
    this.isLoading = true;
    this.apiService.getAllGroups(this.txtSearchByName).subscribe((data: any) => {
      this.groups = data;
      this.selectedGroups = [data[0]._id];

      if (this.isEditingGroup) {
        // this.editedGroup = this.groups.find((item: any) => item._id == this.selectedGroupID);
        // this.refreshEditGroupForm(this.editedGroup);
        this.showGroupForm = false;
      }

      this.groupContact = this.groups;

      this.leaveRequestsLoading = false;
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    }, error => {
      this.leaveRequestsLoading = false;
      console.error(error);
    });
  }

  onTabChanged(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.enableContactForm = true;
      this.enableGroupForm = false;
      this.createButton = "Contact";
      this.showGroupForm = false;
      this.showAllMembers = false;
      this.isCheckedContact = true;
      this.isCheckedGroup = false;
      this.isGotAllContact = false;
      this.txtSearchByName = '';
      this.resetContactForm();
      this.getAllGroups();
    } else {
      this.enableContactForm = false;
      this.enableGroupForm = true;
      this.createButton = "Group";
      this.showContactForm = false;
      this.showAllMembers = false;
      this.isCheckedContact = false;
      this.isCheckedGroup = true;
      this.isGotAllContact = true;
      this.txtSearchByName = '';
      this.resetGroupForm();
    }
  }

  refreshData() {
    if(this.enableContactForm) {
      this.txtSearchByName = '';
      this.txtSearchGroup = '';
      this.getAllContacts();
    } else if(this.enableGroupForm) {
      this.txtSearchByName = '';
      this.getAllGroups();
    }
  }

  onShowContactForm() {
    this.showContactForm = true;
    this.isCreatingContact = true;
    this.isEditingContact = false;
  }

  onShowGroupForm() {
    this.showGroupForm = true;
    this.isCreatingGroup = true;
    this.isEditingGroup = false;
  }

  showSearchBox() {
    if (this.enableSearchBox == true) this.enableSearchBox = false;
    else this.enableSearchBox = true;
  }

  onGroupChange() {
    this.enableSearchBox = true;
    this.contacts = [];
    if(this.txtSearchGroup === '') {
      this.getAllContacts();
    } else {
      this.filterContactAllPages(1, this.txtSearchByName);
    }
  }

  filterListByName() {
    if(this.enableContactForm && this.txtSearchGroup === '') {
      this.apiService.getAllContacts(this.limit_contact, 1, this.txtSearchByName).subscribe((data: any) => {
        this.contacts = data;
      });
    } else if(this.enableContactForm && this.txtSearchGroup !== '') {
      this.contacts = [];
      this.filterContactAllPages(1, this.txtSearchByName);
    } else if(this.enableGroupForm) {
      this.apiService.getAllGroups(this.txtSearchByName).subscribe((data: any) => {
        this.groups = data;
      });
    }
  }

  filterContactAllPages(page: number, name: string) {
    this.apiService.getAllContacts(50, page, name).subscribe((data: any) => {
      if(data.length != 0) {
        this.contacts.push(...data.filter((item: any) => item.groups.map((group: any) => group._id).indexOf(this.txtSearchGroup) >= 0));
        this.filterContactAllPages(page + 1, name);
      }
    });
  }

  cancelContactForm() {
    this.resetContactForm();
    this.showContactForm = false;
    this.contactProfileUrl = './../../../../../assets/images/user.png';
    this.selectedGroups = [this.groups[0]._id];
    this.phoneIncrement = 1;
    this.emailIncrement = 1;
  }

  cancelGroupForm() {
    this.resetGroupForm();
    this.showGroupForm = false;
    this.groupProfileUrl = './../../../../../assets/images/user.png';
  }

  private resetContactForm() {
    this.formContact.enable();
    this.formContact.reset({
      name: '',
      phone: '',
      email: '',
      website: '',
      fax: '',
      note: ''
    });

    this.formContact.patchValue({
      group_ids: [this.groupContact[0]._id],
    });

    this.contactFile = null;
    this.phones = Array(1).fill(0).map((x, i) => i);
    this.emails = Array(1).fill(0).map((x, i) => i);

    this.contactProfileUrl = './../../../../../assets/images/user.png';
  }

  private resetGroupForm() {
    this.formGroup.enable();
    this.formGroup.reset({
      name: '',
    });

    this.groupFile = null;
    this.addedNewMembers = [];
    this.removedOldMemberIDs = [];
    this.newListMembers = [];
    this.temporaryNewMembers = [];
    this.temporaryNewMemberIDs = [];

    this.oldListMembers = [];
    this.temporaryOldMemberIDs = [];
    this.removedOldMemberIDs = [];

    this.groupProfileUrl = './../../../../../assets/images/user.png';
  }

  private refreshEditGroupForm(group: any) {
    this.formGroup.enable();

    this.formGroup.patchValue({
      name: group.name,
    });

    this.addedNewMembers = [];
    this.removedOldMemberIDs = [];
    this.newListMembers = [];
    this.temporaryNewMembers = [];
    this.temporaryNewMemberIDs = [];

    this.oldListMembers = group.contacts;
    this.groupProfileUrl = group.photo;
    this.temporaryOldMemberIDs = group.contacts.map((item: any) => item._id);

    this.groupProfileUrl = group.photo;
  }

  fileContactChanged(event) {
    if (event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.contactProfileUrl = event.target.result;
      }
    }

    this.formContact.patchValue({
      file: event.target.files[0],
    });
  }

  fileGroupChanged(event) {
    if (event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.groupProfileUrl = event.target.result;
      }
    }

    this.formGroup.patchValue({
      file: event.target.files[0],
    });
  }

  onShowAllMembers(groupID: string) {
    this.clickedShowGroupMembers = groupID;

    if (this.showAllMembers) this.showAllMembers = false;
    else this.showAllMembers = true;
  }

  increasePhone() {
    this.phoneIncrement++;
    try {
      this.formContact.removeControl(this.phoneIncrement.toString())
    } catch { }

    const numbers = Array(this.phoneIncrement).fill(0).map((x, i) => i);

    const fc = new FormControl('');
    this.formContact.addControl("phone" + numbers[numbers.length - 1].toString(), fc);

    console.log("add phone" + numbers[numbers.length - 1].toString());
    this.phones = numbers;
  }

  decreasePhone(removedIndex) {
    console.log("remove phone" + removedIndex.toString());
    this.formContact.controls["phone" + removedIndex].disable();
    this.formContact.controls["phone" + removedIndex].patchValue('');
    this.removedPhoneIndex.push(removedIndex);
  }

  increaseEmail() {
    this.emailIncrement++;
    try {
      this.formContact.removeControl(this.emailIncrement.toString())
    } catch { }

    const numbers = Array(this.emailIncrement).fill(0).map((x, i) => i);
    numbers.forEach((num) => {
      const fc = new FormControl('');
      this.formContact.addControl("email" + num.toString(), fc);
    })
    this.emails = numbers;
  }

  decreaseEmail(removedIndex) {
    this.formContact.controls["email" + removedIndex].disable();
    this.formContact.controls["email" + removedIndex].patchValue('');
    this.removedEmailIndex.push(removedIndex);
  }

  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1 === o2 : o2 === o2;
  }

  saveContactForm() {
    const value = this.formContact.getRawValue() as ContactFormValues;
    const arrPhones = [];
    const arrEmails = [];

    this.phones.forEach((phoneIndex, i) => {
      if (!this.removedPhoneIndex.includes(i)) {
        console.log(this.formContact.controls['phone' + phoneIndex].value);
        arrPhones.push(this.formContact.controls['phone' + phoneIndex].value);
      }
    });

    this.emails.forEach((emailIndex, i) => {
      if (!this.removedEmailIndex.includes(i)) {
        console.log(this.formContact.controls['email' + emailIndex].value);
        arrEmails.push(this.formContact.controls['email' + emailIndex].value);
      }
    });

    this.formContact.disable();

    if (this.isCreatingContact) {
      this.apiService.createContact({
        name: value.name,
        phones: arrPhones,
        emails: arrEmails,
        website: value.website,
        fax: value.fax,
        group_ids: value.group_ids,
        note: value.note
      }, value.file).subscribe(response => {
        switch (response.type) {
          case HttpEventType.Sent:
            this.formContact.disable();
            break;
          case HttpEventType.UploadProgress:

            break;
          case HttpEventType.ResponseHeader:

            break;
          case HttpEventType.DownloadProgress:

            break;
          case HttpEventType.Response:
            this.contacts.length = 0;
            this.formContact.enable();
            this.resetContactForm();
            this.getAllContacts(10, 1);
            this.snackBar.open('Created successfully', null, { duration: 2000, horizontalPosition: 'right' });
            break;
        }
      }, error => {
        this.formContact.enable();
        this.snackBar.open('Error while uploading...', 'Retry', { duration: 2000, horizontalPosition: 'right' });
      });

      this.phoneIncrement = 1;
      this.emailIncrement = 1;

    } else if (this.isEditingContact) {
      this.apiService.editContact({
        _id: this.selectedContactID,
        name: value.name,
        phones: [...arrPhones],
        emails: [...arrEmails],
        website: value.website,
        fax: value.fax,
        group_ids: value.group_ids,
        note: value.note
      }, value.file).subscribe(response => {
        switch (response.type) {
          case HttpEventType.Sent:
            this.formContact.disable();
            break;
          case HttpEventType.UploadProgress:

            break;
          case HttpEventType.ResponseHeader:

            break;
          case HttpEventType.DownloadProgress:

            break;
          case HttpEventType.Response:
            this.contacts.length = 0;
            this.formContact.enable();
            this.getAllContacts(10, 1);
            this.snackBar.open('Updated successfully', null, { duration: 2000, horizontalPosition: 'right' });
            break;
        }
      }, error => {
        this.formContact.enable();
        this.snackBar.open('Error while uploading...', 'Retry', { duration: 2000, horizontalPosition: 'right' });
      });
    }
  }

  saveGroupForm() {
    const value = this.formGroup.getRawValue() as GroupFormValues;

    this.formGroup.disable();

    if (this.isCreatingGroup) {
      this.apiService.createGroup({
        name: value.name,
        contact_ids: this.temporaryNewMemberIDs
      }, value.file).subscribe(response => {
        switch (response.type) {
          case HttpEventType.Sent:
            this.formGroup.disable();
            break;
          case HttpEventType.UploadProgress:

            break;
          case HttpEventType.ResponseHeader:

            break;
          case HttpEventType.DownloadProgress:

            break;
          case HttpEventType.Response:
            this.groups.length = 0;
            this.formGroup.enable();
            this.resetGroupForm();
            this.getAllGroups();
            this.snackBar.open('Created successfully', null, { duration: 2000, horizontalPosition: 'right' });
            break;
        }
      }, error => {
        this.formGroup.enable();
        this.snackBar.open('Error while uploading...', 'Retry', { duration: 2000, horizontalPosition: 'right' });
      });
    } else if (this.isEditingGroup) {
      this.apiService.editGroup({
        _id: this.selectedGroupID,
        name: value.name,
        add_contact_ids: this.temporaryNewMemberIDs,
        remove_contact_ids: this.removedOldMemberIDs
      }, value.file).subscribe(response => {
        switch (response.type) {
          case HttpEventType.Sent:
            this.formGroup.disable();
            break;
          case HttpEventType.UploadProgress:

            break;
          case HttpEventType.ResponseHeader:

            break;
          case HttpEventType.DownloadProgress:

            break;
          case HttpEventType.Response:
            this.groups.length = 0;
            this.formGroup.enable();
            this.getAllGroups();
            this.snackBar.open('Updated successfully', null, { duration: 2000, horizontalPosition: 'right' });
            break;
        }
        this.removedMemberID = '';
      }, error => {
        this.formGroup.enable();
        this.snackBar.open('Error while uploading...', 'Retry', { duration: 2000, horizontalPosition: 'right' });
      });
    }
  }

  showAddNewMemberDialog(): void {
    this.temporaryNewMemberIDs = this.temporaryNewMembers.map((item: any) => item._id);
    const dialogRef = this.dialog.open(AddMemberComponent, {
      width: '615px',
      height: 'auto',
      data: {
        firstPageContacts: this.firstPageContacts,
        temporaryNewMemberIDs: [...this.temporaryNewMemberIDs, ...this.temporaryOldMemberIDs]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.removedMemberID = '';
      if (result != false) {
        this.addedNewMembers = result;
        this.temporaryNewMembers.push(...this.addedNewMembers);
      }
      this.temporaryNewMemberIDs = this.temporaryNewMembers.map((item: any) => item._id);
      this.newListMembers = this.temporaryNewMembers;
    });
  }

  showDeleteContactDialog(contact_id: string): void {
    const dialogRef = this.dialog.open(DeleteContactComponent, {
      width: '400px',
      height: 'auto',
      data: {
        contact_id: contact_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.contacts = [];
        this.getAllContacts(10, 1);
        this.resetContactForm();
      }
    });
  }

  showDeleteGroupDialog(group_id: string): void {
    const dialogRef = this.dialog.open(DeleteGroupComponent, {
      width: '400px',
      height: 'auto',
      data: {
        group_id: group_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.getAllGroups();
        this.resetGroupForm();
      }
    });
  }

  removeMember(removedID) {
    this.temporaryNewMemberIDs = this.temporaryNewMemberIDs.filter(item => item != removedID);
    this.temporaryNewMembers = this.temporaryNewMembers.filter((item: any) => this.temporaryNewMemberIDs.indexOf(item._id) >= 0);
    this.newListMembers = this.temporaryNewMembers;
    this.removedMemberID = removedID;
  }

  removeOldMember(removedID) {
    this.removedOldMemberIDs.push(removedID);
    this.temporaryOldMemberIDs = this.temporaryOldMemberIDs.filter(item => item != removedID);
    this.oldListMembers = this.oldListMembers.filter((item: any) => this.temporaryOldMemberIDs.indexOf(item._id) >= 0);
    this.removedMemberID = removedID;
  }

  showRequestDataEditContact(selectedContact) {
    this.isCreatingContact = false;
    this.isEditingContact = true;
    this.showContactForm = true;
    this.selectedContactID = selectedContact._id;
    this.removedPhoneIndex = [];
    this.removedEmailIndex = [];

    this.formContact.patchValue({
      name: selectedContact.name,
      website: selectedContact.website,
      fax: selectedContact.fax,
      note: selectedContact.note
    });

    this.selectedGroups = selectedContact.groups.map((item: any) => item._id);

    this.phones = Array(selectedContact.phones.length).fill(0).map((x, i) => i);
    this.emails = Array(selectedContact.emails.length).fill(0).map((x, i) => i);
    this.phoneIncrement = selectedContact.phones.length;
    this.emailIncrement = selectedContact.emails.length;

    try {
      this.formContact.removeControl(this.phoneIncrement.toString());
      this.formContact.removeControl(this.emailIncrement.toString());
    } catch { }

    this.phones.forEach((phoneIndex, i) => {
      const fc = new FormControl('');
      this.formContact.addControl("phone" + phoneIndex, fc);
      this.formContact.controls['phone' + phoneIndex].patchValue(selectedContact.phones[i].toString());
    });

    this.emails.forEach((emailIndex, i) => {
      const fc = new FormControl('');
      this.formContact.addControl("email" + emailIndex, fc);
      this.formContact.controls['email' + emailIndex].patchValue(selectedContact.emails[i].toString());
    });

    this.contactProfileUrl = selectedContact.photo;
  }

  showRequestDataEditGroup(selectedGroup) {
    this.isCreatingGroup = false;
    this.isEditingGroup = true;
    this.showGroupForm = true;
    this.selectedGroupID = selectedGroup._id;
    this.formGroup.patchValue({
      name: selectedGroup.name,
    });

    this.oldListMembers = selectedGroup.contacts;
    this.groupProfileUrl = selectedGroup.photo;
    this.temporaryOldMemberIDs = selectedGroup.contacts.map((item: any) => item._id);
  }

  showMemberInfoIDialog(memberID: string) {
    this.apiService.getContactById(memberID).subscribe((data: any) => {
      const dialogRef = this.dialog.open(ContactDetailComponent, {
        width: '400px',
        height: 'auto',
        data: {
          contact: data
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined) {

        }
      });
    });
  }
}

export interface ContactFormValues {
  name?: string;
  phones?: Array<string>;
  emails?: Array<string>;
  website?: string;
  fax?: string;
  group_ids?: string[];
  note?: string;
  file?: File;
}

export interface GroupFormValues {
  name?: string;
  contact_ids?: string[];
  file?: File;
}


