export class Promotion {
  type: string;
  from: string;
  to: string;
  count: number;
  export: boolean;
  page: number;

  constructor() {
    this.type = "";
    this.from = this.getDefaultStartDate();
    this.to = this.getDefaultEndDate();
    this.count = 10;
    this.export = false;
    this.page = 1;
  }

  getDefaultStartDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (mm < 10) {
      mm = "0" + mm;
    }

    return yyyy + "-" + mm + "-01";
  }

  getDefaultEndDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let dd: any = this.daysInMonth(mm, yyyy);

    if (mm < 10) {
      mm = "0" + mm;
    }

    return yyyy + "-" + mm + "-" + dd;
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
}
