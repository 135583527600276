import { Component, OnInit, Inject, Input } from '@angular/core';
import { UserService } from '../../../../shared/user.service';
import { ApiService } from '../../../../shared/api.service';
import { MatDialog } from '@angular/material';
import { CreateUserComponent } from './create-user-dialogs/create-user.component';
import { EditUserComponent } from './edit-user-dialogs/edit-user.component';
import { UserLoginService } from 'src/app/shared/userlogin.service';
import { ResetPasswordComponent } from './reset-password-dialogs/reset-password.component';
import { UserPagination } from '../../../../models/user.model';
import { Router } from '@angular/router';
import { NgxUiLoaderDemoService } from 'src/app/shared/ngx-ui-loader.service';
import { NgxUiLoaderService, Loader } from 'ngx-ui-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface UserInfo {
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  pwd: string;
  telephone: string;
  address: string;
  role: string;
  position: string;
  office: string;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  @Input() loader: Loader;
  timers: any[];
  foregroundStarted: {};
  backgroundStarted: {};

  users: Array<object> = [];
  currentUserID: string;
  checked = false;
  disabled = false;
  userPagination = new UserPagination();
  page_increasement = 0;
  lastPage_check = false;
  isLoading = true;
  formGroup: FormGroup;
  searchByName: boolean = true;
  searchByOffice: boolean = false;
  searchBy = "name"; 
  offices: Array<object> = [];
  defaultOffice = '';

  constructor(private userService: UserService,
    private userLoginService: UserLoginService, 
    private apiService: ApiService, 
    public dialog: MatDialog, 
    private route: Router,
    private formBuilder: FormBuilder,
    public demoService: NgxUiLoaderDemoService,
    private ngxUiLoaderService: NgxUiLoaderService) {

      this.formGroup = this.formBuilder.group({
        search_txt: ['', ],
        office: ['', ]
      });
  }

  ngOnInit() {
    // this.timers = [];
    // this.foregroundStarted = {};
    // this.backgroundStarted = {};
    // if (this.loader.isMaster) {
    //   this.backgroundStarted = { ...this.loader.background };
    // } 

    // this.ngxUiLoaderService.startLoader(this.loader.loaderId, 'default');
    // this.foregroundStarted['default'] = true;
    // this.timers = [...this.timers, setTimeout(() => {
    //   this.ngxUiLoaderService.stopLoader(this.loader.loaderId, 'default');
    //   this.foregroundStarted['default'] = false;
    // }, 3000)];

    this.getAllUsers();
    this.getAllOffices();
    this.currentUserID = this.userLoginService.getCurrentUserID();
    this.userService.getAllUsers.subscribe(users => this.users = users);
  }

  getAllUsers() {
    this.isLoading = true;
    this.apiService.getAllUsers().subscribe((data: any) => {
      if (data.status == 1 && data.users.length > 0) {
        this.users = [];
        this.users = data.users;
      }

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

    }, err => {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    });
  }

  openCreateUserDialog(): void {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '800px',
      height: 'auto',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    });
  }

  openEditUserDialog(user: any): void {
    this.userService.bindSelectedUser(user);
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '800px',
      height: 'auto',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getAllUsers();
    });
  }

  openResetDialog(user: any): void {
    this.userService.bindSelectedUser(user);
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      width: 'auto',
      height: 'auto',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  //count selector
  selectCountHandler(event: any) {
    this.userPagination.count = event.target.value;

    this.loadList();
  }

  currentPage = 1;
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.userPagination.page = this.currentPage;
    this.loadList();
  }

  nextClick() {
    this.currentPage = this.currentPage + 1;
    this.userPagination.page = this.currentPage;
    this.loadList();
  }

  loadList() {
    let search_txt = this.formGroup.value.search_txt;
    let office = this.formGroup.value.office;

    this.apiService.getListUsers(this.userPagination, search_txt, office).subscribe(Response => {

      this.page_increasement = (this.currentPage - 1) * this.userPagination.count;
      if (Response['status'] == 401) {
        this.route.navigateByUrl('/');
      }
      else {
        this.users = Response['users'];
      }
    });
    this.userPagination.export = false;
  }

  getAllOffices() {
    this.apiService.getAllOffices().subscribe((data: any) => {
      if (data.status == 1) {
        this.offices = [];
        this.offices = data.offices;
      }
    });
  }

  refreshListUsers() {
    let search_txt = this.formGroup.value.search_txt;
    let office = this.formGroup.value.office;

    if(office === 'all') office = '';

    this.apiService.getFilteredUsers(search_txt, office).subscribe((res: any) => {
      this.users = res.users;
    });
  }
}




