import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { Router } from "@angular/router";
import { ProjectFileService } from "../project-file.service";
import { ProjectFilePagination } from "src/app/models/project-file.model";
import { EditProjectFileDialogComponent } from "../edit-project-file-dialog/edit-project-file-dialog.component";
import { DeleteProjectFileDialogComponent } from "../delete-project-file-dialog/delete-project-file-dialog.component";
import { UserLoginService } from "src/app/shared/userlogin.service";

@Component({
  selector: "app-project-file-display-dialog",
  templateUrl: "./project-file-display-dialog.component.html",
  styleUrls: ["./project-file-display-dialog.component.scss"]
})
export class ProjectFileDisplayDialogComponent implements OnInit {
  projectFilePagination = new ProjectFilePagination();
  isLoading = true;
  projectFiles: any;
  page_increasement = 0;
  fileLinks: any;
  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private userToken: UserLoginService,
    private route: Router,
    private projectFileService: ProjectFileService,
    public dialogRef: MatDialogRef<ProjectFileDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any
  ) {}

  ngOnInit() {
    this.getAllProjectFiles();
    this.projectFileService.getAllProjectFiles.subscribe(
      data => (this.projectFiles = data)
    );
  }

  getAllProjectFiles() {
    this.isLoading = true;
    this.apiService.getAllProjectFiles(this.object.project._id).subscribe(
      (data: any) => {
        this.isLoading = false;
        if (data.length > 0) {
          this.projectFiles = [];
          this.projectFiles = data;
        }
      },
      error => {
        this.isLoading = false;
      }
    );
  }

  openDeleteProjectFileDialog(projectFile: any){
    const dialogRef = this.dialog.open(DeleteProjectFileDialogComponent, {
      width: "400px",
      height: "auto",
      data: {
        projectFile: projectFile
      }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  openEditProjectFileDialog(projectFile: any) {
    const dialogRef = this.dialog.open(EditProjectFileDialogComponent, {
      width: "400px",
      height: "auto",
      data: {
        projectFile: projectFile
      }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  viewFileInNewTap(projectFile: any) {
    for (let i = 0; i < projectFile.files.length; i++){
      window.open(projectFile.files[i], '_blank');
    }
  }

  // count selector
  selectCountHandler(event: any) {
    this.projectFilePagination.count = event.target.value;
    this.loadList();
  }

  currentPage = 1;
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.projectFilePagination.page = this.currentPage;
    this.loadList();
  }
  nextClick() {
    this.currentPage = this.currentPage + 1;
    this.projectFilePagination.page = this.currentPage;
    this.loadList();
  }

  loadList() {
    this.apiService
      .getListProjectFiles(this.projectFilePagination, this.object.project._id)
      .subscribe(Response => {
        this.page_increasement =
          (this.currentPage - 1) * this.projectFilePagination.count;
        if (Response["status"] == 401) {
          this.route.navigateByUrl("/");
        } else {
          this.projectFiles = Response;
        }
      });
    this.projectFilePagination.export = false;
  }

  getFileLink(projectFile: any){
    this.fileLinks = [];
    this.fileLinks = projectFile.files;
  }


  viewFile(file: any){
    window.open(file.url + `?token=${this.userToken.getToken()}`, '_blank');
  }

  downloadFile(file: any){
    this.apiService.downloadQuotationFile(file.url + `?token=${this.userToken.getToken()}`, file.name);
  }
}
