import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { SupplierService } from "../supplier.service";

@Component({
  selector: "app-delete-supplier-dialog",
  templateUrl: "./delete-supplier-dialog.component.html",
  styleUrls: ["./delete-supplier-dialog.component.scss"]
})
export class DeleteSupplierDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteSupplierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private supplierService: SupplierService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

  handleDeleteSupplier() {
    
    this.apiService.deleteSupplier(this.object.supplier_id).subscribe(
      (response: any) => {
        this.apiService.getAllSuppliers().subscribe((data: any) => {
          this.supplierService.bindSuppliers(data);
        });
        this.snackBar.open("Deleted successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Delete failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }
}
