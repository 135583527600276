import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Component, Inject } from '@angular/core';
import { UserService } from 'src/app/shared/user.service';
import { UserLoginService } from 'src/app/shared/userlogin.service';


@Component({
  selector: 'status',
  templateUrl: 'status.component.html',
  styleUrls: ['status.component.css']
})
export class StatusComponent {

  rejectStatus: string;
  currentUserID = this.userLoginService.getCurrentUserID();

  // ===== Form Validation ======================================================
  statusFormControl: FormControl = new FormControl('', [
    Validators.required, 
    Validators.minLength(10)
  ]);

  statusFormGroup: FormGroup = new FormGroup({
    password: this.statusFormControl
  });

  getStatusErrorMessage() {
    return this.statusFormControl.hasError('required') ? 'You must enter a value' : 
      this.statusFormControl.hasError('minlength') ? 'Password must be at least 10 characters long' : '';
  }
  // ===========================================================================

  constructor(public dialogRef: MatDialogRef<StatusComponent>, 
              @Inject(MAT_DIALOG_DATA) public object: any, 
              public userService: UserService, 
              private apiService: ApiService,
              private snackBar: MatSnackBar,
              private userLoginService: UserLoginService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  rejectLeaveRequest() {
    this.apiService.rejectLeaveRequest(this.object.leave_id, this.rejectStatus).subscribe((response) => {
      if(response.status) {
        this.snackBar.open('Rejected successfully', null, {duration: 2000, horizontalPosition: 'right'});
      }
    });
  }
}
