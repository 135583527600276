import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { PositionInfo } from '../position.component';
import { UserService } from 'src/app/shared/user.service';


@Component({
    selector: 'create-position',
    templateUrl: 'create-position.component.html',
    styleUrls: ['create-position.component.css']
  })
  export class CreatePositionComponent {
  
    // ===== Form Validation ======================================================
  nameFormControl: FormControl = new FormControl('', [Validators.required]);
  nameEnFormControl = new FormControl('', [Validators.required]);
  descriptionFormControl = new FormControl('', [Validators.required]);

  positionFormGroup: FormGroup = new FormGroup({
    name: this.nameFormControl,
    name_en: this.nameEnFormControl,
    description: this.descriptionFormControl
  });

  getRequiredErrorMessage(field) {
    return this.positionFormGroup.get(field).hasError('required') ? 'You must enter a value' : '';
  }
  // ===========================================================================
  
    constructor(public dialogRef: MatDialogRef<CreatePositionComponent>, 
                @Inject(MAT_DIALOG_DATA) public position: PositionInfo, 
                private apiService: ApiService, 
                private userService: UserService,
                private snackBar: MatSnackBar) { 
                  dialogRef.disableClose = true;
                }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
    ngOnInit() {
    }

    keyDownFunction(event) {
      if (event.keyCode == 13) {
        if (!this.positionFormGroup.invalid) {
          this.dialogRef.close();
          this.handleCreatePosition();
        }
      }
    }
  
    handleCreatePosition() {
      if(this.positionFormGroup.valid) {
        this.apiService.createPosition(this.position).subscribe((response: any) => {
          this.apiService.getAllPositions().subscribe((data: any) => {
            if (data.status == 1 && data.positions.length > 0) {
              this.userService.bindPositions(data.positions);
            }
          });
          this.dialogRef.close();
          if(response.status == 1) {
            this.snackBar.open('Created successfully', null, {duration: 2000, horizontalPosition: 'right'});
          } else {
            this.snackBar.open('Create failed!', null, {duration: 2000, horizontalPosition: 'right'});
          }
        });
      }
    }
  }