import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { SupplierInfo } from "../supplier.component";
import { ApiService } from "src/app/shared/api.service";
import { SupplierService } from "../supplier.service";

@Component({
  selector: "app-create-supplier-dialog",
  templateUrl: "./create-supplier-dialog.component.html",
  styleUrls: ["./create-supplier-dialog.component.scss"]
})
export class CreateSupplierDialogComponent implements OnInit {
  selectedAttendant: any;
  attendants: any;
  newAttendant = { _id: "0", name: "New" };
  constructor(
    public dialogRef: MatDialogRef<CreateSupplierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public supplier: SupplierInfo,
    private apiService: ApiService,
    private supplierService: SupplierService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.getAllAttendants();
    this.checkIfNeededToAddValidation();
  }

  companyNameFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  attendantFormControl: FormControl = new FormControl("");
  phoneNumberFormControl: FormControl = new FormControl("");
  emailFormControl: FormControl = new FormControl("");
  companyAddressFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  websiteFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);

  attendantSelectFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);

  supplierFormGroup: FormGroup = new FormGroup({
    company_name: this.companyNameFormControl,
    attendant: this.attendantFormControl,
    phone_number: this.phoneNumberFormControl,
    email: this.emailFormControl,
    address: this.companyAddressFormControl,
    website: this.websiteFormControl,
    attendant_select: this.attendantSelectFormControl
  });

  getRequiredErrorMessage(field) {
    return this.supplierFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  getEmailErrorMessage() {
    return this.emailFormControl.hasError("required")
      ? "You must enter a value"
      : this.emailFormControl.hasError("email")
      ? "Not a valid email"
      : "";
  }

  getAllAttendants() {
    this.attendants = [];
    this.apiService.getAllAttendants().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.attendants = data;
          this.attendants.push(this.newAttendant);
        }
      },
      error => {
        this.attendants = [];
      }
    );
  }

  handleCreateSupplier() {

    if (this.selectedAttendant !== "0") {
      this.supplier.attendant_id = this.selectedAttendant;
      this.supplier.name = undefined;
      this.supplier.email = undefined;
      this.supplier.phone_number = undefined;
    }
    this.apiService.createSupplier(this.supplier).subscribe(
      response => {
        this.apiService.getAllSuppliers().subscribe((data: any) => {
          if (data.length > 0) {
            this.supplierService.bindSuppliers(data);
          }
        });
        this.snackBar.open("Created successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Create failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.supplierFormGroup.invalid) {
        this.dialogRef.close();
        this.handleCreateSupplier();
      }
    }
  }

  onAttendantChange(event) {
    this.selectedAttendant = event.value;
    if (this.selectedAttendant === "0") {
      this.attendantFormControl.setValidators([Validators.required]);
      this.phoneNumberFormControl.setValidators([Validators.required]);
      this.emailFormControl.setValidators([
        Validators.required,
        Validators.email
      ]);
    } else {
      this.attendantFormControl.setValidators(null);
      this.phoneNumberFormControl.setValidators(null);
      this.emailFormControl.setValidators(null);
    }
    this.supplierFormGroup.updateValueAndValidity();
    console.log("update validity");
  }

  checkIfNeededToAddValidation() {
    if (this.attendants.length === 1) {
      this.attendantFormControl.setValidators([Validators.required]);
      this.phoneNumberFormControl.setValidators([Validators.required]);
      this.emailFormControl.setValidators([
        Validators.required,
        Validators.email
      ]);
    } else {
      this.attendantFormControl.setValidators(null);
      this.phoneNumberFormControl.setValidators(null);
      this.emailFormControl.setValidators(null);
    }
    this.supplierFormGroup.updateValueAndValidity();
  }
}
