import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Component } from '@angular/core';
import { UserService } from 'src/app/shared/user.service';


@Component({
  selector: 'reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['reset-password.component.css']
})
export class ResetPasswordComponent {

  selectedUser: any;
  hide = true;

  // ===== Form Validation ======================================================
  passwordFormControl: FormControl = new FormControl('', [
    Validators.required, 
    Validators.minLength(6)
  ]);

  resetFormGroup: FormGroup = new FormGroup({
    password: this.passwordFormControl
  });

  getPasswordErrorMessage() {
    return this.passwordFormControl.hasError('required') ? 'You must enter a value' : 
      this.passwordFormControl.hasError('minlength') ? 'Password must be at least 6 characters long' : '';
  }
  // ===========================================================================

  constructor(public dialogRef: MatDialogRef<ResetPasswordComponent>, 
              public userService: UserService, 
              private apiService: ApiService,
              private snackBar: MatSnackBar) { 
                dialogRef.disableClose = true;
              }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.userService.getSelectedUser.subscribe((selectedUser) => {
      this.selectedUser = selectedUser;
    });
  }

  handleResetPassword() {
    if(this.resetFormGroup.valid) {
      this.apiService.resetPassword(this.selectedUser._id, { pwd: this.selectedUser.pwd }).subscribe((response: any) => {
        if(response.status == 1) {
          this.snackBar.open('Reset successfully', null, {duration: 2000, horizontalPosition: 'right'});
        } else {
          this.snackBar.open('Reset failed!', null, {duration: 2000, horizontalPosition: 'right'});
        }
      });
    }
  }
}
