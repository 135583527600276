import {LoginPageComponent} from '../login-page/login-page.component';
import {Routes} from '@angular/router';


import {AdminPageComponent} from '../admin-page/admin-page.component';
import {DashboardComponent} from '../admin-page/menu-left/dashboard/dashboard.component';
import {IncomeComponent} from '../admin-page/menu-left/dashboard/income/income.component';
import {OutcomeComponent} from '../admin-page/menu-left/dashboard/outcome/outcome.component';
import {TableDetailComponent} from '../admin-page/menu-left/dashboard/table-detail/table-detail.component';
import {UserComponent} from '../admin-page/menu-left/dashboard/user/user.component';
import {AuthguardGuard} from '../auths/authguard.guard';
import {OutcomeDetailComponent} from '../admin-page/menu-left/dashboard/outcome/outcome-detail/outcome-detail.component';
import {RedeemDetailComponent} from '../admin-page/menu-left/dashboard/outcome/redeem-detail/redeem-detail.component';
import {DocumentComponent} from '../admin-page/menu-left/dashboard/document/document.component';
import {OfficeComponent} from '../admin-page/menu-left/dashboard/office/office.component';
import {PublicHolidayComponent} from '../admin-page/menu-left/dashboard/public-holiday/public-holiday.component';
import {PositionComponent} from '../admin-page/menu-left/dashboard/position/position.component';
import {AttendanceComponent} from '../admin-page/menu-left/dashboard/attendance/attendance.component';
import {LeaveRequestComponent} from '../admin-page/menu-left/dashboard/leave-request/leave-request.component';
import { CustomerComponent } from '../admin-page/menu-left/dashboard/customer/customer.component';
import { SupplierComponent } from '../admin-page/menu-left/dashboard/supplier/supplier.component';
import { ProjectComponent } from '../admin-page/menu-left/dashboard/project/project.component';
import { QuotationComponent } from '../admin-page/menu-left/dashboard/quotation/quotation.component';
import { CompareQuotationComponent } from '../admin-page/menu-left/dashboard/compare-quotation/compare-quotation.component';
import { CompanyComponent } from '../admin-page/menu-left/dashboard/company/company.component';
import { AttendanceReportComponent } from '../admin-page/menu-left/dashboard/attendance-report/attendance-report.component';
import { FinancialReportComponent } from '../admin-page/menu-left/dashboard/financial-report/financial-report.component';
import { ContactComponent } from '../admin-page/menu-left/dashboard/contact/contact.component';


export const adminRoutes: Routes = [
  {
    path: '',
    component: LoginPageComponent,
    data: {
      title: 'ចូលប្រើប្រាស់'
    }
  },
  {
    path: 'admin',
    component: AdminPageComponent,
    canActivate: [AuthguardGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        children: [
          {
            path: '',
            redirectTo: 'income',
            pathMatch: 'full',
          },
          {
            path: 'income',
            component: IncomeComponent,
            data: {
              title: 'ចំណូល'
            }
          },
          {
            path: 'companies',
            component: CompanyComponent,
            data: {
              title: 'ក្រុមហ៊ុន'
            }
          },
          // {
          //   path: 'customers',
          //   component: CustomerComponent,
          //   data: {
          //     title: 'អតិថិជន'
          //   }
          // },
          // {
          //   path: 'suppliers',
          //   component: SupplierComponent,
          //   data: {
          //     title: 'អ្នកផ្គត់ផ្គង់'
          //   }
          // },
          {
            path: 'projects',
            component: ProjectComponent,
            data: {
              title: 'គម្រោង'
            }
          },
          {
            path: 'quotations',
            component: QuotationComponent,
            data: {
              title: 'ព័ត៌មានសម្រង់តម្លៃ'
            }
          },
          {
            path: 'compare_quotations',
            component: CompareQuotationComponent,
            data: {
              title: 'ប្រៀបធៀបព័ត៌មានសម្រង់តម្លៃ'
            }
          },
          {
            path: 'outcome',
            component: OutcomeComponent,
            data: {
              title: 'ចំណាយ'
            }
          },
          {
            path: 'outcome/detail/:type',
            component: OutcomeDetailComponent,
          },
          {
            path: 'outcome/redeem/detail/:type',
            component: RedeemDetailComponent
          },
          {
            path: 'detail/:name/:id',
            component: TableDetailComponent
          },
          {
            path: 'documents',
            component: DocumentComponent,
            data: {
              title: 'ឯកសារ'
            }
          },
          {
            path: 'users',
            component: UserComponent,
            data: {
              title: 'អ្នកប្រើប្រាស់'
            }
          },
          {
            path: 'positions',
            component: PositionComponent,
            data: {
              title: 'តួនាទី'
            }
          },
          {
            path: 'offices',
            component: OfficeComponent,
            data: {
              title: 'ការិយាល័យ'
            }
          },
          {
            path: 'public-holidays',
            component: PublicHolidayComponent,
            data: {
              title: 'ថ្ងៃឈប់សម្រាកសាធារណៈ'
            }
          },
          {
            path: 'attendances',
            component: AttendanceComponent,
            data: {
              title: 'វត្តមាន'
            }
          },
          {
            path: 'attendances-report',
            component: AttendanceReportComponent,
            data: {
              title: 'របាយការណ៍វត្តមាន'
            }
          },
          {
            path: 'financial-report',
            component: FinancialReportComponent,
            data: {
              title: 'របាយការណ៍ហិរញ្ញវត្ថុ'
            }
          },
          {
            path: 'leave-requests',
            component: LeaveRequestComponent,
            data: {
              title: 'Leave Requests'
            }
          },
          {
            path: 'contact',
            component: ContactComponent,
            data: {
              title: 'ទំនាក់ទំនង'
            }
          }
        ]
      }
    ]
  },
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

