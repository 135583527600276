import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../../shared/api.service';
import {map} from 'rxjs/operators';
import {OfficeModel} from '../../../../models/office.model';
import {animate, query, stagger, state, style, transition, trigger} from '@angular/animations';
import {MatDialog} from '@angular/material';
import {OfficeDialogComponent} from '../office-dialog/office-dialog.component';
import {Subscription} from 'rxjs';
import {OfficeDeleteDialogComponent} from '../office-delete-dialog/office-delete-dialog.component';


@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.css'],
  animations: [
    trigger('fade', [
      state('void', style({opacity: 0, transform: 'translateY(20px)'})),
      transition('void => *', animate('.5s ease'))
    ]),
    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({opacity: 0, transform: 'translateX(-40px)'}),
            stagger(
              '60ms',
              animate(
                '400ms ease',
                style({opacity: 1, transform: 'translateX(0px)'})
              )
            )
          ],
          {optional: true}
        ),
        query(':leave', animate('50ms', style({opacity: 0})), {
          optional: true
        })
      ])
    ])
  ]
})
export class OfficeComponent implements OnInit, OnDestroy {

  offices: OfficeModel[] = [];
  isLoading = true;
  collapseHash = new Map<string, boolean>();
  subscription = new Subscription();


  constructor(private api: ApiService,
              private dialog: MatDialog) {

  }

  ngOnInit(): void {
    this.getOffices();
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getOffices() {
    this.isLoading = true;
    this.subscription.add(this.api.getOfficesTree().pipe(map(x => x.offices))
      .subscribe(offices => {
        this.saveCollapseMap(this.offices);
        this.offices = this._patchOffices(offices);
        this.isLoading = false;
        console.log(offices);
        this.collapseHash.clear();
      }, err => this.isLoading = false));
  }


  clickCollapse(office: OfficeModel) {
    if (!office.collapse) {
      this.traverseCollapse(office);
    }
  }

  traverseCollapse(office: OfficeModel) {
    office.collapse = false;
    if (office.childs.length) {
      office.childs.forEach(x => this.traverseCollapse(x));
    }
  }

  private saveCollapseMap(offices: OfficeModel[]): void {
    offices.forEach(office => {
      if (!this.collapseHash.get(office._id)) {
        this.collapseHash.set(office._id, !!office.collapse);
      }

      if (office.childs) {
        this.saveCollapseMap(office.childs);
      }
    });
  }

  private _patchOffices(offices: OfficeModel[], parentId: string = null): OfficeModel[] {
    return offices.map(office => {
      office.collapse = !!this.collapseHash.get(office._id);
      if (office.childs) {
        office.parent_id = parentId;
        office.childs = this._patchOffices(office.childs, office._id);
      }
      return office;
    });
  }

  createRootOffice() {
    this.dialog.open(OfficeDialogComponent, {
      width: '340px'
    }).afterClosed().subscribe(response => {
      if (response) {
        this.getOffices();
      }
    });
  }

  createOffice(parent: OfficeModel) {
    this.dialog.open(OfficeDialogComponent, {
      width: '340px',
      data: {
        parent
      }
    }).afterClosed().subscribe(response => {
      if (response) {
        if (response.office) {
          const parentId = response.office.parent;
          if (parentId) {
            if (!this.collapseHash.get(parentId)) {
              this.collapseHash.set(parentId, true);
            }
          }
        }
        this.getOffices();
      }
    });
  }

  updateOffice(currentOffice: OfficeModel) {
    this.dialog.open(OfficeDialogComponent, {
      width: '340px',
      data: {
        office: currentOffice
      }
    }).afterClosed().subscribe(response => {
      if (response) {
        this.getOffices();
      }
    });
  }

  deleteOffice(office: OfficeModel) {
    this.dialog.open(OfficeDeleteDialogComponent, {
      width: '360px',
      data: office._id
    }).afterClosed().subscribe(response => {
      if (response) {
        this.getOffices();
      }
    });
  }


}
