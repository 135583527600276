import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  quotations: Array<object> = [];
  selectedQuotation: any;
  private bs_all_quotations = new BehaviorSubject<any>(this.quotations);
  private bs_selected_quotation = new BehaviorSubject<any>(this.selectedQuotation);



  getAllQuotations = this.bs_all_quotations.asObservable();
  getSelectedQuotation = this.bs_selected_quotation.asObservable();

  constructor() {}

  bindQuotations(quotations: any) {
    this.bs_all_quotations.next(quotations);
  }

  bindSelectedQuotation(quotation: any){
    this.bs_selected_quotation.next(quotation);
  }
}
