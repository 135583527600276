import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { QuotationService } from "../quotation.service";

@Component({
  selector: "app-edit-quotation-dialog",
  templateUrl: "./edit-quotation-dialog.component.html",
  styleUrls: ["./edit-quotation-dialog.component.scss"]
})
export class EditQuotationDialogComponent implements OnInit {
  projects: any;
  suppliers: any;
  customers: any;
  types = ["recieved", "issued"];
  statuses = ["open", "close"];
  selectedQuotation: any;
  firstTime = true;
  attendants: any;
  newAttendant = { _id: "0", name: "Create new attendant" };
  selectedSupplier: any;
  selectedCustomer: any;

  constructor(
    public dialogRef: MatDialogRef<EditQuotationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private quotationService: QuotationService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  titleFormControl: FormControl = new FormControl("", [Validators.required]);
  descriptionFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  quotationNoFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  vatInFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);
  typeFormControl: FormControl = new FormControl("", [Validators.required]);
  statusFormControl: FormControl = new FormControl("", [Validators.required]);
  projectFormControl: FormControl = new FormControl("", [Validators.required]);
  supplierFormControl: FormControl = new FormControl("");
  customerFormControl: FormControl = new FormControl("");

  attendantFormControl: FormControl = new FormControl("", [Validators.required]);
  attendantNameFormControl: FormControl = new FormControl("");
  phoneNumberFormControl: FormControl = new FormControl("");
  emailFormControl: FormControl = new FormControl("");

  quotationFormGroup: FormGroup = new FormGroup({
    title: this.titleFormControl,
    description: this.descriptionFormControl,
    quotation_no: this.quotationNoFormControl,
    vat_in: this.vatInFormControl,
    type: this.typeFormControl,
    status: this.statusFormControl,
    project: this.projectFormControl,
    supplier: this.supplierFormControl,
    customer: this.customerFormControl,
    attendant_id: this.attendantFormControl,
    attendant_name: this.attendantNameFormControl,
    attendant_phone_number: this.phoneNumberFormControl,
    attendant_email: this.emailFormControl
  });

  getRequiredErrorMessage(field) {
    return this.quotationFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  getEmailErrorMessage() {
    return this.emailFormControl.hasError("required")
      ? "You must enter a value"
      : this.emailFormControl.hasError("email")
      ? "Not a valid email"
      : "";
  }

  ngOnInit() {
    this.quotationService.getSelectedQuotation.subscribe(
      selectedQuotation => (this.selectedQuotation = selectedQuotation)
    );
    this.getProjects();
    this.getCompanies();
    this.preventNullData();
    console.log(this.attendants);
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.quotationFormGroup.invalid) {
        this.dialogRef.close();
        this.handleEditQuotation();
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.apiService.getAllQuotations().subscribe((data: any) => {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (this.selectedQuotation._id == data[i]._id) {
            this.selectedQuotation.title = data[i].title;
            this.selectedQuotation.description = data[i].description;
            this.selectedQuotation.quotation_number = data[i].quotation_number;
            this.selectedQuotation.type = data[i].type;
            this.selectedQuotation.status = data[i].status;
            this.selectedQuotation.project_id = data[i].project._id;
            this.selectedQuotation.supplier_id = data[i].supplier._id;
            this.selectedQuotation.customer_id = data[i].customer._id;
            this.selectedQuotation.attendant_name = data[i].attendant.name;
            this.selectedQuotation.attendant_email = data[i].attendant.email;
            this.selectedQuotation.attendant_phone_numnber =
              data[i].attendant.phone_number;
          }
        }
      }
    });
  }

  getProjects() {
    this.apiService.getAllProjects().subscribe(projects => {
      this.projects = [];
      this.projects = projects;
    });
  }

  getCompanies() {
    this.apiService.getAllCompanies().subscribe((data: any) => {
      this.customers = [];
      this.suppliers = [];
      this.customers = data;
      this.suppliers = this.customers;
      if (this.firstTime) {
        this.getAttendants();
      }
    });
  }

  getAttendants() {
    if (this.object.quotation.type === "issued") {
      this.apiService
        .getAttendantsByCompany(this.selectedCustomer)
        .subscribe((data: any) => {
          this.attendants = [];
          this.attendants = data;
          this.attendants.push(this.newAttendant);
        });
    }

    if (this.object.quotation.type === "recieved") {
      this.apiService
        .getAttendantsByCompany(this.selectedSupplier)
        .subscribe((data: any) => {
          this.attendants = [];
          this.attendants = data;
          this.attendants.push(this.newAttendant);
        });
    }
    this.firstTime = false;
    this.quotationFormGroup.updateValueAndValidity();
  }

  getSuppliers() {
    this.apiService.getAllSuppliers().subscribe(suppliers => {
      this.suppliers = [];
      this.suppliers = suppliers;
    });
  }

  getCustomers() {
    this.apiService.getAllCustomers().subscribe(customers => {
      this.customers = [];
      this.customers = customers;
    });
  }

  onCustomerChange(event) {
    this.getAttendants();
    this.quotationFormGroup.updateValueAndValidity();
  }

  onSupplierChange(event) {
    this.getAttendants();
    this.quotationFormGroup.updateValueAndValidity();
  }

  onTypeChange(event) {
    this.getCompanies();
    this.quotationFormGroup.updateValueAndValidity();
  }

  preventNullData() {
    if (this.object.quotation.supplier !== null) {
      this.selectedSupplier = this.object.quotation.supplier._id;
    }
    if (this.object.quotation.customer !== null) {
      this.selectedCustomer = this.object.quotation.customer._id;
    }
  }

  onAttendantChange(event) {
    if (event.value === "0") {
      this.object.quotation.attendant.name = "";
      this.object.quotation.attendant.phone_number = "";
      this.object.quotation.attendant.email = "";
      this.attendantNameFormControl.setValidators([Validators.required]);
      this.phoneNumberFormControl.setValidators([Validators.required]);
      this.emailFormControl.setValidators(null);
    } else {
      this.attendantNameFormControl.setValidators(null);
      this.phoneNumberFormControl.setValidators(null);
      this.emailFormControl.setValidators(null);
      const selectedAtt = this.attendants.filter(
        item => item._id === event.value
      )[0];
      this.object.quotation.attendant.name = selectedAtt.name;
      this.object.quotation.attendant.phone_number = selectedAtt.phone_number;
      this.object.quotation.attendant.email = selectedAtt.email;
    }
    this.quotationFormGroup.updateValueAndValidity();
  }

  handleEditQuotation() {
    let newQuotation = {
      title: this.object.quotation.title,
      description: this.object.quotation.description,
      quotation_number: this.object.quotation.quotation_number,
      type: this.object.quotation.type,
      status: this.object.quotation.status,
      project_id: this.object.quotation.project._id,
      supplier_id: this.selectedSupplier,
      customer_id: this.selectedCustomer,
      attendant_id: this.object.quotation.attendant._id,
      attendant_name: this.object.quotation.attendant.name,
      attendant_email: this.object.quotation.attendant.email,
      attendant_phone_number: this.object.quotation.attendant.phone_number,
      attendant_company_id: "0"
    };
    if (newQuotation.attendant_id === "0") {
      newQuotation.attendant_id = undefined;
    }
    if (newQuotation.type === "recieved") {
      newQuotation.customer_id = undefined;
      newQuotation.attendant_company_id = newQuotation.supplier_id;
    }
    if (newQuotation.type === "issued") {
      newQuotation.supplier_id = undefined;
      newQuotation.attendant_company_id = newQuotation.customer_id;
    }
    this.apiService
      .editQuotation(this.object.quotation._id, newQuotation)
      .subscribe(
        response => {
          this.snackBar.open("Updated successfully", null, {
            duration: 2000,
            horizontalPosition: "right"
          });
          this.apiService.getAllQuotations().subscribe((data: any) => {
            if (data.length > 0) {
              this.quotationService.bindQuotations(data);
            }
          });
        },
        error => {
          this.snackBar.open("Update failed!", null, {
            duration: 2000,
            panelClass: ["red-snackbar"],
            horizontalPosition: "right"
          });
        }
      );
  }
}
