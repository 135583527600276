import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/shared/api.service";
import { ProjectService } from "./project.service";
import { ProjectPagination } from "src/app/models/project.model";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { DeleteProjectDialogComponent } from "./delete-project-dialog/delete-project-dialog.component";
import { CreateProjectDialogComponent } from "./create-project-dialog/create-project-dialog.component";
import { EditProjectDialogComponent } from "./edit-project-dialog/edit-project-dialog.component";
import { CreateProjectFileDialogComponent } from "./create-project-file-dialog/create-project-file-dialog.component";
import { ProjectFileDisplayDialogComponent } from "./project-file-display-dialog/project-file-display-dialog.component";

@Component({
  selector: "app-project",
  templateUrl: "./project.component.html",
  styleUrls: ["./project.component.scss"]
})
export class ProjectComponent implements OnInit {
  isLoading = true;
  projects: any;
  projectPagination = new ProjectPagination();
  page_increasement = 0;
  searchKeyword: string = "";

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private projectService: ProjectService,
    private route: Router
  ) { }

  ngOnInit() {
    this.getAllProjects();
    this.projectService.getAllProjects.subscribe(
      projects => (this.projects = projects)
    );
  }

  getAllProjects() {
    this.isLoading = true;
    this.apiService.getAllProjects().subscribe(
      (data: any) => {

        setTimeout(() => {
          this.isLoading = false;
        }, 500);

        if (data.length > 0) {
          this.projects = [];
          this.projects = data;
          console.log(this.projects);
        }
      },
      (error: any) => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    );
  }

  openCreateProjectDialog(): void {
    const dialogRef = this.dialog.open(CreateProjectDialogComponent, {
      width: "800px",
      height: "auto",
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  openEditProjectDialog(project: any): void {
    this.projectService.bindSelectedProject(project);
    const dialogRef = this.dialog.open(EditProjectDialogComponent, {
      width: "800px",
      height: "auto",
      data: {
        project: project
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  openCreateProjectFileDialog(projectID: any) {
    const dialogRef = this.dialog.open(CreateProjectFileDialogComponent, {
      width: '400px',
      height: 'auto',
      data: {
        project_id: projectID
      }
    });
    dialogRef.afterClosed().subscribe(result => { });
  }

  openDeleteProjectDialog(projectID: string): void {
    const dialogRef = this.dialog.open(DeleteProjectDialogComponent, {
      width: "400px",
      height: "auto",
      data: {
        project_id: projectID
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }
  handleSearch() {
    this.loadList();
  }

  openDisplayFileDialog(project: any) {
    const dialogRef = this.dialog.open(ProjectFileDisplayDialogComponent, {
      width: "1200px",
      height: "auto",
      data: {
        project: project
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.handleSearch();
    }
  }

  onSearchChange(event) {
    if (event.target.value === "") {
      this.handleSearch();
    }
  }

  // count selector
  selectCountHandler(event: any) {
    this.projectPagination.count = event.target.value;
    this.loadList();
  }

  currentPage = 1;
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.projectPagination.page = this.currentPage;
    this.loadList();
  }

  nextClick() {
    this.currentPage = this.currentPage + 1;
    this.projectPagination.page = this.currentPage;
    this.loadList();
  }

  loadList() {
    this.apiService
      .getListProjects(this.projectPagination, this.searchKeyword)
      .subscribe(Response => {
        this.page_increasement =
          (this.currentPage - 1) * this.projectPagination.count;
        if (Response["status"] == 401) {
          this.route.navigateByUrl("/");
        } else {
          this.projects = Response;
        }
      });
    this.projectPagination.export = false;
  }
}

export interface ProjectInfo {
  name: string;
  description: string;
  manager: string;
  customer_id: string;
  start_date: string;
  end_date: string;
}

export interface ProjectFileInfo {
  project_id: string;
  title: string;
  description: string;
  file: File;
}
