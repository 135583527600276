import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-delete-project-dialog',
  templateUrl: './delete-project-dialog.component.html',
  styleUrls: ['./delete-project-dialog.component.scss']
})
export class DeleteProjectDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private projectService: ProjectService,
    private snackBar: MatSnackBar) { 
      dialogRef.disableClose = true;
    }

    onNoClick(): void {
      this.dialogRef.close();
    }
  
    ngOnInit() {}
  
    handleDeleteProject() {
      
      this.apiService.deleteProject(this.object.project_id).subscribe(
        (response: any) => {
          this.apiService.getAllProjects().subscribe((data: any) => {
            this.projectService.bindProjects(data);
          });
          this.snackBar.open("Deleted successfully", null, {
            duration: 2000,
            horizontalPosition: "right"
          });
        },
        error => {
          this.snackBar.open("Delete failed!", null, {
            duration: 2000,
            panelClass: ["red-snackbar"],
            horizontalPosition: "right"
          });
        }
      );
    }
}
