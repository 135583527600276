import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { UserService } from 'src/app/shared/user.service';
import { ContactModel } from 'src/app/models/contact.model';

@Component({
  selector: 'contact-detail',
  templateUrl: 'contact-detail.component.html',
  styleUrls: ['contact-detail.component.scss']
})
export class ContactDetailComponent {

  contact: any;
  phone_logo: string = './../../../../../../assets/images/smart_logo.jpg';
  prefix_cellcard: Array<string> = ['011','012','017','061','076','077','078','079','085','089','092','095','099'];
  prefix_smart: Array<string> = ['010','015','016','069','070','081','086','087','093','098','096'];
  prefix_metfone: Array<string> = ['031','060','066','067','068','071','088','090','097'];
  prefix_qb: Array<string> = ['013','080','083','084'];
  prefix_cootel: Array<string> = ['038'];
  prefix_seatel: Array<string> = ['018'];

  constructor(public dialogRef: MatDialogRef<ContactDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private userService: UserService,
    private snackBar: MatSnackBar) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.contact = this.object.contact;
    console.log(this.contact);
  }

  getMobilePhoneLogo(phone: string) {
    const prefix_number = phone.substring(0, 3);
    if(this.prefix_cellcard.indexOf(prefix_number) >= 0) return './../../../../../../assets/images/cellcard_logo.png';
    else if(this.prefix_smart.indexOf(prefix_number) >= 0) return './../../../../../../assets/images/smart_logo.jpg';
    else if(this.prefix_metfone.indexOf(prefix_number) >= 0) return './../../../../../../assets/images/metfone_logo.jpg';
    else if(this.prefix_qb.indexOf(prefix_number) >= 0) return './../../../../../../assets/images/qb_logo.jpg';
    else if(this.prefix_cootel.indexOf(prefix_number) >= 0) return './../../../../../../assets/images/cootel_logo.jpg';
    else if(this.prefix_seatel.indexOf(prefix_number) >= 0) return './../../../../../../assets/images/seatel_logo.png';
    else return './../../../../../../assets/images/unknown_number_logo.jpg';
  }
}