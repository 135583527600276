import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { QuotationFileService } from '../quotation-file.service';

@Component({
  selector: 'app-delete-quotation-file-dialog',
  templateUrl: './delete-quotation-file-dialog.component.html',
  styleUrls: ['./delete-quotation-file-dialog.component.scss']
})
export class DeleteQuotationFileDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteQuotationFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private quotationFileService: QuotationFileService,
    private snackBar: MatSnackBar) { 
      dialogRef.disableClose = true;
    }

    onNoClick(): void {
      this.dialogRef.close();
    }
  
    ngOnInit() {
      console.log(this.object);
    }
  
    handleDeleteQuotationFile() {
      
      this.apiService.deleteQuotationFile(this.object.quotation_file._id).subscribe(
        (response: any) => {
          this.apiService.getAllQuotationFiles(this.object.quotation_file.quotation._id).subscribe((data: any) => {
            this.quotationFileService.bindQuotationFiles(data);
          });
          this.snackBar.open("Deleted successfully", null, {
            duration: 2000,
            horizontalPosition: "right"
          });
        },
        error => {
          this.snackBar.open("Delete failed!", null, {
            duration: 2000,
            panelClass: ["red-snackbar"],
            horizontalPosition: "right"
          });
        }
      );
    }
}
