import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { ProjectFileService } from "../project-file.service";

@Component({
  selector: "app-delete-project-file-dialog",
  templateUrl: "./delete-project-file-dialog.component.html",
  styleUrls: ["./delete-project-file-dialog.component.scss"]
})
export class DeleteProjectFileDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteProjectFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private projectFileService: ProjectFileService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleDeleteProjectFile() {
      
    this.apiService.deleteProjectFile(this.object.projectFile._id).subscribe(
      (response: any) => {
        this.apiService.getAllProjectFiles(this.object.projectFile.project._id).subscribe((data: any) => {
          this.projectFileService.bindProjectFiles(data);
        });
        this.snackBar.open("Deleted successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Delete failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }
}
