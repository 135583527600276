import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { CompanyService } from "../company.service";
import { FormControl, Validators, FormGroup } from "@angular/forms";

@Component({
  selector: "app-edit-company-dialog",
  templateUrl: "./edit-company-dialog.component.html",
  styleUrls: ["./edit-company-dialog.component.scss"]
})
export class EditCompanyDialogComponent implements OnInit {
  selectedCompany: any;
  constructor(
    public dialogRef: MatDialogRef<EditCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  nameFormControl: FormControl = new FormControl("", [Validators.required]);

  addressFormControl: FormControl = new FormControl("", [Validators.required]);

  websiteFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);

  vatinFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);

  companyFormGroup: FormGroup = new FormGroup({
    name: this.nameFormControl,
    address: this.addressFormControl,
    website: this.websiteFormControl,
    vatin: this.vatinFormControl
  });

  getRequiredErrorMessage(field) {
    return this.companyFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  ngOnInit() {
    this.companyService.getSelectedCompany.subscribe(
      selectedCompany => (this.selectedCompany = selectedCompany)
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.apiService.getAllCompanies().subscribe((data: any) => {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (this.selectedCompany._id == data[i]._id) {
            this.selectedCompany.company_name = data[i].company_name;
            this.selectedCompany.address = data[i].address;
            this.selectedCompany.website = data[i].website;
            this.selectedCompany.VATIN = data[i].VATIN;
          }
        }
      }
    });
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.companyFormGroup.invalid) {
        this.dialogRef.close();
        this.handleUpdateCompany();
      }
    }
  }

  handleUpdateCompany() {
    const newComapany = {
      company_name: this.object.company.company_name,
      address: this.object.company.address,
      website: this.object.company.website,
      VATIN: this.object.company.VATIN
    };
    this.apiService.editCompany(this.object.company._id, newComapany).subscribe(
      response => {
        this.snackBar.open("Updated successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Update failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }
}
