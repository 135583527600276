import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../../../../shared/api.service';
import { DocumentService } from '../../../../../shared/document.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeleteDirectoryComponent } from './delete-dir-dialogs/delete-dir.component';
import { MatDialog } from '@angular/material';
import { RenameDirectoryComponent } from './rename-dir-dialogs/rename-dir.component';
import { DeleteFileComponent } from './delete-file-dialogs/delete-file.component';
import { RenameFileComponent } from './rename-file-dialogs/rename-file.component';
import { DownloadFileComponent } from './download-file-dialogs/download-file.component';

@Component({
  selector: 'app-file-block',
  templateUrl: './file-block.component.html',
  styleUrls: ['./file-block.component.css']
})
export class FileBlockComponent implements OnInit {
  directories: Array<object> = [];
  child_directories: any = [];
  child_files: any = [];
  dir_name: string = '';
  file_name: string = '';
  parentID: string = '';
  toggle: boolean;

  constructor(private apiService: ApiService, private documentService: DocumentService, public dialog: MatDialog) { }

  ngOnInit() {
    this.documentService.newListDir.subscribe(directories => this.directories = directories);
    this.documentService.getDirectories.subscribe(child_directories => this.child_directories = child_directories);
    this.documentService.getFiles.subscribe(child_files => this.child_files = child_files);
    this.documentService.getParentID.subscribe(parentID => this.parentID = parentID);
  }

  dropDir(event: CdkDragDrop<string[]>) {
    let list_changed_order: any = { "directories": [] };
    let changed_dir: any = {};
    if (event.previousIndex < event.currentIndex) {
      for (let i = event.previousIndex; i < event.currentIndex; i++) {
        changed_dir = {
          "id": this.child_directories[i + 1]._id,
          "order_number": this.child_directories[i].order_number
        };
        list_changed_order.directories.push(changed_dir);
      }
      changed_dir = {
        "id": this.child_directories[event.previousIndex]._id,
        "order_number": this.child_directories[event.currentIndex].order_number
      }
      list_changed_order.directories.push(changed_dir);
    } else {
      for (let i = event.previousIndex; i > event.currentIndex; i--) {
        changed_dir = {
          "id": this.child_directories[i - 1]._id,
          "order_number": this.child_directories[i].order_number
        };
        list_changed_order.directories.push(changed_dir);
      }
      changed_dir = {
        "id": this.child_directories[event.previousIndex]._id,
        "order_number": this.child_directories[event.currentIndex].order_number
      }
      list_changed_order.directories.push(changed_dir);
    }

    moveItemInArray(this.child_directories, event.previousIndex, event.currentIndex);
    this.apiService.changeDirOrder(list_changed_order).subscribe((response) => {
      this.apiService.getSubItems(this.parentID).subscribe((data: any) => {
        this.child_directories = data.directories;
      });
    });
  }

  dropFile(event: CdkDragDrop<string[]>) {
    let list_changed_order: any = { "files": [] };
    let changed_file: any = {};
    if (event.previousIndex < event.currentIndex) {
      for (let i = event.previousIndex; i < event.currentIndex; i++) {
        changed_file = {
          "id": this.child_files[i + 1]._id,
          "order_number": this.child_files[i].order_number
        };
        list_changed_order.files.push(changed_file);
      }
      changed_file = {
        "id": this.child_files[event.previousIndex]._id,
        "order_number": this.child_files[event.currentIndex].order_number
      }
      list_changed_order.files.push(changed_file);
    } else {
      for (let i = event.previousIndex; i > event.currentIndex; i--) {
        changed_file = {
          "id": this.child_files[i - 1]._id,
          "order_number": this.child_files[i].order_number
        };
        list_changed_order.files.push(changed_file);
      }
      changed_file = {
        "id": this.child_files[event.previousIndex]._id,
        "order_number": this.child_files[event.currentIndex].order_number
      }
      list_changed_order.files.push(changed_file);
    }

    moveItemInArray(this.child_files, event.previousIndex, event.currentIndex);
    this.apiService.changeFileOrder(list_changed_order).subscribe((response) => {
      this.apiService.getSubItems(this.parentID).subscribe((data: any) => {
        this.child_files = data.files;
      });
    });
  }

  dirDropdownFunc(num: any) {
    if ($("#myDropdownDir" + num).is(':visible')) {
      $("#myDropdownDir" + num).hide();
    } else {
      $('div[id^="myDropdown"]').css("display", "none");
      $("#myDropdownDir" + num).show();
    }
  }

  fileDropdownFunc(num: any) {
    if ($("#myDropdownFile" + num).is(':visible')) {
      $("#myDropdownFile" + num).hide();
    } else {
      $('div[id^="myDropdown"]').css("display", "none");
      $("#myDropdownFile" + num).show();
    }
  }

  changeDateTimeFormat(date_time: string) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var date = new Date(date_time);
    var day = date.getDate();
    var monthIndex = monthNames[date.getMonth()];
    var year = date.getFullYear();
    return monthIndex + " " + day + ", " + year;
  }

  //----- Directory's operational events ----------------------------------------
  openDeleteDirDialog(dir_id: string): void {
    const dialogRef = this.dialog.open(DeleteDirectoryComponent, {
      width: '400px',
      height: 'auto',
      data: {
        dir_id: dir_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openRenameDirDialog(dir: any): void {
    const dialogRef = this.dialog.open(RenameDirectoryComponent, {
      width: '400px',
      height: 'auto',
      data: {
        dir: dir
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  //---------------------------------------------------------------------------- 


  //----- File's operational events --------------------------------------------
  openDeleteFileDialog(file_id: string): void {
    const dialogRef = this.dialog.open(DeleteFileComponent, {
      width: '400px',
      height: 'auto',
      data: {
        file_id: file_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openRenameFileDialog(file: any): void {
    const dialogRef = this.dialog.open(RenameFileComponent, {
      width: '400px',
      height: 'auto',
      data: {
        file: file
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openDownloadFileDialog(file: any): void {
    const dialogRef = this.dialog.open(DownloadFileComponent, {
      width: '400px',
      height: 'auto',
      data: {
        file: file
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  //-------------------------------------------------------------------------- 

  onNameChange(newName: string) {
    this.dir_name = newName;
  }

  onFileNameChange(newName: string) {
    this.file_name = newName;
  }

  openDir(dir_id: string) {
    this.apiService.getSubItems(dir_id).subscribe((data: any) => {
      if (data.directories != []) this.child_directories = data.directories;
      if (data.files != []) this.child_files = data.files;
      for (let i = 0; i < this.directories.length; i++) {
        if (this.recursiveBindPath(this.directories[i], dir_id)) {
          break;
        }
      }
    });
  }

  recursiveBindPath(dir: any, obj_id: string) {
    if (dir._id == obj_id) {
      this.documentService.bindClickDirectory(dir);
      let getPath = dir.path.split('/');
      getPath.push(dir.name);
      this.documentService.bindPath(getPath);
      return true;
    } else if (dir.childs != []) {
      for (let i = 0; i < dir.childs.length; i++) {
        if (this.recursiveBindPath(dir.childs[i], obj_id)) {
          break;
        };
      }
    }
  }

  getSelectedIDList(parent_id: string) {
    this.apiService.getDirectories().subscribe((data: any) => {
      if (data.status == 1 && data.directories.length > 0) {
        for (let i = 0; i < data.directories.length; i++) {
          let listID = [];
          if (this.recursiveFindDir(data.directories[i], parent_id, listID)) {
            break;
          }
        }
      }
    });
  }

  recursiveFindDir(dir: any, obj_id: any, listID: any) {
    listID.push(dir._id);
    if (dir._id == obj_id) {
      return listID;
    } else if (dir.childs != []) {
      for (let i = 0; i < dir.childs.length; i++) {
        if (this.recursiveFindDir(dir.childs[i], obj_id, listID)) {
          break;
        };
      }
    }
  }
}

export interface DirInfo {
  dir_id: string;
  index: number;
}