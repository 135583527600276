import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs'
import { UserLoginService } from '../../../../shared/userlogin.service';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-outcome',
  templateUrl: './outcome.component.html',
  styleUrls: ['./outcome.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ],
})
export class OutcomeComponent implements OnInit {

  today = new Date();
  minDate = new Date(2017, 1, 1);
  maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  bsValue = new Date();
  listPromotion: any[];
  listRedeem: any[];
  startDate: string = '';
  endDate: string = '';
  isLoadingPromotion = true;
  isLoadingRedeem = true;

  constructor(private userLoginService: UserLoginService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.startDate = this.getDefaultStartDate();
    this.endDate = this.getDefaultEndDate();
    this.userLoginService.setStartDate(this.datePipe.transform(new Date(this.getDefaultStartDate()), 'yyyy-MM-dd'));
    this.userLoginService.setEndDate(this.datePipe.transform(new Date(this.getDefaultEndDate()), 'yyyy-MM-dd'));

    this.loadListPromotion();
    this.loadListRedeem();
  }

  getDefaultStartDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-01';
  }

  getDefaultEndDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let dd: any = this.daysInMonth(mm, yyyy);

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-' + dd;
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  onStartDateChange(date: any) {
    this.startDate = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.userLoginService.setStartDate(this.datePipe.transform(date.value, 'yyyy-MM-dd'));
    this.loadListPromotion();
    this.loadListRedeem();
  }

  onEndDateChange(date: any) {
    this.endDate = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.userLoginService.setEndDate(this.datePipe.transform(date.value, 'yyyy-MM-dd'));
    this.loadListPromotion();
    this.loadListRedeem();
  }

  loadListPromotion() {
    this.isLoadingPromotion = true;
    this.userLoginService.getListPromotion(this.startDate, this.endDate).subscribe(Response => {
      this.listPromotion = Response['promotion'];
      setTimeout(() => {
        this.isLoadingPromotion = false;
      }, 500);
    })
  }

  loadListRedeem() {
    this.isLoadingRedeem = true;
    this.userLoginService.getListRedeem(this.startDate, this.endDate).subscribe(Response => {
      this.listRedeem = Response['redeem'];
      setTimeout(() => {
        this.isLoadingRedeem = false;
      }, 500);
    })
  }

  getToday() {
    let today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-' + dd;
  }
}
