import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UserLoginService } from "./shared/userlogin.service";
import { AuthguardGuard } from "./auths/authguard.guard";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import { HttpClientModule } from "@angular/common/http";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { NgxSpinnerModule } from "ngx-spinner";

import { AppComponent } from "./app.component";
import { LoginPageComponent } from "./login-page/login-page.component";
import { AdminPageComponent } from "./admin-page/admin-page.component";
import { MenuLeftComponent } from "./admin-page/menu-left/menu-left.component";
import { DashboardComponent } from "./admin-page/menu-left/dashboard/dashboard.component";
import { UserComponent } from "./admin-page/menu-left/dashboard/user/user.component";
import { CreateUserComponent } from "./admin-page/menu-left/dashboard/user/create-user-dialogs/create-user.component";
import { CreateDirectoryComponent } from "./admin-page/menu-left/dashboard/document/top-url/create-dir-dialogs/create-dir.component";
import { DeleteDirectoryComponent } from "./admin-page/menu-left/dashboard/document/file-block/delete-dir-dialogs/delete-dir.component";
import { RenameDirectoryComponent } from "./admin-page/menu-left/dashboard/document/file-block/rename-dir-dialogs/rename-dir.component";
import { DeleteFileComponent } from "./admin-page/menu-left/dashboard/document/file-block/delete-file-dialogs/delete-file.component";
import { RenameFileComponent } from "./admin-page/menu-left/dashboard/document/file-block/rename-file-dialogs/rename-file.component";
import { DownloadFileComponent } from "./admin-page/menu-left/dashboard/document/file-block/download-file-dialogs/download-file.component";
import { EditUserComponent } from "./admin-page/menu-left/dashboard/user/edit-user-dialogs/edit-user.component";
import { ResetPasswordComponent } from "./admin-page/menu-left/dashboard/user/reset-password-dialogs/reset-password.component";
import { ChangePasswordComponent } from "./admin-page/change-password-dialogs/change-password.component";
import { StatusComponent } from "./admin-page/menu-left/dashboard/leave-request/status-dialogs/status.component";
import { TableDetailComponent } from "./admin-page/menu-left/dashboard/table-detail/table-detail.component";
import { IncomeComponent } from "./admin-page/menu-left/dashboard/income/income.component";
import { OutcomeComponent } from "./admin-page/menu-left/dashboard/outcome/outcome.component";
import { adminRoutes } from "./shared/route";
import { OutcomeDetailComponent } from "./admin-page/menu-left/dashboard/outcome/outcome-detail/outcome-detail.component";
import { RedeemDetailComponent } from "./admin-page/menu-left/dashboard/outcome/redeem-detail/redeem-detail.component";
import { DocumentComponent } from "./admin-page/menu-left/dashboard/document/document.component";
import { TopURLComponent } from "./admin-page/menu-left/dashboard/document/top-url/top-url.component";
import { DirectoryBlockComponent } from "./admin-page/menu-left/dashboard/document/directory-block/directory-block.component";
import { FileBlockComponent } from "./admin-page/menu-left/dashboard/document/file-block/file-block.component";
import { ApiService } from "./shared/api.service";
import { DocumentService } from "./shared/document.service";
import { TreeViewComponent } from "./admin-page/menu-left/dashboard/document/directory-block/tree-view/tree-view.component";

import "../polyfills";
import { CdkTreeModule } from "@angular/cdk/tree";
import { DragDropModule } from "@angular/cdk/drag-drop";
import {
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatRadioModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatOptionModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatTabsModule
} from "@angular/material";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { OfficeComponent } from "./admin-page/menu-left/dashboard/office/office.component";
import { OfficeDialogComponent } from "./admin-page/menu-left/dashboard/office-dialog/office-dialog.component";
import { OfficeDeleteDialogComponent } from "./admin-page/menu-left/dashboard/office-delete-dialog/office-delete-dialog.component";
import { PublicHolidayComponent } from "./admin-page/menu-left/dashboard/public-holiday/public-holiday.component";
import { HolidayDialogComponent } from "./admin-page/menu-left/dashboard/holiday-dialog/holiday-dialog.component";
import { DateRangePipe } from "./pipes/date-range.pipe";
import { HolidayDeleteDialogComponent } from "./admin-page/menu-left/dashboard/holiday-delete-dialog/holiday-delete-dialog.component";
import { PositionComponent } from "./admin-page/menu-left/dashboard/position/position.component";
import { CreatePositionComponent } from "./admin-page/menu-left/dashboard/position/create-position-dialogs/create-position.component";
import { EditPositionComponent } from "./admin-page/menu-left/dashboard/position/edit-position-dialogs/edit-position.component";
import { DeletePositionComponent } from "./admin-page/menu-left/dashboard/position/delete-position-dialogs/delete-position.component";
import { AttendanceComponent } from "./admin-page/menu-left/dashboard/attendance/attendance.component";
import { LeaveRequestComponent } from "./admin-page/menu-left/dashboard/leave-request/leave-request.component";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { Ng2OdometerModule } from "ng2-odometer";
import { ApproveComponent } from "./admin-page/menu-left/dashboard/leave-request/approve-dialogs/approve.component";
import { ConfirmCancelComponent } from "./admin-page/menu-left/dashboard/attendance/confirm-cancel-dialogs/confirm-cancel.component";
import { CustomerComponent } from "./admin-page/menu-left/dashboard/customer/customer.component";
import { CreateCustomerComponent } from "./admin-page/menu-left/dashboard/customer/create-customer-dialog/create-customer/create-customer.component";
import { DeleteCustomerDialogComponent } from "./admin-page/menu-left/dashboard/customer/delete-customer-dialog/delete-customer-dialog.component";
import { EditCustomerDialogComponent } from "./admin-page/menu-left/dashboard/customer/edit-customer-dialog/edit-customer-dialog.component";
import { SupplierComponent } from "./admin-page/menu-left/dashboard/supplier/supplier.component";
import { CreateSupplierDialogComponent } from "./admin-page/menu-left/dashboard/supplier/create-supplier-dialog/create-supplier-dialog.component";
import { DeleteSupplierDialogComponent } from "./admin-page/menu-left/dashboard/supplier/delete-supplier-dialog/delete-supplier-dialog.component";
import { EditSupplierDialogComponent } from "./admin-page/menu-left/dashboard/supplier/edit-supplier-dialog/edit-supplier-dialog.component";
import { ProjectComponent } from "./admin-page/menu-left/dashboard/project/project.component";
import { DeleteProjectDialogComponent } from "./admin-page/menu-left/dashboard/project/delete-project-dialog/delete-project-dialog.component";
import { CreateProjectDialogComponent } from "./admin-page/menu-left/dashboard/project/create-project-dialog/create-project-dialog.component";
import { EditProjectDialogComponent } from "./admin-page/menu-left/dashboard/project/edit-project-dialog/edit-project-dialog.component";
import { QuotationComponent } from "./admin-page/menu-left/dashboard/quotation/quotation.component";
import { CreateQuotationDialogComponent } from "./admin-page/menu-left/dashboard/quotation/create-quotation-dialog/create-quotation-dialog.component";
import { DeleteQuotationDialogComponent } from "./admin-page/menu-left/dashboard/quotation/delete-quotation-dialog/delete-quotation-dialog.component";
import { EditQuotationDialogComponent } from "./admin-page/menu-left/dashboard/quotation/edit-quotation-dialog/edit-quotation-dialog.component";
import { CreateQuotationFileDialogComponent } from "./admin-page/menu-left/dashboard/quotation/create-quotation-file-dialog/create-quotation-file-dialog.component";
import { QuotationFileDisplayDialogComponent } from "./admin-page/menu-left/dashboard/quotation/quotation-file-display-dialog/quotation-file-display-dialog.component";
import { DeleteQuotationFileDialogComponent } from "./admin-page/menu-left/dashboard/quotation/delete-quotation-file-dialog/delete-quotation-file-dialog.component";
import { EditQuotationFileDialogComponent } from "./admin-page/menu-left/dashboard/quotation/edit-quotation-file-dialog/edit-quotation-file-dialog.component";
import { CompareQuotationComponent } from "./admin-page/menu-left/dashboard/compare-quotation/compare-quotation.component";
import { SafePipe } from "./pipes/safe.pipe";
import { CreateProjectFileDialogComponent } from "./admin-page/menu-left/dashboard/project/create-project-file-dialog/create-project-file-dialog.component";
import { ProjectFileDisplayDialogComponent } from "./admin-page/menu-left/dashboard/project/project-file-display-dialog/project-file-display-dialog.component";
import { EditProjectFileDialogComponent } from "./admin-page/menu-left/dashboard/project/edit-project-file-dialog/edit-project-file-dialog.component";
import { DeleteProjectFileDialogComponent } from "./admin-page/menu-left/dashboard/project/delete-project-file-dialog/delete-project-file-dialog.component";
import { FileNamePipe } from "./pipes/file-name.pipe";
import { from } from "rxjs";
import { SortablejsModule } from "angular-sortablejs";
import { CompanyComponent } from "./admin-page/menu-left/dashboard/company/company.component";
import { CreateCompanyDialogComponent } from "./admin-page/menu-left/dashboard/company/create-company-dialog/create-company-dialog.component";
import { EditCompanyDialogComponent } from "./admin-page/menu-left/dashboard/company/edit-company-dialog/edit-company-dialog.component";
import { DeleteCompanyDialogComponent } from "./admin-page/menu-left/dashboard/company/delete-company-dialog/delete-company-dialog.component";
import { AttendanceReportComponent } from "./admin-page/menu-left/dashboard/attendance-report/attendance-report.component";
import { FinancialReportComponent } from "./admin-page/menu-left/dashboard/financial-report/financial-report.component";
import { ExcelService } from "./shared/excel.service";
import { NgxUiLoaderDemoService } from "./shared/ngx-ui-loader.service";
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION,
  PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { ContactComponent } from "./admin-page/menu-left/dashboard/contact/contact.component";
import { AddMemberComponent } from "./admin-page/menu-left/dashboard/contact/add-member-dialogs/add-member.component";
import { DeleteGroupComponent } from "./admin-page/menu-left/dashboard/contact/delete-group-dialogs/delete-group.component";
import { DeleteContactComponent } from "./admin-page/menu-left/dashboard/contact/delete-contact-dialogs/delete-contact.component";
import { InfiniteScrollerDirective } from "./directives/infinite-scroller.directive";
import { ContactDetailComponent } from "./admin-page/menu-left/dashboard/contact/contact-detail-dialogs/contact-detail.component";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // bgsColor: '#OOACC1',
  // bgsOpacity: 0.5,
  // bgsPosition: POSITION.bottomCenter,
  // bgsSize: 60,
  // bgsType: SPINNER.rectangleBounce,
  // fgsColor: '#00ACC1',
  // fgsPosition: POSITION.centerCenter,
  // fgsSize: 60,
  // fgsType: SPINNER.chasingDots,
  // logoUrl: 'assets/angular.png',
  // pbColor: '#FF0000',
  // pbDirection: PB_DIRECTION.leftToRight,
  // pbThickness: 5,
  // text: 'Welcome to ngx-ui-loader',
  // textColor: '#FFFFFF',
  // textPosition: POSITION.centerCenter
};

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    AdminPageComponent,
    MenuLeftComponent,
    DashboardComponent,
    UserComponent,
    TableDetailComponent,
    IncomeComponent,
    OutcomeComponent,
    OutcomeDetailComponent,
    RedeemDetailComponent,
    DocumentComponent,
    TopURLComponent,
    DirectoryBlockComponent,
    FileBlockComponent,
    TreeViewComponent,
    CreateUserComponent,
    EditUserComponent,
    ResetPasswordComponent,
    CreateDirectoryComponent,
    DeleteDirectoryComponent,
    RenameDirectoryComponent,
    DeleteFileComponent,
    RenameFileComponent,
    DownloadFileComponent,
    PositionComponent,
    CreatePositionComponent,
    EditPositionComponent,
    DeletePositionComponent,
    OfficeComponent,
    OfficeDialogComponent,
    OfficeDeleteDialogComponent,
    PublicHolidayComponent,
    HolidayDialogComponent,
    DateRangePipe,
    FileSizePipe,
    HolidayDeleteDialogComponent,
    AttendanceComponent,
    LeaveRequestComponent,
    StatusComponent,
    ApproveComponent,
    ChangePasswordComponent,
    ConfirmCancelComponent,
    CustomerComponent,
    CreateCustomerComponent,
    DeleteCustomerDialogComponent,
    EditCustomerDialogComponent,
    SupplierComponent,
    CreateSupplierDialogComponent,
    DeleteSupplierDialogComponent,
    EditSupplierDialogComponent,
    ProjectComponent,
    DeleteProjectDialogComponent,
    CreateProjectDialogComponent,
    EditProjectDialogComponent,
    QuotationComponent,
    CreateQuotationDialogComponent,
    DeleteQuotationDialogComponent,
    EditQuotationDialogComponent,
    CreateQuotationFileDialogComponent,
    QuotationFileDisplayDialogComponent,
    DeleteQuotationFileDialogComponent,
    EditQuotationFileDialogComponent,
    CompareQuotationComponent,
    SafePipe,
    CreateProjectFileDialogComponent,
    ProjectFileDisplayDialogComponent,
    EditProjectFileDialogComponent,
    DeleteProjectFileDialogComponent,
    FileNamePipe,
    CompanyComponent,
    CreateCompanyDialogComponent,
    EditCompanyDialogComponent,
    DeleteCompanyDialogComponent,
    AttendanceReportComponent,
    FinancialReportComponent,
    ContactComponent,
    AddMemberComponent,
    DeleteGroupComponent,
    DeleteContactComponent,
    InfiniteScrollerDirective,
    ContactDetailComponent
  ],
  imports: [
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    BrowserModule,
    RouterModule.forRoot(adminRoutes),
    NgbModule,
    FormsModule,
    DataTablesModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    NgxSpinnerModule,
    CdkTreeModule,
    MatTreeModule,
    DragDropModule,
    MatNativeDateModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatCardModule,
    MatSidenavModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatFormFieldModule,
    MatRadioModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatRippleModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatListModule,
    MatTooltipModule,
    MatTabsModule,
    Ng2OdometerModule.forRoot(),
    SortablejsModule.forRoot({
      animation: 150
    }),

    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule
  ],
  providers: [
    UserLoginService, 
    AuthguardGuard, 
    ApiService, 
    DocumentService, 
    ExcelService,
    NgxUiLoaderDemoService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    UserComponent,
    CreateUserComponent,
    EditUserComponent,
    ResetPasswordComponent,
    CreateDirectoryComponent,
    DeleteDirectoryComponent,
    RenameDirectoryComponent,
    DeleteFileComponent,
    RenameFileComponent,
    DownloadFileComponent,
    OfficeDialogComponent,
    OfficeDeleteDialogComponent,
    HolidayDialogComponent,
    HolidayDeleteDialogComponent,
    OfficeDeleteDialogComponent,
    CreatePositionComponent,
    EditPositionComponent,
    DeletePositionComponent,
    StatusComponent,
    ApproveComponent,
    ChangePasswordComponent,
    ConfirmCancelComponent,
    CreateCustomerComponent,
    DeleteCustomerDialogComponent,
    EditCustomerDialogComponent,
    CreateSupplierDialogComponent,
    DeleteSupplierDialogComponent,
    EditSupplierDialogComponent,
    CreateProjectDialogComponent,
    EditProjectDialogComponent,
    DeleteProjectDialogComponent,
    CreateQuotationDialogComponent,
    EditQuotationDialogComponent,
    DeleteQuotationDialogComponent,
    CreateQuotationFileDialogComponent,
    QuotationFileDisplayDialogComponent,
    DeleteQuotationFileDialogComponent,
    EditQuotationFileDialogComponent,
    CreateProjectFileDialogComponent,
    ProjectFileDisplayDialogComponent,
    EditProjectFileDialogComponent,
    DeleteProjectFileDialogComponent,
    CreateCompanyDialogComponent,
    EditCompanyDialogComponent,
    DeleteCompanyDialogComponent,
    AddMemberComponent,
    DeleteGroupComponent,
    DeleteContactComponent,
    ContactDetailComponent
  ]
})
export class AppModule {}
