import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from '../../../../shared/user.service';
import { ApiService } from '../../../../shared/api.service';
import { MatDialog } from '@angular/material';
import { CreatePositionComponent } from './create-position-dialogs/create-position.component';
import { EditPositionComponent } from './edit-position-dialogs/edit-position.component';
import { DeletePositionComponent } from './delete-position-dialogs/delete-position.component';
import { PositionPagination } from 'src/app/models/position.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.css']
})
export class PositionComponent implements OnInit {

  positions: Array<object> = [];
  checked = false;
  disabled = false;
  positionPagination = new PositionPagination();
  page_increasement = 0;
  lastPage_check = false;
  isLoading = true;

  constructor(private userService: UserService, private apiService: ApiService, public dialog: MatDialog, private route: Router) {
  }

  ngOnInit() {
    this.getAllPositions();
    this.userService.getAllPositions.subscribe(positions => this.positions = positions);
  }

  getAllPositions() {
    this.isLoading = true;
    this.apiService.getAllPositions().subscribe((data: any) => {
      if (data.status == 1 && data.positions.length > 0) {
        this.positions = [];
        this.positions = data.positions;
      }
      
      setTimeout(() => {
        this.isLoading = false;
      }, 500);

    },err => {
    setTimeout(() => {
        this.isLoading = false;
      }, 500);
    });
  }

  openCreatePositionDialog(): void {
    const dialogRef = this.dialog.open(CreatePositionComponent, {
      width: '400px',
      height: 'auto',
      data: {
        
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openEditPositionDialog(position: any): void {
    this.userService.bindSelectedPosition(position);
    const dialogRef = this.dialog.open(EditPositionComponent, {
      width: '400px',
      height: 'auto',
      data: {
        position: position
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        
    });
  }

  openDeletePositionDialog(positionID: string): void {
    const dialogRef = this.dialog.open(DeletePositionComponent, {
      width: '400px',
      height: 'auto',
      data: {
        position_id: positionID
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  //count selector
  selectCountHandler(event: any) {
    this.positionPagination.count = event.target.value;

    this.loadList();
  }

  currentPage = 1;
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.positionPagination.page = this.currentPage;
    this.loadList();
  }

  nextClick() {
    this.currentPage = this.currentPage + 1;
    this.positionPagination.page = this.currentPage;
    this.loadList();
  }

  loadList() {
    this.apiService.getListPositions(this.positionPagination).subscribe(Response => {
      this.page_increasement = (this.currentPage - 1) * this.positionPagination.count;
      if (Response['status'] == 401) {
        this.route.navigateByUrl('/');
      }
      else {
        this.positions = Response['positions'];
      }
    });
    this.positionPagination.export = false;
  }
}

export interface PositionInfo {
  name: string;
  name_en: string;
  description: string;
}


