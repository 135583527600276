import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { DocumentService } from 'src/app/shared/document.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'rename-file',
    templateUrl: 'rename-file.component.html',
    styleUrls: ['rename-file.component.css']
  })
  export class RenameFileComponent {

    child_files: any = [];
    parentID: string = '';
    fileName: string = '';

    // ===== Form Validation ======================================================
    nameFormControl = new FormControl('', [Validators.required]);
  
    fileFormGroup: FormGroup = new FormGroup({
      name: this.nameFormControl
    });
  
    getRequiredErrorMessage(field) {
      return this.fileFormGroup.get(field).hasError('required') ? 'You must enter a value' : '';
    }
    // ===========================================================================
  
    constructor(public dialogRef: MatDialogRef<RenameFileComponent>, 
                @Inject(MAT_DIALOG_DATA) public object: any, 
                private apiService: ApiService, 
                private documentService: DocumentService,
                private snackBar: MatSnackBar) { 
                  dialogRef.disableClose = true;
                }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
   
    ngOnInit() {
      this.fileName = this.object.file.name;
      this.documentService.getParentID.subscribe(parentID => this.parentID = parentID);
      this.documentService.getFiles.subscribe(child_files => this.child_files = child_files);
    }
  
    handleRenameFile(file: any) {
      if(this.fileFormGroup.valid) {
        this.apiService.getFiles(this.parentID).subscribe((data: any) => {
          for (let i = 0; i < data.files.length; i++) {
            if (data.files[i]._id == file._id) {
              let newFile = {
                name: this.fileName,
                directory: this.parentID,
                order_number: data.files[i].order_number
              };
              this.apiService.editFile(newFile, file._id).subscribe((response: any) => {
                if(response.status == 1) {
                  this.apiService.getSubItems(this.parentID).subscribe((data: any) => {
                    this.documentService.bindFiles(data.files);
                  });
                  this.snackBar.open('Renamed successfully', null, {duration: 2000, horizontalPosition: 'right'});
                } else {
                  this.snackBar.open(response.message, null, {duration: 2000, horizontalPosition: 'right'});
                }
                this.dialogRef.close();
              });
            }
          }
        });
      }
    }
  }