import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../../shared/api.service';
import {MatDialog} from '@angular/material';
import {range, Subscription} from 'rxjs';
import {PublicHolidayModel} from '../../../../models/public-holiday.model';
import {map, toArray} from 'rxjs/operators';
import {animate, query, stagger, state, style, transition, trigger} from '@angular/animations';
import {HolidayDialogComponent} from '../holiday-dialog/holiday-dialog.component';
import {HolidayDeleteDialogComponent} from '../holiday-delete-dialog/holiday-delete-dialog.component';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { UserLoginService } from 'src/app/shared/userlogin.service';


@Component({
  selector: 'app-public-holiday',
  templateUrl: './public-holiday.component.html',
  styleUrls: ['./public-holiday.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({opacity: 0, transform: 'translateY(20px)'})),
      transition('void => *', animate('.5s ease'))
    ]),

    trigger('listStagger', [
      transition('* <=> *', [
        query(
          ':enter',
          [
            style({opacity: 0, transform: 'translateX(-40px)'}),
            stagger(
              '60ms',
              animate(
                '400ms ease',
                style({opacity: 1, transform: 'translateX(0px)'})
              )
            )
          ],
          {optional: true}
        ),
        query(':leave', animate('50ms', style({opacity: 0})), {
          optional: true
        })
      ])
    ])
  ]
})
export class PublicHolidayComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  isLoading = false;
  publicHolidays: PublicHolidayModel[] = [];
  yearControl = new FormControl();
  years = range(new Date().getFullYear() - 5, 20).pipe(toArray());
  currentYear: number;
  currentUserRoleName: string;
  roleNameAdmin: string = "Admin";
  roleNameAccountant: string = "Accountant";

  constructor(private api: ApiService,
              private dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute,
              private userLoginService: UserLoginService) {
  }

  ngOnInit(): void {

    this.currentUserRoleName = this.userLoginService.getCurrentUserRoleName();

    this.route.queryParams.subscribe(params => {

      const x = +params.year;
      if (isNaN(x)) {
        this.currentYear = new Date().getFullYear();
      } else {
        this.currentYear = x;
      }
      this.getHolidays(this.currentYear);
    });

    this.yearControl
      .valueChanges
      .subscribe(value => {
        this.router.navigate([], {queryParams: {
          year: value
        }, replaceUrl: true});
      });

    if (!this.currentYear) {
      this.yearControl.patchValue(new Date().getFullYear());
    } else {
      this.yearControl.patchValue(this.currentYear);
    }

  }


  createHoliday() {
    this.dialog.open(HolidayDialogComponent, {
      width: '450px',
    }).afterClosed().subscribe(response => {
      if (response) {
        this.getHolidays();
      }
    });
  }

  updateHoliday(holiday: PublicHolidayModel) {
    this.dialog.open(HolidayDialogComponent, {
      width: '440px',
      data: holiday
    }).afterClosed().subscribe(response => {
      if (response) {
        this.getHolidays();
      }
    });
  }

  deleteHoliday(holiday: PublicHolidayModel) {
    this.dialog.open(HolidayDeleteDialogComponent, {
      width: '360px',
      data: holiday
    }).afterClosed().subscribe(response => {
      if (response) {
        this.getHolidays();
      }
    });
  }

  getHolidays(year: number = this.currentYear) {
    this.isLoading = true;
    this.api.getPublicHolidays(year)
      .pipe(map(x => x.public_holidays))
      .subscribe(holidays => {
        this.publicHolidays = holidays.sort(this.sortHoliday);
        
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
        
      });
  }


  private sortHoliday(a: PublicHolidayModel, b: PublicHolidayModel): number {
    if (a.start_date < b.start_date) {
      return -1;
    }

    if (a.start_date > b.start_date) {
      return 1;
    }

    return 0;
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
