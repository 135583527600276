import {Component, OnDestroy, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Location} from '@angular/common';
import { ApiService } from 'src/app/shared/api.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { StatusComponent } from './status-dialogs/status.component';
import { ApproveComponent } from './approve-dialogs/approve.component';
import { UserLoginService } from 'src/app/shared/userlogin.service';



@Component({
  selector: 'app-leave-request',
  templateUrl: './leave-request.component.html',
  styleUrls: ['./leave-request.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({opacity: 0, transform: 'translateY(20px)'})),
      transition('void => *', animate('.5s ease'))
    ]),
  ]
})
export class LeaveRequestComponent implements OnInit, OnDestroy {

  leave_requests: Array<object> = [];
  // users: any = [];
  leave_types: any = [];
  currentUserID = this.userLoginService.getCurrentUserID();
  countLeaveRequest: number = 0;

  constructor(private location: Location, 
              private apiService: ApiService, 
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private userLoginService: UserLoginService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.apiService.getLeaveNotification(this.currentUserID).subscribe((response: any) => {
      this.leave_requests = response.leave_requests;
      this.countLeaveRequest = this.leave_requests.length;
    });
    this.apiService.getLeaveTypes().subscribe((response: any) => {
      this.leave_types = response.leave_types;            
    });
    // this.apiService.getAllUsers().subscribe((response: any) => {
    //   this.users = response.users;
    // });
  }

  openStatusDialog(leaveID: string): void {
    const dialogRef = this.dialog.open(StatusComponent, {
      width: '400px',
      height: 'auto',
      data: {
        leave_id: leaveID
      }
    }); 

    dialogRef.afterClosed().subscribe(result => {
      this.apiService.getLeaveNotification(this.currentUserID).subscribe((res: any) => {
        this.leave_requests = res.leave_requests;
      });
    });
  }

  openApproveAllDialog(): void {
    const dialogRef = this.dialog.open(ApproveComponent, {
      width: '400px',
      height: 'auto',
      data: {
        leave_requests: this.leave_requests
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.leave_requests = result[0];
        this.countLeaveRequest = result[1];
      }
    });
  }

  getLeaveType(leaveTypeID: string) {
    for(let i = 0; i < this.leave_types.length; i++) {
      if(this.leave_types[i]._id == leaveTypeID) {
        return (this.leave_types[i].name_en + " / " + this.leave_types[i].name);
      }
    }
  }

  // getUser(userID: string) {
  //   for(let i = 0; i < this.users.length; i++) {
  //     if(this.users[i]._id == userID) {
  //       return this.users[i].name;
  //     }  
  //   }
  // }

  normalizeShift(start: string, end: string): string {
    if (start === end) {
      return this.firstCap(start);
    }

    if (start && end) {
      return `${this.firstCap(start)} - ${this.firstCap(end)}`;
    }

    if (start) {
      return this.firstCap(start);
    }

    return null;
  }

  firstCap(value: string): string {
    if (value === 'full') {
      return value.charAt(0).toUpperCase() + value.slice(1) + ' day';
    }

    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  approveLeaveRequest(leave_id: string) {
    this.apiService.approveLeaveRequest(leave_id).subscribe((response) => {
      console.log(response);
      this.apiService.getLeaveNotification(this.currentUserID).subscribe((response: any) => {
        this.leave_requests = response.leave_requests;
        this.countLeaveRequest = this.leave_requests.length;
      });
      this.snackBar.open('Approved successfully', null, {duration: 2000, horizontalPosition: 'right'});
    });
  }
}
