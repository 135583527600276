import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { ProjectService } from "../project.service";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { ProjectFileInfo } from "../project.component";

@Component({
  selector: "app-create-project-file-dialog",
  templateUrl: "./create-project-file-dialog.component.html",
  styleUrls: ["./create-project-file-dialog.component.scss"]
})
export class CreateProjectFileDialogComponent implements OnInit {
  fileName: string = "";
  files: any;
  tmpFiles: any;
  constructor(
    public dialogRef: MatDialogRef<CreateProjectFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public projectFile: ProjectFileInfo,
    private apiService: ApiService,
    private projectService: ProjectService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  titleFormControl: FormControl = new FormControl("", [Validators.required]);
  descriptionFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  fileNameFormControl: FormControl = new FormControl("", [Validators.required]);

  fileFormControl: FormControl = new FormControl("", [Validators.required]);

  projectFileFormGroup: FormGroup = new FormGroup({
    title: this.titleFormControl,
    description: this.descriptionFormControl,
    file_name: this.fileNameFormControl,
    file: this.fileFormControl
  });

  getRequiredErrorMessage(field) {
    return this.projectFileFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  fileChanged(fileEvent: Event) {
    const target = fileEvent.target as HTMLInputElement;
    this.fileName = target.files[0].name;
    this.files = target.files;
    this.tmpFiles = Array.from(this.files);
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.projectFileFormGroup.invalid) {
        this.dialogRef.close();
      }
    }
  }

  onDelete(file: any) {
    var index = this.tmpFiles.indexOf(file);
    if (index > -1){
      this.tmpFiles.splice(index, 1);
    }
  }

  ngOnInit() {}

  handleCreateProjectFile() {
    const formData = new FormData();
    formData.append("project_id", this.projectFile.project_id);
    formData.append("title", this.projectFile.title);
    formData.append("description", this.projectFile.description);
    for (let i = 0; i < this.tmpFiles.length; i++) {
      formData.append("file", this.tmpFiles[i]);
    }
    this.apiService.createProjectFile(formData).subscribe(
      response => {
        this.snackBar.open("Created successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Create failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }
}
