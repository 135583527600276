import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { SupplierService } from "../supplier.service";

@Component({
  selector: "app-edit-supplier-dialog",
  templateUrl: "./edit-supplier-dialog.component.html",
  styleUrls: ["./edit-supplier-dialog.component.scss"]
})
export class EditSupplierDialogComponent implements OnInit {
  selectedSupplier: any;
  selectedAttendant: any;
  attendants: any;
  newAttendant = { _id: "0", name: "New" };

  constructor(
    public dialogRef: MatDialogRef<EditSupplierDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private supplierService: SupplierService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  companyNameFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  attendantFormControl: FormControl = new FormControl();
  phoneNumberFormControl: FormControl = new FormControl("");
  emailFormControl: FormControl = new FormControl("");
  companyAddressFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  websiteFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);

  attendantSelectFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);

  supplierFormGroup: FormGroup = new FormGroup({
    company_name: this.companyNameFormControl,
    attendant: this.attendantFormControl,
    phone_number: this.phoneNumberFormControl,
    email: this.emailFormControl,
    address: this.companyAddressFormControl,
    website: this.websiteFormControl,
    attendant_select: this.attendantSelectFormControl
  });

  getRequiredErrorMessage(field) {
    return this.supplierFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  getEmailErrorMessage() {
    return this.emailFormControl.hasError("required")
      ? "You must enter a value"
      : this.emailFormControl.hasError("email")
      ? "Not a valid email"
      : "";
  }

  ngOnInit() {
    this.getAllAttendants();
    this.checkIfNeededToAddValidation();
    this.supplierService.getSelectedSupplier.subscribe(data => {
      this.selectedSupplier = data;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.apiService.getAllSuppliers().subscribe((data: any) => {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (this.selectedSupplier._id == data[i]._id) {
            this.selectedSupplier.company_name = data[i].company_name;
            this.selectedSupplier.attendant_id = data[i].attendant._id;
            this.selectedSupplier.phone_number = data[i].attendant.phone_number;
            this.selectedSupplier.email = data[i].attendant.email;
            this.selectedSupplier.address = data[i].address;
            this.selectedSupplier.website = data[i].website;
          }
        }
      }
    });
  }

  getAllAttendants() {
    this.attendants = [];
    this.apiService.getAllAttendants().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.attendants = data;
          this.attendants.push(this.newAttendant);
        }
      },
      error => {
        this.attendants = [];
      }
    );
  }

  handleEditSupplier() {
    let newSupplier = {
      company_name: this.object.supplier.company_name,
      attendant_id: this.selectedAttendant,
      phone_number: this.object.supplier.phone_number,
      name: this.object.supplier.name,
      email: this.object.supplier.email,
      address: this.object.supplier.address,
      website: this.object.supplier.website
    };

    if (this.selectedAttendant !== "0"){
      newSupplier.name = undefined;
      newSupplier.phone_number = undefined;
      newSupplier.email = undefined;
    }
    else {
      newSupplier.attendant_id = undefined;
    }
    
    this.apiService
      .editSupplier(this.object.supplier._id, newSupplier)
      .subscribe(
        response => {
          this.snackBar.open("Updated successfully", null, {
            duration: 2000,
            horizontalPosition: "right"
          });
        },
        error => {
          this.snackBar.open("Update failed!", null, {
            duration: 2000,
            panelClass: ["red-snackbar"],
            horizontalPosition: "right"
          });
        }
      );
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.supplierFormGroup.invalid) {
        this.dialogRef.close();
        this.handleEditSupplier();
      }
    }
  }

  onAttendantChange(event) {
    this.selectedAttendant = event.value;
    if (event.value === "0") {
      this.attendantFormControl.setValidators([Validators.required]);
      this.phoneNumberFormControl.setValidators([Validators.required]);
      this.emailFormControl.setValidators([
        Validators.required,
        Validators.email
      ]);
    } else {
      this.attendantFormControl.setValidators(null);
      this.phoneNumberFormControl.setValidators(null);
      this.emailFormControl.setValidators(null);
    }
    this.supplierFormGroup.updateValueAndValidity();
    console.log("update validity");
  }

  checkIfNeededToAddValidation() {
    if (this.attendants.length === 1) {
      this.attendantFormControl.setValidators([Validators.required]);
      this.phoneNumberFormControl.setValidators([Validators.required]);
      this.emailFormControl.setValidators([
        Validators.required,
        Validators.email
      ]);
    } else {
      this.attendantFormControl.setValidators(null);
      this.phoneNumberFormControl.setValidators(null);
      this.emailFormControl.setValidators(null);
    }
    this.supplierFormGroup.updateValueAndValidity();
  }
}
