import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Sale } from '../models/salse.model';
import { Promotion } from '../models/promotion.model';
import { Redeem } from '../models/redeem.model';
import { environment } from '../../environments/environment';
import { AuthguardGuard } from '../auths/authguard.guard';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class UserLoginService {
  private bashURL = environment.url + "/";
  returnURL: string = '';
  expiresAt: number;
  userProfile: any;
  accessToken: string;
  authenticated: boolean;

  constructor(private http: HttpClient, private route: Router) { }

  login(username: string, password: string){
    return this.http.post(this.bashURL + "login", {
      "name": username,
      "pwd": password
    })
    .pipe(map((res) => {
      localStorage.setItem('token', this.getToken());
      const time_to_login = Date.now() + 1000 * 60 * 60 * 2; // one minute
      localStorage.setItem('timer', JSON.stringify(time_to_login));
      return res;
    }));
  }

  getListType(listObj: Sale){
    return this.http.get(this.bashURL + `payment/sale_by_tour?from=${listObj.from}&to=${listObj.to}&type=${listObj.type}&token=${this.getToken()}&count=${listObj.count}&page=${listObj.page}&export=${listObj.export}`);
  }

  getPointList(listObj: Sale) {
    return this.http.get(this.bashURL + `payment/points?from=${listObj.from}&to=${listObj.to}&token=${this.getToken()}&export=${listObj.export}`);
  }

  getDetailIncomes(listObj: Sale, id: string = ''){
    if(id === '') {
      return this.http.get(this.bashURL + `payment/sale_by_date?from=${listObj.from}&to=${listObj.to}&type=${listObj.type}&token=${this.getToken()}&count=${listObj.count}&page=${listObj.page}&export=${listObj.export}`);
    } else {
      return this.http.get(this.bashURL + `payment/sale_by_date?from=${listObj.from}&to=${listObj.to}&type=${listObj.type}&token=${this.getToken()}&count=${listObj.count}&page=${listObj.page}&export=${listObj.export}&id=${id}`);
    }
  }

  getUrlLink(listObj: Sale){
    return this.bashURL + `payment/sale_by_tour?from=${listObj.from}&to=${listObj.to}&type=${listObj.type}&token=${this.getToken()}&count=${listObj.count}&page=1&export=${listObj.export}`;
  }

  getUrlLinkDetail(listObj: Sale){
    return this.bashURL + `payment/sale_by_date?from=${listObj.from}&to=${listObj.to}&type=${listObj.type}&token=${this.getToken()}&count=${listObj.count}&page=1&export=${listObj.export}`;
  }

  getUrlLinkPoint(listObj: Sale){
    return this.bashURL + `payment/points?from=${listObj.from}&to=${listObj.to}&token=${this.getToken()}&export=${listObj.export}`;
  }

  getUrlLinkTopup(listObj: Sale){
    return this.bashURL + `payment/daily_topup?from=${listObj.from}&to=${listObj.to}&token=${this.getToken()}&export=${listObj.export}`;
  }

  getUrlLinkDeposit(listObj: Sale){
    return this.bashURL + `payment/daily_deposit?from=${listObj.from}&to=${listObj.to}&token=${this.getToken()}&export=${listObj.export}`;
  }

  getListPromotion(start: string, end: string){
    return this.http.get(this.bashURL + `payment/promotion?from=${start}&to=${end}&type=TA&token=${this.getToken()}`);
  }

  getListTopup(start: string, end: string){
    return this.http.get(this.bashURL + `payment/daily_topup?from=${start}&to=${end}&type=TA&token=${this.getToken()}`);
  }

  getListDeposit(start: string, end: string){
    return this.http.get(this.bashURL + `payment/daily_deposit?from=${start}&to=${end}&type=TA&token=${this.getToken()}`);
  }

  getListPromotionDetail(promo: Promotion){
    return this.http.get(this.bashURL + `payment/promotion/detail?from=${promo.from}&to=${promo.to}&type=${promo.type}&token=${this.getToken()}&count=${promo.count}&page=${promo.page}&export=${promo.export}`);
  }

  getPromotionLink(promo: Promotion){
    return this.bashURL + `payment/promotion/detail?from=${promo.from}&to=${promo.to}&type=${promo.type}&token=${this.getToken()}&count=${promo.count}&page=1&export=${promo.export}`;
  }

  getListRedeem(start: string, end: string){
    return this.http.get(this.bashURL + `payment/redeem?from=${start}&to=${end}&token=${this.getToken()}`);
  }

  getListRedeemDetail(redeem: Redeem){                                  
    return this.http.get(this.bashURL + `payment/redeem/detail?from=${redeem.from}&to=${redeem.to}&type=${redeem.type}&token=${this.getToken()}&count=${redeem.count}&page=${redeem.page}&export=${redeem.export}`);
  }
  getRedeemLink(redeem: Redeem){
    return this.bashURL + `payment/redeem/detail?from=${redeem.from}&to=${redeem.to}&type=${redeem.type}&token=${this.getToken()}&count=${redeem.count}&page=1&export=${redeem.export}`;
  }

  getDeposit(start: string, end: string, page : number){
    return this.http.get(this.bashURL + `payment/deposit/history?page=${page}&count=20&from=${start}&to=${end}&token=${this.getToken()}`);
  }

  getTopup(start: string, end: string, page : number){
    return this.http.get(this.bashURL + `payment/top_up/history?page=${page}&count=20&from=${start}&to=${end}&token=${this.getToken()}`);
  }

  getURLDeposit(start: string, end: string){
    return this.bashURL + `payment/deposit/history?export=true&from=${start}&to=${end}&token=${this.getToken()}`;
  }

  getURLTopup(start: string, end: string){
    return this.bashURL + `payment/top_up/history?export=true&from=${start}&to=${end}&token=${this.getToken()}`;
  }

  setStartDate(start_date: string) {
    localStorage.setItem('start_date', start_date);
  }

  setEndDate(end_date: string) {
    localStorage.setItem('end_date', end_date);
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  setCurrentUserID(userID: string) {
    localStorage.setItem('currentUserID', userID);
  }

  setCurrentUserRoleID(userRoleID: string) {
    localStorage.setItem('currentUserRoleID', userRoleID);
  }

  setCurrentUserRoleName(userRoleName: string) {
    localStorage.setItem('currentUserRoleName', userRoleName);
  }

  setUsername(username: string){
    localStorage.setItem('name', username);
  }

  setReturnURL(url: string) {
    localStorage.setItem('returnURL', url);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getCurrentUserID(){
    return localStorage.getItem('currentUserID');
  }

  getCurrentUserRoleID(){
    return localStorage.getItem('currentUserRoleID');
  }

  getCurrentUserRoleName(){
    return localStorage.getItem('currentUserRoleName');
  }

  getUsername(){
    return localStorage.getItem('name');
  }

  deleteToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('name');
  }

  getStartDate() {
    return localStorage.getItem("start_date");
  }

  getEndDate() {
    return localStorage.getItem("end_date");
  }

  getReturnURL() {
    return localStorage.getItem("returnURL");
  }
}
