import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../shared/api.service';
import { DocumentService } from '../../../../../shared/document.service';
import { CreateDirectoryComponent } from './create-dir-dialogs/create-dir.component';
import { MatDialog } from '@angular/material';
import { HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-top-url',
  templateUrl: './top-url.component.html',
  styleUrls: ['./top-url.component.css']
})

export class TopURLComponent implements OnInit {

  constructor(private apiService: ApiService,
    private documentService: DocumentService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  clicked_dir: any;
  new_dir: any;
  message: any;
  full_path: any;
  file_name: string = '';
  arrFileName: Array<string> = [];
  selectedIDList: Array<string> = [];
  isLoading = false;

  ngOnInit() {
    this.documentService.clickedDir.subscribe(clicked_dir => this.clicked_dir = clicked_dir);
    this.documentService.pathDir.subscribe(full_path => this.full_path = full_path);
  }

  uploadFile() {
    $("#upload_file").trigger("click");
  }

  onFileUpload(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      let file_name = files[i].name;
      this.arrFileName = file_name.split("\\");

      let newFile = {
        name: `${this.arrFileName[this.arrFileName.length - 1]}`,
        directory: `${this.clicked_dir._id}`
      };

      this.apiService.createSoftFile(newFile).subscribe((response: any) => {
        if (response.status == 1) {
          this.apiService.uploadNewFile(response.file._id, files[i]).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
              console.log("Upload Process: " + Math.round(event.loaded / event.total) * 100 + '%');
            }
            else if (event.type === HttpEventType.Response) {
              console.log(event);
            }
          });

          this.apiService.getSubItems(this.clicked_dir._id).subscribe((data: any) => {
            if (data.files != []) this.documentService.bindFiles(data.files);
          });
        }
        else {
          this.snackBar.open(response.message, null, { duration: 2000, horizontalPosition: 'right' });
        }
      });
    }
  }

  refresh() {
    this.isLoading = true;
    this.apiService.getSubItems(this.clicked_dir._id).subscribe((data: any) => {
      if (data.files != []) this.documentService.bindFiles(data.files);
      if (data.directories != []) this.documentService.bindSubDir(data.directories);

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

    });
  }

  openCreateDirDialog(): void {
    const dialogRef = this.dialog.open(CreateDirectoryComponent, {
      width: '400px',
      height: 'auto',
      data: {

      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}

export interface DirInfo {
  name: string;
}