import { Component, OnInit } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UserLoginService } from '../../../../../shared/userlogin.service';
import { Redeem } from '../../../../../models/redeem.model';
import * as dayjs from 'dayjs'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ExcelService } from 'src/app/shared/excel.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-redeem-detail',
  templateUrl: './redeem-detail.component.html',
  styleUrls: ['./redeem-detail.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ],
})
export class RedeemDetailComponent implements OnInit {
  isLoading = true;
  today = new Date();
  minDate = new Date(2017, 1, 1);
  maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  bsValue = new Date();
  redeem = new Redeem();
  listRedeem: any[];
  redeemType;
  listRedeemType = ["TA", "TG"];
  page_increasement = 0;

  constructor(private activateRoute: ActivatedRoute, 
              private redeemService: UserLoginService, 
              private location: Location,
              private excelService: ExcelService,
              private userLoginService: UserLoginService,
              private datePipe: DatePipe) { 

    activateRoute.params.subscribe(params => {
      this.redeemType = params.type;
      this.redeem.type = this.redeemType;
    });
  }

  ngOnInit() {
    this.redeem.page = 1;
    this.redeem.count = 10;
    this.redeem.from = this.redeemService.getStartDate();
    this.redeem.to = this.redeemService.getEndDate();
    
    if(this.redeem.from == null) {
      this.redeem.from = this.getDefaultStartDate();
    }

    if(this.redeem.to == null) {
      this.redeem.to = this.getDefaultEndDate();
    }

    this.loadListRedeem();
  }

  getDefaultStartDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-01';
  }

  getDefaultEndDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let dd: any = this.daysInMonth(mm, yyyy);

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-' + dd;
  }

  daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
  }

  onValueStartChange(date: any){
    this.redeem.from = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.redeem.page = 1;
    this.redeem.count = 10;
    this.redeemService.setStartDate(this.datePipe.transform(date.value, 'yyyy-MM-dd'));
    this.loadListRedeem();
  }

  onValueEndChange(date: any){
    this.redeem.to = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.redeem.page = 1;
    this.redeem.count = 10;
    this.redeemService.setStartDate(this.datePipe.transform(date.value, 'yyyy-MM-dd'));
    this.loadListRedeem();
  }
  selectCountHandler(event){
    this.redeem.count = event.target.value;
    this.loadListRedeem();
  }
  selectTypeHandler(event){
    this.redeem.type = event.target.value;
    this.loadListRedeem();
  }

  onDownloadClick() {
    this.redeem.export = true;
    const url = this.userLoginService.getRedeemLink(this.redeem);
    window.open(url, "_blank");

    this.redeem.export = false;
  }

  currentPage = 1;
  //previous button click
  previousClick(){
    this.currentPage = this.currentPage - 1;
    this.redeem.page = this.currentPage;
    this.loadListRedeem();
    console.log(this.currentPage);
  }

  //next button click
  nextClick(){
    this.currentPage = this.currentPage + 1;
    this.redeem.page = this.currentPage;
    this.loadListRedeem();
    console.log(this.currentPage);
  }

  loadListRedeem(){
    this.isLoading = true;
    this.redeemService.getListRedeemDetail(this.redeem).subscribe(Response => {

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

      this.listRedeem = Response['redeems'];
      this.page_increasement = (this.currentPage - 1) * this.redeem.count;
    })
    this.redeem.export = false;
  }

  getToday() {
    let today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-' + dd;
  }
}
