import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";

import { ApiService } from "src/app/shared/api.service";
import { Router } from "@angular/router";
import { SupplierService } from "./supplier.service";
import { SupplierPagination } from "src/app/models/supplier.model";
import { CreateSupplierDialogComponent } from "./create-supplier-dialog/create-supplier-dialog.component";
import { DeleteSupplierDialogComponent } from "./delete-supplier-dialog/delete-supplier-dialog.component";
import { EditSupplierDialogComponent } from "./edit-supplier-dialog/edit-supplier-dialog.component";

@Component({
  selector: "app-supplier",
  templateUrl: "./supplier.component.html",
  styleUrls: ["./supplier.component.scss"]
})
export class SupplierComponent implements OnInit {
  suppliers: any;
  isLoading = true;
  page_increasement = 0;
  supplierPagination = new SupplierPagination();
  searchKeyword: string = "";

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private supplierService: SupplierService,
    private route: Router
  ) {}

  ngOnInit() {
    this.getAllSuppliers();
    this.supplierService.getAllSuppliers.subscribe(
      suppliers => (this.suppliers = suppliers)
    );
    
  }

  getAllSuppliers() {
    this.isLoading = true;
    this.apiService.getAllSuppliers().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.suppliers = [];
          this.suppliers = data;
          this.isLoading = false;
        }
      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }

  openCreateSupplierDialog(): void {
    const dialogRef = this.dialog.open(CreateSupplierDialogComponent, {
      width: "800px",
      height: "auto",
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  openDeleteSupplierDialog(supplierID: string): void {
    const dialogRef = this.dialog.open(DeleteSupplierDialogComponent, {
      width: "400px",
      height: "auto",
      data: {
        supplier_id: supplierID
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  openEditSupplierDialog(supplier: any): void {
    this.supplierService.bindSelectedSupplier(supplier);
    const dialogRef = this.dialog.open(EditSupplierDialogComponent, {
      width: "800px",
      height: "auto",
      data: {
        supplier: supplier
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadList();
    });
  }

  handleSearch(){
    this.loadList();
  }

  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this.handleSearch();
    }
  }

  onSearchChange(event){
    if (event.target.value === ''){
      this.handleSearch();
    }
  }

  // count selector
  selectCountHandler(event: any) {
    this.supplierPagination.count = event.target.value;
    this.loadList();
  }

  currentPage = 1;
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.supplierPagination.page = this.currentPage;
    this.loadList();
  }

  nextClick() {
    this.currentPage = this.currentPage + 1;
    this.supplierPagination.page = this.currentPage;
    this.loadList();
  }

  loadList() {
    this.apiService
      .getListSuppliers(this.supplierPagination, this.searchKeyword)
      .subscribe(Response => {
        this.page_increasement =
          (this.currentPage - 1) * this.supplierPagination.count;
        if (Response["status"] == 401) {
          this.route.navigateByUrl("/");
        } else {
          this.suppliers = Response;
        }
      });
    this.supplierPagination.export = false;
  }

}

export interface SupplierInfo{
  company_name: string;
  attendant_id: string;
  name: string;
  phone_number: string;
  email: string;
  address: string;
  website: string;
}
