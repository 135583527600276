import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { CompanyInfo } from "../company.component";
import { ApiService } from "src/app/shared/api.service";
import { CompanyService } from "../company.service";
import { FormControl, Validators, FormGroup } from "@angular/forms";

@Component({
  selector: "app-create-company-dialog",
  templateUrl: "./create-company-dialog.component.html",
  styleUrls: ["./create-company-dialog.component.scss"]
})
export class CreateCompanyDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CreateCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public company: CompanyInfo,
    private apiService: ApiService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  nameFormControl: FormControl = new FormControl("", [Validators.required]);

  addressFormControl: FormControl = new FormControl("", [Validators.required]);

  websiteFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);

  vatinFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);

  companyFormGroup: FormGroup = new FormGroup({
    name: this.nameFormControl,
    address: this.addressFormControl,
    website: this.websiteFormControl,
    vatin: this.vatinFormControl
  });

  getRequiredErrorMessage(field) {
    return this.companyFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  ngOnInit() {}

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.companyFormGroup.invalid) {
        this.dialogRef.close();
        this.handleCreateCompany();
      }
    }
  }

  handleCreateCompany() {
    this.apiService.createCompany(this.company).subscribe(
      response => {
        this.apiService.getAllCompanies().subscribe((data: any) => {
          if (data.length > 1) {
            this.companyService.bindCompanies(data);
          }
        });
        this.snackBar.open("Created successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Create failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }
}
