import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatDatepickerInputEvent
} from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { QuotationFileService } from "../quotation-file.service";
import { FormControl, Validators, FormGroup } from "@angular/forms";

@Component({
  selector: "app-edit-quotation-file-dialog",
  templateUrl: "./edit-quotation-file-dialog.component.html",
  styleUrls: ["./edit-quotation-file-dialog.component.scss"]
})
export class EditQuotationFileDialogComponent implements OnInit {
  fileName: string = "";
  currentDate = new Date();
  maxDate: Date;
  minDate: Date;
  parentFiles: any;
  issued_date: Date = this.currentDate;
  expired_date: Date = this.currentDate;
  files: any = [];
  selectedQuotationFile: any;

  oldFileArray: any;
  deleteFileList: any;
  displayFiles: any = [];
  remainFiles: any;
  tmpFiles: any;

  constructor(
    public dialogRef: MatDialogRef<EditQuotationFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private quotationFileService: QuotationFileService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  revisionFormControl: FormControl = new FormControl("", [Validators.required]);
  totalPriceFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  parentFileFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);
  issuedDateFormControl: FormControl = new FormControl(this.currentDate, [
    Validators.required
  ]);
  expiredDateFormControl: FormControl = new FormControl(this.currentDate, [
    Validators.required
  ]);
  fileFormControl: FormControl = new FormControl(null, [
    Validators.nullValidator
  ]);

  fileNameFormControl: FormControl = new FormControl(this.fileName, [
    Validators.nullValidator
  ]);

  quotationFileFormGroup: FormGroup = new FormGroup({
    revision: this.revisionFormControl,
    total_price: this.totalPriceFormControl,
    parent_file: this.parentFileFormControl,
    issued_date: this.issuedDateFormControl,
    expired_date: this.expiredDateFormControl,
    file: this.fileFormControl,
    file_name: this.fileNameFormControl
  });

  getRequiredErrorMessage(field) {
    return this.quotationFileFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.quotationFileFormGroup.invalid) {
        this.dialogRef.close();
      }
    }
  }

  fileChanged(fileEvent: Event) {
    const target = fileEvent.target as HTMLInputElement;
    
    this.fileName = target.files[0].name;
    let changedFiles: any = Array.from(target.files);
    
    for (let i = 0; i < changedFiles.length; i++) {
      this.displayFiles.push(changedFiles[i]);
      this.files.push(changedFiles[i]);
    }
  }

  onDelete(file: any) {
    var index = this.displayFiles.indexOf(file);
    if (index > -1) {
      this.displayFiles.splice(index, 1);
    }
    var indexForOld = this.remainFiles.indexOf(file);
    if (indexForOld > -1) {
      this.deleteFileList.push(this.remainFiles[indexForOld]);
      this.remainFiles.splice(indexForOld, 1);
    }
    if (this.files !== undefined && this.files.length > 0) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].name === file.name) {
          this.files.splice(i, 1);
        }
      }
      if (this.files.length <= 0) {
        this.fileName = "";
      }
    }
  }

  getFileNameFromLink(fileArray: any) {
    let tmpArray = [];
    fileArray.forEach((file) => {
      tmpArray.push(file);
    });
    return tmpArray;
  }

  ngOnInit() {
    this.quotationFileService.getSelectedQuotationFile.subscribe(
      selectedQuotationFile =>
        (this.selectedQuotationFile = selectedQuotationFile)
    );
    this.getParentFiles();
    this.maxDate = new Date(this.object.quotation_file.expired_date);
    this.minDate = new Date(this.object.quotation_file.issued_date);

    this.oldFileArray = [];
    if (this.object.quotation_file.file.length != 0) {
      this.oldFileArray = this.getFileNameFromLink(this.object.quotation_file.file);
    }

    this.displayFiles = [];
    this.displayFiles = this.object.quotation_file.file;

    this.remainFiles = [];
    this.deleteFileList = [];
    for (let i = 0; i < this.oldFileArray.length; i++) {
      this.remainFiles.push(this.oldFileArray[i]);
    }
  }

  getParentFiles() {
    this.parentFiles = [];
    this.apiService
      .getAllQuotationFiles(this.object.quotation_file.quotation._id)
      .subscribe((quotationFiles: any) => {
        if (quotationFiles.length > 0) {
          for (var i = 0; i < quotationFiles.length; i++) {
            if (this.object.quotation_file._id === quotationFiles[i]._id) {
              quotationFiles.splice(i, 1);
            }
          }
          this.parentFiles = quotationFiles;
        }
      });
  }

  issuedDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.issued_date = event.value;
    this.minDate = new Date(event.value);
  }

  expiredDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.expired_date = event.value;
    this.maxDate = new Date(event.value);
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.apiService
      .getAllQuotationFiles(this.object.quotation_file.quotation._id)
      .subscribe((data: any) => {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (this.selectedQuotationFile._id == data[i]._id) {
              this.selectedQuotationFile.revision = data[i].revision;
              this.selectedQuotationFile.total_price = data[i].total_price;
              this.selectedQuotationFile.parent = data[i].parent;
              this.selectedQuotationFile.quotation = data[i].quotation;
              this.selectedQuotationFile.file = data[i].file;
              this.selectedQuotationFile.createdAt = data[i].createdAt;
              this.selectedQuotationFile.updatedAt = data[i].updatedAt;
            }
          }
        }
      });
  }

  handleEditQuotationFile() {
    let newQuotationFileFormData = new FormData();
    newQuotationFileFormData.append(
      "quotation_id",
      this.object.quotation_file.quotation._id
    );
    newQuotationFileFormData.append(
      "revision",
      this.object.quotation_file.revision
    );
    newQuotationFileFormData.append(
      "total_price",
      this.object.quotation_file.total_price.toString()
    );
    newQuotationFileFormData.append(
      "parent",
      this.object.quotation_file.parent
    );
    newQuotationFileFormData.append(
      "issued_date",
      this.object.quotation_file.issued_date.toString()
    );
    newQuotationFileFormData.append(
      "expired_date",
      this.object.quotation_file.expired_date.toString()
    );
    if (this.files !== undefined) {
      for (let i = 0; i < this.files.length; i++) {
        newQuotationFileFormData.append("file", this.files[i]);
      }
    }

    if (this.deleteFileList !== undefined) {
      let deletedFile: any;
      let emptyDeletedFile: any;
      for (let i = 0; i < this.deleteFileList.length; i++) {
        deletedFile = {
          _id: this.deleteFileList[i]._id,
          name: this.deleteFileList[i].name
        }
        newQuotationFileFormData.append("delete_list", JSON.stringify(deletedFile));
      }
      
      emptyDeletedFile = {_id: null};
      if(this.deleteFileList.length == 1) {
        newQuotationFileFormData.append("delete_list", JSON.stringify(emptyDeletedFile));
      } else if(this.deleteFileList.length == 0) {
        newQuotationFileFormData.append("delete_list", JSON.stringify(emptyDeletedFile));
        newQuotationFileFormData.append("delete_list", JSON.stringify(emptyDeletedFile));
      }
    }

    if (this.remainFiles !== undefined) {
      let remainingFile: any;
      let emptyRemainingFile: any;
      for (let i = 0; i < this.remainFiles.length; i++) {
        remainingFile = {
          _id: this.remainFiles[i]._id,
          name: this.remainFiles[i].name
        }
        newQuotationFileFormData.append("remain_list", JSON.stringify(remainingFile));
      }
      
      emptyRemainingFile = {_id: null};
      if(this.remainFiles.length == 1) {
        newQuotationFileFormData.append("remain_list", JSON.stringify(emptyRemainingFile));
      } else if(this.remainFiles.length == 0) {
        newQuotationFileFormData.append("remain_list", JSON.stringify(emptyRemainingFile));
        newQuotationFileFormData.append("remain_list", JSON.stringify(emptyRemainingFile));
      }
    }
    this.apiService
      .editQuotationFile(
        this.object.quotation_file._id,
        newQuotationFileFormData
      )
      .subscribe(
        response => {
          this.snackBar.open("Updated successfully", null, {
            duration: 2000,
            horizontalPosition: "right"
          });
          this.apiService
            .getAllQuotationFiles(this.object.quotation_file.quotation._id)
            .subscribe((data: any) => {
              if (data.length > 0) {
                this.quotationFileService.bindQuotationFiles(data);
              }
            });
        },
        error => {
          this.snackBar.open("Update failed!", null, {
            duration: 2000,
            panelClass: ["red-snackbar"],
            horizontalPosition: "right"
          });
        }
      );
  }
}
