import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuotationFileService {

  quotationFiles: Array<object> = [];
  selectedQuotationFile: any;
  private bs_all_quotation_files = new BehaviorSubject<any>(this.quotationFiles);
  private bs_selected_quotation_file = new BehaviorSubject<any>(this.selectedQuotationFile);



  getAllQuotationFiles = this.bs_all_quotation_files.asObservable();
  getSelectedQuotationFile = this.bs_selected_quotation_file.asObservable();

  constructor() {}

  bindQuotationFiles(quotations: any) {
    this.bs_all_quotation_files.next(quotations);
  }

  bindSelectedQuotationFile(quotation: any){
    this.bs_selected_quotation_file.next(quotation);
  }
}
