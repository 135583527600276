import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { DocumentService } from 'src/app/shared/document.service';

@Component({
    selector: 'delete-file',
    templateUrl: 'delete-file.component.html',
    styleUrls: ['delete-file.component.css']
  })
  export class DeleteFileComponent {
    child_files: any = [];
    parentID: string = '';
  
    constructor(public dialogRef: MatDialogRef<DeleteFileComponent>, 
                @Inject(MAT_DIALOG_DATA) public object: any, 
                private apiService: ApiService, 
                private documentService: DocumentService,
                private snackBar: MatSnackBar) { 
                  dialogRef.disableClose = true;
                }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
   
    ngOnInit() {
      this.documentService.getParentID.subscribe(parentID => this.parentID = parentID);
      this.documentService.getFiles.subscribe(child_files => this.child_files = child_files);
    }
  
    handleDeleteFile() {
      this.apiService.deleteFile(this.object.file_id).subscribe((response) => {
        this.apiService.getSubItems(this.parentID).subscribe((data: any) => {
          this.documentService.bindFiles(data.files);
        });
        this.snackBar.open('Deleted successfully', null, {duration: 2000, horizontalPosition: 'right'});
      });
    }
  }