import { Component, Input, Directive, EventEmitter, Output } from '@angular/core';
import { DocumentService } from '../../../../../../shared/document.service';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css']
})
export class TreeViewComponent {

  @Input() directories: Array<object>;
  @Input() listSelectedID: Array<string>;
  @Output() parentEvent = new EventEmitter<object>();

  constructor(private documentService: DocumentService) {
  }

  onDropdownClick(dir: any) {
    if(dir.childs.length != 0) {
      if ($("#sign_" + dir._id).hasClass("fa fa-angle-right")) {
        $("#childs_" + dir._id).show();
        $("#sign_" + dir._id).removeClass("fa fa-angle-right").addClass("fa fa-angle-down");
      } else {
        $("#childs_" + dir._id).hide();
        $("#sign_" + dir._id).removeClass("fa fa-angle-down").addClass("fa fa-angle-right");
      }
    }
  }

  findActiveChild(directories: any[]): boolean {
    var result = false;
    for (var i = 0; i < directories.length; i++) {
      if (  directories[i]._id === this.documentService.activeDirectory ||  this.findActiveChild(directories[i].childs)) {
        result = true;
      } 
    }
    return result;
  }

  selectDirectory(dir_name: string, dir_id: string) {
    this.documentService.activeDirectory = dir_id;
    this.parentEvent.emit([dir_name, dir_id]);
  }

  selectSubDirectory(obj: object) {
    this.documentService.activeDirectory = obj[1];
    this.parentEvent.emit([obj[0], obj[1]]);
  }
}
