import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest
} from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserLoginService } from "./userlogin.service";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../environments/environment";
import {
  CreateOfficeBody,
  CreateOfficeResponse,
  OfficesResponse
} from "../models/office.model";
import {
  PublicHolidayModel,
  PublicHolidayModelResponse,
  PublicHolidaysModelResponse
} from "../models/public-holiday.model";
import {
  AttendanceResponse,
  AttendanceStatusModel
} from "../models/attendance.model";
import { LeaveTypesResponse } from "../models/leave-type.model";
import {
  AnnualLeavesResponse,
  LeaveRequestModel
} from "../models/leave-request.model";
import { UserPagination } from "../models/user.model";
import { PositionPagination } from "../models/position.model";
import { LeaveRequestNotification } from "../models/leave-notification.model";
import { ObserveOnMessage } from "rxjs/internal/operators/observeOn";
import { CustomerPagination } from "../models/customer.model";
import { SupplierPagination } from "../models/supplier.model";
import { ProjectPagination } from "../models/project.model";
import { QuotationPagination } from "../models/quotation.model";
import { QuotationFilePagination } from "../models/quotation-file.model";
import { ProjectFilePagination } from "../models/project-file.model";
import { CompanyPagination } from "../models/company.model";
import { ContactModel } from "../models/contact.model";
import { GroupModel } from "../models/group.model";
// import { runInThisContext } from 'vm';

@Injectable({
  providedIn: "root"
})
export class ApiService {
  fileUrl: any;

  API_URL = environment.url;
  public isUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private http: HttpClient,
    private userToken: UserLoginService,
    private sanitizer: DomSanitizer
  ) { }

  public getDirectories() {
    return this.http.get(
      `${this.API_URL}/directories/tree?token=${this.userToken.getToken()}`
    );
  }

  public getSubItems(dir_id: string) {
    return this.http.get(
      `${this.API_URL}/directories/${dir_id}?token=${this.userToken.getToken()}`
    );
  }

  /**
   * Directory http request
   */
  public createDirectory(directory: any) {
    return this.http.post(
      `${this.API_URL}/directories?token=${this.userToken.getToken()}`,
      directory
    );
  }

  public deleteDirectory(dir_id: string) {
    return this.http.delete(
      `${this.API_URL}/directories/${dir_id}?token=${this.userToken.getToken()}`
    );
  }

  public renameItem(dir: any, dir_id: string) {
    return this.http.patch(
      `${
      this.API_URL
      }/directories/${dir_id}?token=${this.userToken.getToken()}`,
      dir
    );
  }

  public changeDirOrder(list_changed_order: any) {
    return this.http.post(
      `${
      this.API_URL
      }/directories/arrange_order?token=${this.userToken.getToken()}`,
      list_changed_order
    );
  }

  /**
   * File http request
   */
  public getFiles(dir_id: string) {
    return this.http.get(
      `${this.API_URL}/files/dir/${dir_id}?token=${this.userToken.getToken()}`
    );
  }

  public createSoftFile(file: any) {
    return this.http.post(
      `${this.API_URL}/files?token=${this.userToken.getToken()}`,
      file
    );
  }

  public uploadNewFile(file_id: string, file: File) {
    let formData = new FormData();
    formData.append("file", file, file.name);
    return this.http.post(
      `${
      this.API_URL
      }/files/upload/${file_id}?token=${this.userToken.getToken()}`,
      formData,
      {
        reportProgress: true,
        observe: "events"
      }
    );
  }

  public deleteFile(file_id: string) {
    return this.http.delete(
      `${this.API_URL}/files/${file_id}?token=${this.userToken.getToken()}`
    );
  }

  public editFile(file: any, file_id: string) {
    return this.http.patch(
      `${this.API_URL}/files/${file_id}?token=${this.userToken.getToken()}`,
      file
    );
  }

  public changeFileOrder(list_changed_order: any) {
    return this.http.post(
      `${this.API_URL}/files/arrange_order?token=${this.userToken.getToken()}`,
      list_changed_order
    );
  }

  public downloadFile(file: any) {
    return this.http
      .get(
        `${this.API_URL}/files/download/${
        file._id
        }?token=${this.userToken.getToken()}`,
        {
          responseType: "blob"
        }
      )
      .pipe(
        map(res => {
          return {
            filename: file.name,
            data: res
          };
        })
      )
      .subscribe(
        res => {
          console.log("start download:", res);
          var url = window.URL.createObjectURL(res.data);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        },
        error => {
          console.log("download error:", JSON.stringify(error));
        },
        () => {
          console.log("Completed file download.");
        }
      );
  }

  /**
   * User http request
   */
  public getAllUsers() {
    return this.http.get(
      `${this.API_URL}/users?token=${this.userToken.getToken()}`
    );
  }

  public createUser(user: any) {
    return this.http.post(
      `${this.API_URL}/users?token=${this.userToken.getToken()}`,
      user
    );
  }

  public editUser(userID: string, newUser: any) {
    return this.http.patch(
      `${this.API_URL}/users/${userID}?token=${this.userToken.getToken()}`,
      newUser
    );
  }

  public resetPassword(userID: string, newPassword: any) {
    return this.http.post(
      `${
      this.API_URL
      }/users/reset/${userID}?token=${this.userToken.getToken()}`,
      newPassword
    );
  }

  public getListUsers(userPagination: UserPagination, search_txt: string, office: string) {
    if (search_txt !== '') {
      return this.http.get(
        `${this.API_URL}/users?token=${this.userToken.getToken()}&count=${
        userPagination.count
        }&page=${userPagination.page}&export=${userPagination.export}`, {
          params: {
            office: office,
            name: search_txt
          }
        }
      );
    } else {
      return this.http.get(
        `${this.API_URL}/users?token=${this.userToken.getToken()}&count=${
        userPagination.count
        }&page=${userPagination.page}&export=${userPagination.export}`, {
          params: {
            office: office
          }
        }
      );
    }
  }

  /**
   * Change own password http request
   */
  public changeOwnPassword(
    userID: string,
    newPassword: string,
    oldPassword: string
  ) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data;boundary=abc");

    var formData = {};
    formData["new_password"] = newPassword;
    formData["old_password"] = oldPassword;
    console.log(formData);
    return this.http.post(
      `${
      this.API_URL
      }/users/reset_password/${userID}?token=${this.userToken.getToken()}`,
      formData,
      {
        reportProgress: true,
        responseType: "json",
        headers: headers
      }
    );
  }

  /**
   * Role http request
   */
  public getAllRoles() {
    return this.http.get(
      `${this.API_URL}/roles?token=${this.userToken.getToken()}`
    );
  }

  /**
   * Position http request
   */
  public getAllPositions() {
    return this.http.get(
      `${this.API_URL}/positions?token=${this.userToken.getToken()}`
    );
  }

  public createPosition(position: any) {
    return this.http.post(
      `${this.API_URL}/positions?token=${this.userToken.getToken()}`,
      position
    );
  }

  public editPosition(positionID: string, newPosition: any) {
    return this.http.patch(
      `${
      this.API_URL
      }/positions/${positionID}?token=${this.userToken.getToken()}`,
      newPosition
    );
  }

  public deletePosition(position_id: string) {
    return this.http.delete(
      `${
      this.API_URL
      }/positions/${position_id}?token=${this.userToken.getToken()}`
    );
  }

  public getListPositions(positionPagination: PositionPagination) {
    return this.http.get(
      `${this.API_URL}/positions?token=${this.userToken.getToken()}&count=${
      positionPagination.count
      }&page=${positionPagination.page}&export=${positionPagination.export}`
    );
  }

  public getAllOffices() {
    return this.http.get(
      `${this.API_URL}/offices?token=${this.userToken.getToken()}`
    );
  }

  // Attendant http request

  public getAllAttendants() {
    return this.http.get(
      `${this.API_URL}/attendants?token=${this.userToken.getToken()}`
    );
  }

  public getAttendanceReport(start_date: string, end_date: string) {
    return this.http.get(`${this.API_URL}/attendance_report?token=${this.userToken.getToken()}`, {
      params: {
        start_date: start_date,
        end_date: end_date
      }
    });
  }

  // Customer http request

  public getAllCustomers() {
    return this.http.get(
      `${this.API_URL}/customers?token=${this.userToken.getToken()}`
    );
  }

  public getListCustomers(
    customerPagination: CustomerPagination,
    company_name: string
  ) {
    if (company_name !== "" && company_name !== undefined) {
      return this.http.get(
        `${this.API_URL}/customers?token=${this.userToken.getToken()}&count=${
        customerPagination.count
        }&page=${customerPagination.page}&export=${
        customerPagination.export
        }&company_name=${company_name}`
      );
    } else {
      return this.http.get(
        `${this.API_URL}/customers?token=${this.userToken.getToken()}&count=${
        customerPagination.count
        }&page=${customerPagination.page}&export=${customerPagination.export}`
      );
    }
  }

  public createCustomer(customer: any) {
    return this.http.post(
      `${this.API_URL}/customers?token=${this.userToken.getToken()}`,
      customer
    );
  }

  public editCustomer(customer_id: string, newCustomer: any) {
    return this.http.patch(
      `${
      this.API_URL
      }/customers/${customer_id}?token=${this.userToken.getToken()}`,
      newCustomer
    );
  }

  public deleteCustomer(customer_id: string) {
    return this.http.delete(
      `${
      this.API_URL
      }/customers/${customer_id}?token=${this.userToken.getToken()}`
    );
  }

  // Supplier http request

  public getAllSuppliers() {
    return this.http.get(
      `${this.API_URL}/suppliers?token=${this.userToken.getToken()}`
    );
  }

  public getListSuppliers(
    supplierPagination: SupplierPagination,
    company_name: string
  ) {
    if (company_name !== "" && company_name !== undefined) {
      return this.http.get(
        `${this.API_URL}/suppliers?token=${this.userToken.getToken()}&count=${
        supplierPagination.count
        }&page=${supplierPagination.page}&export=${
        supplierPagination.export
        }&company_name=${company_name}`
      );
    } else {
      return this.http.get(
        `${this.API_URL}/suppliers?token=${this.userToken.getToken()}&count=${
        supplierPagination.count
        }&page=${supplierPagination.page}&export=${supplierPagination.export}`
      );
    }
  }

  public createSupplier(supplier: any) {
    return this.http.post(
      `${this.API_URL}/suppliers?token=${this.userToken.getToken()}`,
      supplier
    );
  }

  public deleteSupplier(supplier_id: string) {
    return this.http.delete(
      `${
      this.API_URL
      }/suppliers/${supplier_id}?token=${this.userToken.getToken()}`
    );
  }

  public editSupplier(supplier_id: string, newSupplier: any) {
    return this.http.patch(
      `${
      this.API_URL
      }/suppliers/${supplier_id}?token=${this.userToken.getToken()}`,
      newSupplier
    );
  }

  // company http request
  public getAllCompanies() {
    return this.http.get(
      `${this.API_URL}/companies?token=${this.userToken.getToken()}`
    );
  }

  public getListCompanies(
    companyPagination: CompanyPagination,
    searchKeyword: string
  ) {
    if (searchKeyword !== "" && searchKeyword !== undefined) {
      return this.http.get(
        `${this.API_URL}/companies?token=${this.userToken.getToken()}&count=${
        companyPagination.count
        }&page=${companyPagination.page}&export=${
        companyPagination.export
        }&search=${searchKeyword}`
      );
    } else {
      return this.http.get(
        `${this.API_URL}/companies?token=${this.userToken.getToken()}&count=${
        companyPagination.count
        }&page=${companyPagination.page}&export=${companyPagination.export}`
      );
    }
  }

  public createCompany(company: any) {
    return this.http.post(
      `${this.API_URL}/companies?token=${this.userToken.getToken()}`,
      company
    );
  }

  public editCompany(companyID: string, newCompany: any) {
    return this.http.patch(
      `${
      this.API_URL
      }/companies/${companyID}?token=${this.userToken.getToken()}`,
      newCompany
    );
  }

  public deleteCompany(companyID: string) {
    return this.http.delete(
      `${
      this.API_URL
      }/companies/${companyID}?token=${this.userToken.getToken()}`
    );
  }

  // project http request

  public getAllProjects() {
    return this.http.get(
      `${this.API_URL}/projects?token=${this.userToken.getToken()}`
    );
  }

  public getListProjects(
    projectPagination: ProjectPagination,
    search_keyword: string
  ) {
    if (search_keyword !== "" && search_keyword !== undefined) {
      return this.http.get(
        `${this.API_URL}/projects?token=${this.userToken.getToken()}&count=${
        projectPagination.count
        }&page=${projectPagination.page}&export=${
        projectPagination.export
        }&search=${search_keyword}`
      );
    } else {
      return this.http.get(
        `${this.API_URL}/projects?token=${this.userToken.getToken()}&count=${
        projectPagination.count
        }&page=${projectPagination.page}&export=${projectPagination.export}`
      );
    }
  }

  public createProject(project: any) {
    return this.http.post(
      `${this.API_URL}/projects?token=${this.userToken.getToken()}`,
      project
    );
  }

  public editProject(project_id: string, newProject: any) {
    return this.http.patch(
      `${
      this.API_URL
      }/projects/${project_id}?token=${this.userToken.getToken()}`,
      newProject
    );
  }

  public deleteProject(project_id: string) {
    return this.http.delete(
      `${
      this.API_URL
      }/projects/${project_id}?token=${this.userToken.getToken()}`
    );
  }

  // project file http request
  public createProjectFile(projectFile: any) {
    return this.http.post(
      `${this.API_URL}/related_files?token=${this.userToken.getToken()}`,
      projectFile
    );
  }

  public getAllProjectFiles(projectID: string) {
    return this.http.get(
      `${
      this.API_URL
      }/related_files?token=${this.userToken.getToken()}&project_id=${projectID}`
    );
  }

  public getListProjectFiles(
    projectFileQuotaton: ProjectFilePagination,
    projectID: string
  ) {
    return this.http.get(
      `${
      this.API_URL
      }/related_files?project_id=${projectID}&token=${this.userToken.getToken()}&count=${
      projectFileQuotaton.count
      }&page=${projectFileQuotaton.page}&export=${projectFileQuotaton.export}`
    );
  }

  public editProjectFile(projectFileID: string, newProjectFile: any) {
    return this.http.patch(
      `${
      this.API_URL
      }/related_files/${projectFileID}?token=${this.userToken.getToken()}`,
      newProjectFile
    );
  }

  public deleteProjectFile(projectFileID: string) {
    return this.http.delete(
      `${
      this.API_URL
      }/related_files/${projectFileID}?token=${this.userToken.getToken()}`
    );
  }

  // Quotation http request

  public getAllQuotations() {
    return this.http.get(
      `${this.API_URL}/quotations?token=${this.userToken.getToken()}`
    );
  }

  public getQuotationNotifications() {
    return this.http.get(
      `${
      this.API_URL
      }/quotation_notifications?token=${this.userToken.getToken()}`
    );
  }

  public getQuotationsByProjectID(project_id: string) {
    return this.http.get(
      `${
      this.API_URL
      }/quotations?token=${this.userToken.getToken()}&project_id=${project_id}`
    );
  }

  public getListQuotations(
    quotationPagination: QuotationPagination,
    search_keyword: string,
    projectID: string = "",
    type: string = ""
  ) {
    let url = `${
      this.API_URL
      }/quotations?token=${this.userToken.getToken()}&count=${
      quotationPagination.count
      }&page=${quotationPagination.page}&export=${quotationPagination.export}`;
    if (projectID !== "0") {
      url = url + `&project_id=${projectID}`;
    }
    if (search_keyword !== "" && search_keyword !== undefined) {
      url = url + `&title=${search_keyword}`;
    }
    if (type !== "") {
      url = url + `&type=${type}`;
    }
    return this.http.get(url);
  }

  public createQuotation(quotation: any) {
    let lastQuotation: any = quotation;

    if (quotation.type === "recieved") {
      quotation.customer_id = "null";
    }
    if (quotation.type === "issued") {
      quotation.supplier_id = "null";
    }
    if (quotation.attendant_email === '') {
      lastQuotation = {
        attendant_company_id: quotation.attendant_company_id,
        attendant_name: quotation.attendant_name,
        attendant_phone_number: quotation.attendant_phone_number,
        customer_id: quotation.customer_id,
        description: quotation.description,
        project_id: quotation.project_id,
        quotation_number: quotation.quotation_number,
        status: quotation.status,
        supplier_id: quotation.supplier_id,
        title: quotation.title,
        type: quotation.type
      };
    }
    return this.http.post(
      `${this.API_URL}/quotations?token=${this.userToken.getToken()}`,
      lastQuotation
    );
  }

  public editQuotation(quotation_id: string, newQuotation: any) {
    let lastQuotation: any = newQuotation;

    if (newQuotation.type === "recieved") {
      newQuotation.customer_id = "null";
    }
    if (newQuotation.type === "issued") {
      newQuotation.supplier_id = "null";
    }
    if (newQuotation.attendant_email === '' || newQuotation.attendant_email === null) {
      lastQuotation = {
        attendant_company_id: newQuotation.attendant_company_id,
        attendant_name: newQuotation.attendant_name,
        attendant_phone_number: newQuotation.attendant_phone_number,
        customer_id: newQuotation.customer_id,
        description: newQuotation.description,
        project_id: newQuotation.project_id,
        quotation_number: newQuotation.quotation_number,
        status: newQuotation.status,
        supplier_id: newQuotation.supplier_id,
        title: newQuotation.title,
        type: newQuotation.type
      };
    }

    return this.http.patch(
      `${
      this.API_URL
      }/quotations/${quotation_id}?token=${this.userToken.getToken()}`,
      lastQuotation
    );
  }

  public deleteQuotation(quotation_id: string) {
    return this.http.delete(
      `${
      this.API_URL
      }/quotations/${quotation_id}?token=${this.userToken.getToken()}`
    );
  }

  // get attendant of company
  public getAttendantsByCompany(company_id: string) {
    return this.http.get(
      `${
      this.API_URL
      }/attendants?token=${this.userToken.getToken()}&company_id=${company_id}`
    );
  }

  // Quotation File http request

  public getAllQuotationFiles(quotation_id: string) {
    return this.http.get(
      `${
      this.API_URL
      }/quotation_files?token=${this.userToken.getToken()}&quotation_id=${quotation_id}`
    );
  }

  public getAQuotationFile(quotation_id: string, file_id: string) {
    return this.http.get(
      `${
      this.API_URL
      }/quotation_files/${file_id}?token=${this.userToken.getToken()}&quotation_id=${quotation_id}`
    );
  }

  public getListQuotationFiles(
    quotationFilePagination: QuotationFilePagination,
    quotation_id: string
  ) {
    return this.http.get(
      `${
      this.API_URL
      }/quotation_files?quotation_id=${quotation_id}&token=${this.userToken.getToken()}&count=${
      quotationFilePagination.count
      }&page=${quotationFilePagination.page}&export=${
      quotationFilePagination.export
      }`
    );
  }

  public createQuotationFile(quotationFile: any) {
    return this.http.post(
      `${this.API_URL}/quotation_files?token=${this.userToken.getToken()}`,
      quotationFile
    );
  }

  public deleteQuotationFile(quotation_file_id: string) {
    return this.http.delete(
      `${
      this.API_URL
      }/quotation_files/${quotation_file_id}?token=${this.userToken.getToken()}`
    );
  }

  public editQuotationFile(quotation_file_id: string, newQuotationFile: any) {
    return this.http.patch(
      `${
      this.API_URL
      }/quotation_files/${quotation_file_id}?token=${this.userToken.getToken()}`,
      newQuotationFile
    );
  }

  downloadQuotationFile(link: any, revision: string = "Document") {
    // let headers = new HttpHeaders();
    // headers = headers.set("Accept", "application/pdf");
    // return this.http.get(`${link}?token=${this.userToken.getToken()}`, {
    //   // headers: headers,
    //   responseType: "blob"
    // });
    return this.http
      .get(`${link}`, {
        responseType: "blob"
      })
      .pipe(
        map(res => {
          return {
            filename: revision,
            data: res
          };
        })
      )
      .subscribe(
        res => {
          console.log("start download:", res);
          var url = window.URL.createObjectURL(res.data);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = res.filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        },
        error => {
          console.log("download error:", JSON.stringify(error));
        },
        () => {
          console.log("Completed file download.");
        }
      );
  }

  /**
   * Create new office
   */

  public createOffice(
    officeBody: CreateOfficeBody
  ): Observable<CreateOfficeResponse> {
    return this.http.post<CreateOfficeResponse>(
      this.API_URL.concat("/offices"),
      officeBody,
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Get Offices
   */

  public getOffices(): Observable<OfficesResponse> {
    return this.http.get<CreateOfficeResponse>(
      this.API_URL.concat("/offices"),
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Get Offices Tree
   */

  public getOfficesTree(): Observable<OfficesResponse> {
    return this.http.get<CreateOfficeResponse>(
      this.API_URL.concat("/offices/tree"),
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Edit Office
   */

  public editOffice(
    officeId: string,
    officeBody: CreateOfficeBody
  ): Observable<CreateOfficeResponse> {
    return this.http.patch<CreateOfficeResponse>(
      this.API_URL.concat("/offices/" + officeId),
      officeBody,
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Delete Office
   */

  public deleteOffice(
    officeId: string
  ): Observable<{ status: number; message: string }> {
    return this.http.delete<{ status: number; message: string }>(
      this.API_URL.concat("/offices/" + officeId),
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Create Public holiday
   * @#
   */

  public createPublicHoliday(
    body: PublicHolidayModel
  ): Observable<PublicHolidayModelResponse> {
    return this.http.post<PublicHolidayModelResponse>(
      this.API_URL.concat("/public_holidays"),
      body,
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Get Public Holiday
   * @param year
   */

  public getPublicHolidays(
    year?: number
  ): Observable<PublicHolidaysModelResponse> {
    let param: any = {
      token: this.userToken.getToken()
    };

    if (year) {
      param = {
        token: this.userToken.getToken(),
        year: year.toString()
      };
    }

    return this.http.get<PublicHolidaysModelResponse>(
      this.API_URL.concat("/public_holidays"),
      {
        params: {
          ...param
        }
      }
    );
  }

  /**
   * Edit Public Holiday
   * @param body
   * @param id
   */

  public editPublicHoliday(
    id: string,
    body: PublicHolidayModel
  ): Observable<PublicHolidayModelResponse> {
    return this.http.patch(
      this.API_URL.concat("/public_holidays/" + id),
      body,
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Delete Public Holiday
   * @param publicHolidayId
   */

  public deletePublicHoliday(
    publicHolidayId: string
  ): Observable<{ status: number; message: string }> {
    return this.http.delete<{ status: number; message: string }>(
      this.API_URL.concat("/public_holidays/" + publicHolidayId),
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Get Attendance Status
   */

  public getAttendanceStatus(): Observable<AttendanceStatusModel> {
    return this.http.get<AttendanceStatusModel>(
      this.API_URL.concat("/attendances/status"),
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   *  Attendance Check In
   */

  public attendanceCheckIn(): Observable<AttendanceResponse> {
    return this.http.get<AttendanceResponse>(
      this.API_URL.concat("/attendances/check-in"),
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   *  Attendance Check Out
   */

  public attendanceCheckOut(): Observable<AttendanceResponse> {
    return this.http.get<AttendanceResponse>(
      this.API_URL.concat("/attendances/check-out"),
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Get all leave types
   */

  public getLeaveTypes(): Observable<LeaveTypesResponse> {
    return this.http.get<LeaveTypesResponse>(
      this.API_URL.concat("/leave_types"),
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Create Leave Request
   * @param body
   * @param files
   * @param delete_file_ids
   */
  public createLeaveRequest(
    body: LeaveRequestModel,
    files: File[] = [],
    delete_file_ids = []
  ): Observable<HttpEvent<Response>> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data;boundary=abc");

    const formData = new FormData();
    Object.keys(body).forEach(key => {
      if (body[key] !== null) {
        formData.append(key, body[key]);
      }
    });

    files.forEach(file => {
      formData.append("file", file);
    });

    delete_file_ids.forEach(file_id => {
      formData.append("delete_file_ids[]", file_id);
    });

    return this.http.request<Response>(
      new HttpRequest(
        "POST",
        this.API_URL.concat(
          "/leave_requests?token=" + this.userToken.getToken()
        ),
        formData,
        {
          reportProgress: true,
          responseType: "json",
          headers: headers
        }
      )
    );
  }

  /**
   * Get all leave requests
   */

  public getAnnualLeaveRequests(
    limit = 10,
    currentPage = 1
  ): Observable<AnnualLeavesResponse> {
    return this.http.get<AnnualLeavesResponse>(
      this.API_URL.concat("/leave_requests"),
      {
        params: {
          token: this.userToken.getToken(),
          count: limit.toString(10),
          page: currentPage.toString(10)
        }
      }
    );
  }
  public editLeaveRequest(
    body: LeaveRequestModel,
    files: File[] = [],
    leaveRequestID: string
  ): Observable<HttpEvent<Response>> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data;boundary=abc");

    const formData = new FormData();

    Object.keys(body).forEach(key => {
      if (body[key] !== null) {
        formData.append(key, body[key]);
      }
    });

    files.forEach(file => {
      formData.append("file", file);
    });

    // const editedData = [body, files];
    console.log(formData);

    return this.http.request<Response>(
      new HttpRequest(
        "POST",
        this.API_URL.concat(
          "/leave_requests?token=" + this.userToken.getToken()
        ),
        {
          reportProgress: true,
          responseType: "json",
          headers: headers
        }
      )
    );
  }

  /**
   * Get My leave requests
   */

  public getMyAnnualLeaveRequests(
    limit = 10,
    currentPage = 1,
    userID: string
  ): Observable<AnnualLeavesResponse> {
    return this.http.get<AnnualLeavesResponse>(
      this.API_URL.concat("/leave_requests"),
      {
        params: {
          token: this.userToken.getToken(),
          count: limit.toString(10),
          page: currentPage.toString(10),
          user_id: userID
        }
      }
    );
  }

  /**
   * Get Download URL of file in leave requests
   */

  public getLeaveRequestDownloadUrl(fileId: string): string {
    return `${
      this.API_URL
      }/files/download/${fileId}?token=${this.userToken.getToken()}`;
  }

  /**
   * Get string array of holiday
   */

  public getArrayOfPublicHoliday(
    yearValue?: number
  ): Observable<PublicHolidayArrayResponse> {
    const token = this.userToken.getToken();
    let params: { [param: string]: string } = {
      token
    };

    if (yearValue) {
      const year = yearValue.toString(10);
      params = {
        token,
        year
      };
    }

    return this.http.get<PublicHolidayArrayResponse>(
      this.API_URL.concat("/public_holidays/array"),
      {
        params
      }
    );
  }

  /**
   * Get Annual leave count
   */

  public getAnnualLeaveCount(): Observable<AnnualLeaveCountResponse> {
    return this.http.get<AnnualLeaveCountResponse>(
      this.API_URL.concat("/annual_leaves"),
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Cancel Leave Requests
   */

  public cancelLeaveRequest(
    requestId: string
  ): Observable<{ status: number; message: string }> {
    return this.http.post<{ status: number; message: string }>(
      this.API_URL.concat("/leave_requests/cancel/".concat(requestId)),
      {},
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Get Leave notification Request
   */

  public getLeaveNotification(managerID: string) {
    return this.http.get(this.API_URL.concat("/leave_requests/notification"), {
      params: {
        token: this.userToken.getToken(),
        manager: managerID
      }
    });
  }

  /**
   * Get Leave notification Request
   */

  public getManagers() {
    return this.http.get(this.API_URL.concat("/users/managers"), {
      params: {
        token: this.userToken.getToken()
      }
    });
  }

  public;

  /**
   * Get Activity Log Request
   */

  public getActivityLogs() {
    return this.http.get(this.API_URL.concat("/activity_logs"), {
      params: {
        token: this.userToken.getToken()
      }
    });
  }

  /**
   * Approve leave record
   * @param leaveID
   */

  public approveLeaveRequest(leaveID: string) {
    return this.http.post(
      this.API_URL.concat("/leave_requests/approved/".concat(leaveID)),
      {},
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Reject Leave Request
   * @param requestId
   * @param reason
   */

  public rejectLeaveRequest(
    requestId: string,
    reason: string
  ): Observable<{
    status: number;
    message: string;
    leave_request: LeaveRequestModel;
  }> {
    return this.http.post<{
      status: number;
      message: string;
      leave_request: LeaveRequestModel;
    }>(
      this.API_URL.concat("/leave_requests/rejected/".concat(requestId)),
      {
        reason
      },
      {
        params: {
          token: this.userToken.getToken()
        }
      }
    );
  }

  /**
   * Get Payment Deposit
   * @param start_date
   * @param end_date
   */

  public getPaymentDeposit(start_date: string, end_date: string) {
    return this.http.get(this.API_URL.concat("/payment/deposit"), {
      params: {
        from: start_date,
        to: end_date,
        token: this.userToken.getToken()
      }
    });
  }

  /**
   * Get Payment Redeem
   * @param start_date
   * @param end_date
   */

  public getPaymentRedeem(start_date: string, end_date: string) {
    return this.http.get(this.API_URL.concat("/payment/redeem"), {
      params: {
        from: start_date,
        to: end_date,
        token: this.userToken.getToken()
      }
    });
  }

  /**
   * Get Payment Promotion
   * @param start_date
   * @param end_date
   */

  public getPaymentPromotion(start_date: string, end_date: string) {
    return this.http.get(this.API_URL.concat("/payment/promotion"), {
      params: {
        from: start_date,
        to: end_date,
        token: this.userToken.getToken()
      }
    });
  }

  /**
   * Get Payment Income by Category
   * @param start_date
   * @param end_date
   */

  public getPaymentIncomeByCategory(start_date: string, end_date: string) {
    return this.http.get(this.API_URL.concat("/payment/income_by_category"), {
      params: {
        from: start_date,
        to: end_date,
        token: this.userToken.getToken()
      }
    });
  }

  /**
   * Get Payment Tour status
   * @param start_date
   * @param end_date
   */

  public getPaymentTourStatus(start_date: string, end_date: string) {
    return this.http.get(this.API_URL.concat("/payment/tour_status"), {
      params: {
        from: start_date,
        to: end_date,
        token: this.userToken.getToken()
      }
    });
  }

  /**
   * Get User by Name and Office
   * @param name
   * @param office
   */

  public getFilteredUsers(search_txt: string, office: string) {
    if (search_txt !== '') {
      return this.http.get(this.API_URL.concat("/users"), {
        params: {
          office: office,
          name: search_txt,
          token: this.userToken.getToken()
        }
      });
    } else {
      return this.http.get(this.API_URL.concat("/users"), {
        params: {
          office: office,
          token: this.userToken.getToken()
        }
      });
    }
  }

  /**
   * Get All Contacts
   */

  public getAllContacts(count: number, page: number, name: string = '') {
    return this.http.get(this.API_URL.concat("/contacts"), {
      params: {
        token: this.userToken.getToken(),
        page: page + "",
        count: count + "",
        name: name
      }
    });
  }

  /**
   * Get All Groups
   */

  public getAllGroups(name: string = '') {
    return this.http.get(this.API_URL.concat("/groups"), {
      params: {
        token: this.userToken.getToken(),
        name: name
      }
    });
  }

  /**
   * Create Leave Request
   * @param body
   * @param file
   */
  public createContact(body: ContactModel, file: File): Observable<HttpEvent<Response>> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data;boundary=abc");

    const formData = new FormData();

    formData.append("name", body.name);

    if (body.phones.length !== 0) {
      body.phones.forEach((phone) => {
        formData.append("phones", phone);
      });
    }

    if (body.emails.length !== 0) {
      body.emails.forEach((email) => {
        formData.append("emails", email);
      });
    }

    if (body.group_ids.length !== 0) {
      body.group_ids.forEach((group_id) => {
        formData.append("group_ids", group_id);
      });
    }

    formData.append("website", body.website);
    formData.append("fax", body.fax);
    formData.append("note", body.note);

    if (file !== null) formData.append("photo", file, file.name);

    return this.http.request<Response>(
      new HttpRequest(
        "POST",
        this.API_URL.concat(
          "/contacts?token=" + this.userToken.getToken()
        ),
        formData,
        {
          reportProgress: true,
          responseType: "json",
          headers: headers
        }
      )
    );
  }

  /**
   * Edit Contact
   * @param body
   * @param file
   */
  public editContact(body: ContactModel, file: File): Observable<HttpEvent<Response>> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data;boundary=abc");
    
    const formData = new FormData();

    formData.append("name", body.name);

    if (body.phones.length !== 0) {
      [...body.phones].forEach((phone) => {
        formData.append("phones", phone.toString());
      });
    }

    if (body.emails.length !== 0) {
      [...body.emails].forEach((email) => {
        formData.append("emails", email.toString());
      });
    }

    if (body.group_ids.length !== 0) {
      body.group_ids.forEach((group_id) => {
        formData.append("group_ids", group_id);
      });
    }

    formData.append("website", body.website);
    formData.append("fax", body.fax);
    formData.append("note", body.note);

    if (file !== null) formData.append("photo", file);

    return this.http.request<Response>(
      new HttpRequest(
        "PATCH",
        this.API_URL.concat(
          `/contacts/${body._id}?token=${this.userToken.getToken()}`
        ),
        formData,
        {
          reportProgress: true,
          responseType: "json",
          headers: headers
        }
      )
    );
  }

  /**
   * Get Groups by ID
   */

  public getGroupById(groupID: string) {
    return this.http.get(this.API_URL.concat(`/groups/${groupID}`), {
      params: {
        token: this.userToken.getToken()
      }
    });
  }

  /**
   * Get contact by ID
   */

  public getContactById(contactID: string) {
    return this.http.get(this.API_URL.concat(`/contacts/${contactID}`), {
      params: {
        token: this.userToken.getToken()
      }
    });
  }

  /**
   * Edit group
   * @param body
   * @param file
   */
  public editGroup(body: GroupModel, file: File): Observable<HttpEvent<Response>> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data;boundary=abc");
    
    const formData = new FormData();

    formData.append("name", body.name);

    if (body.add_contact_ids.length !== 0) {
      body.add_contact_ids.forEach((contact_id) => {
        formData.append("add_contact_ids", contact_id);
      });
    }

    if (body.remove_contact_ids.length !== 0) {
      body.remove_contact_ids.forEach((contact_id) => {
        formData.append("remove_contact_ids", contact_id);
      });
    }

    if (file !== null) formData.append("photo", file);

    return this.http.request<Response>(
      new HttpRequest(
        "PATCH",
        this.API_URL.concat(
          `/groups/${body._id}?token=${this.userToken.getToken()}`
        ),
        formData,
        {
          reportProgress: true,
          responseType: "json",
          headers: headers
        }
      )
    );
  }

  /**
   * Create group
   * @param body
   * @param file
   */
  public createGroup(body: GroupModel, file: File): Observable<HttpEvent<Response>> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data;boundary=abc");

    const formData = new FormData();

    formData.append("name", body.name);

    if (body.contact_ids.length !== 0) {
      body.contact_ids.forEach((contact_id) => {
        formData.append("contact_ids", contact_id);
      });
    }

    if (file !== null) formData.append("photo", file, file.name);

    return this.http.request<Response>(
      new HttpRequest(
        "POST",
        this.API_URL.concat(
          "/groups?token=" + this.userToken.getToken()
        ),
        formData,
        {
          reportProgress: true,
          responseType: "json",
          headers: headers
        }
      )
    );
  }

  /**
   * Delete group
   * @param group_id
   */
  public deleteGroup(group_id: string) {
    return this.http.delete(
      `${this.API_URL}/groups/${group_id}?token=${this.userToken.getToken()}`
    );
  }

  /**
   * Delete contact
   * @param contact_id
   */
  public deleteContact(contact_id: string) {
    return this.http.delete(
      `${this.API_URL}/contacts/${contact_id}?token=${this.userToken.getToken()}`
    );
  }
}

export interface AnnualLeaveCountModel {
  _id?: string;
  user?: string;
  year?: string;
  remaining_days?: number;
}

export interface AnnualLeaveCountResponse {
  status?: number;
  annual_leave?: AnnualLeaveCountModel;
}

export interface PublicHolidayArrayResponse {
  status?: number;
  public_holidays?: string[];
}
