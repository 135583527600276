import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { UserService } from 'src/app/shared/user.service';

@Component({
    selector: 'delete-position',
    templateUrl: 'delete-position.component.html',
    styleUrls: ['delete-position.component.css']
  })
  export class DeletePositionComponent {
  
    constructor(public dialogRef: MatDialogRef<DeletePositionComponent>, 
                @Inject(MAT_DIALOG_DATA) public object: any, 
                private apiService: ApiService, 
                private userService: UserService,
                private snackBar: MatSnackBar) { 
                  dialogRef.disableClose = true;
                }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
   
    ngOnInit() {
    }
  
    handleDeletePosition() {
      this.apiService.deletePosition(this.object.position_id).subscribe((response: any) => {
        this.apiService.getAllPositions().subscribe((data: any) => {
            this.userService.bindPositions(data.positions);
        });
        this.snackBar.open('Deleted successfully', null, {duration: 2000, horizontalPosition: 'right'});
      });
    }
  }