import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLoginService } from '../../../shared/userlogin.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  
  returnUrl: string = '';

  constructor(private router: Router, 
    private userLoginService: UserLoginService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.returnUrl = this.userLoginService.getReturnURL();

    if(this.userLoginService.getCurrentUserRoleName() !== "Staff" && this.userLoginService.getCurrentUserRoleName() !== "Manager"){
      this.router.navigateByUrl('admin/dashboard/income');
    } 
    else if(this.returnUrl == '/' || !this.userLoginService.getToken()) {
      this.router.navigateByUrl('admin/dashboard/attendances');
    } 
    else {
      this.router.navigateByUrl('admin/dashboard/leave-requests');
    }
    
  }

}
