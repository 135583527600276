import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Promotion } from '../../../../../models/promotion.model';
import { UserLoginService } from '../../../../../shared/userlogin.service';
import * as dayjs from 'dayjs';
import {Location, DatePipe} from '@angular/common';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ExcelService } from 'src/app/shared/excel.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-outcome-detail',
  templateUrl: './outcome-detail.component.html',
  styleUrls: ['./outcome-detail.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ],
})
export class OutcomeDetailComponent implements OnInit {

  today = new Date();
  minDate = new Date(2017, 1, 1);
  maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  bsValue = new Date();
  listPromoDetail: any[];
  listPromoType = ["TA", "TG", "OP", "AE", "empty"];
  promotion = new Promotion();
  typePromo:string = "";
  page_increasement = 0;

  isLoading = true;

  constructor(private activeRoute: ActivatedRoute, 
              private promotionService: UserLoginService, 
              private location: Location,
              private excelService: ExcelService,
              private userLoginService: UserLoginService,
              private datePipe: DatePipe) {

    activeRoute.params.subscribe(params => {
      if (params.type == "empty") {
        this.promotion.type = "";
      }else{
        this.promotion.type = params.type;
      }
      this.typePromo = params.type;
    });
   }

  ngOnInit() {
    this.promotion.page = 1;
    this.promotion.count = 10;
    this.promotion.from = this.promotionService.getStartDate();
    this.promotion.to = this.promotionService.getEndDate();

    if(this.promotion.from == null) {
      this.promotion.from = this.getDefaultStartDate();
    }

    if(this.promotion.to == null) {
      this.promotion.to = this.getDefaultEndDate();
    }

    this.loadPromotionDetail();
  }

  getDefaultStartDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-01';
  }

  getDefaultEndDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let dd: any = this.daysInMonth(mm, yyyy);

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-' + dd;
  }

  daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
  }

  //type selector
  selectTypeHandler(event){
    this.promotion.type = event.target.value;
    this.loadPromotionDetail();
  }

  // count selector
  selectCountHandler(event: any){
    this.promotion.count = event.target.value;
    this.loadPromotionDetail();
  }

  // start date selector
  onValueStartChange(date: any): void {
    this.promotion.from = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.promotion.page = 1;
    this.promotion.count = 10;
    this.promotionService.setStartDate(this.datePipe.transform(date.value, 'yyyy-MM-dd'));
    this.loadPromotionDetail();
  }

  // end date selector
  onValueEndChange(date: any): void {
    this.promotion.to = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.promotion.page = 1;
    this.promotion.count = 10;
    this.promotionService.setEndDate(this.datePipe.transform(date.value, 'yyyy-MM-dd'));
    this.loadPromotionDetail();
  }

  onDownloadClick() {
    this.promotion.export = true;
    const url = this.userLoginService.getPromotionLink(this.promotion);
    window.open(url, "_blank");

    this.promotion.export = false;
  }

  currentPage = 1;
  //previous button click
  previousClick(){
    this.currentPage = this.currentPage - 1;
    this.promotion.page = this.currentPage;
    this.loadPromotionDetail();
    // console.log(this.currentPage);
  }

  //next button click
  nextClick(){
    this.currentPage = this.currentPage + 1;
    this.promotion.page = this.currentPage;
    this.loadPromotionDetail();
    console.log(this.currentPage);
  }

  loadPromotionDetail(){
    this.isLoading = true;
    this.promotionService.getListPromotionDetail(this.promotion).subscribe(Response => {
      
      setTimeout(() => {
        this.isLoading = false;
      }, 500);

      this.listPromoDetail = Response["promotion"];
      this.page_increasement = (this.currentPage - 1) * this.promotion.count;

    });
    this.promotion.export = false;
  }

  getToday() {
    let today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-' + dd;
  }
}
