import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatDatepickerInputEvent
} from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { ProjectService } from "../project.service";
import { ProjectInfo } from "../project.component";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-create-project-dialog",
  templateUrl: "./create-project-dialog.component.html",
  styleUrls: ["./create-project-dialog.component.scss"],
  providers: [DatePipe]
})
export class CreateProjectDialogComponent implements OnInit {
  customers: any;
  currentDate = new Date();
  maxDate: Date;
  minDate: Date;
  start_date: Date = this.currentDate;
  end_date: Date = this.currentDate;
  constructor(
    public dialogRef: MatDialogRef<CreateProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public project: ProjectInfo,
    private apiService: ApiService,
    private projectService: ProjectService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  projectNameFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  descriptionFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  projectManagerFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  customerFormControl: FormControl = new FormControl("", [Validators.required]);
  startDateFormControl: FormControl = new FormControl(this.currentDate, [
    Validators.required
  ]);
  endDateFormControl: FormControl = new FormControl("", [Validators.required]);

  projectFormGroup: FormGroup = new FormGroup({
    name: this.projectNameFormControl,
    description: this.descriptionFormControl,
    manager: this.projectManagerFormControl,
    customer: this.customerFormControl,
    start_date: this.startDateFormControl,
    end_date: this.endDateFormControl
  });

  getRequiredErrorMessage(field) {
    return this.projectFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }
  ngOnInit() {
    this.getCustomers();
    this.minDate = this.currentDate;
  }

  getCustomers() {
    this.apiService.getAllCompanies().subscribe(response => {
      this.customers = [];
      this.customers = response;
      this.project.customer_id = response[0]._id;
    });
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.projectFormGroup.invalid) {
        this.dialogRef.close();
        this.handleCreateProject();
      }
    }
  }

  startDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.start_date = event.value;
    this.minDate = new Date(event.value);
  }

  endDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.end_date = event.value;
    this.maxDate = new Date(event.value);
  }

  handleCreateProject() {
    this.project.start_date = this.datePipe.transform(this.start_date, 'yyyy-MM-dd');
    this.project.end_date = this.datePipe.transform(this.end_date, 'yyyy-MM-dd');
    this.apiService.createProject(this.project).subscribe(
      response => {
        this.apiService.getAllProjects().subscribe((data: any) => {
          if (data.length > 0) {
            this.projectService.bindProjects(data);
          }
        });
        this.snackBar.open("Created successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Create failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }
}
