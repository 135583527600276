import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/shared/api.service";
import { UserLoginService } from "src/app/shared/userlogin.service";

@Component({
  selector: "app-compare-quotation",
  templateUrl: "./compare-quotation.component.html",
  styleUrls: ["./compare-quotation.component.scss"]
})
export class CompareQuotationComponent implements OnInit {
  constructor(private apiService: ApiService, private userToken: UserLoginService) {}

  projects = [];
  quotationsA = [];
  quotationFilesA = [];
  filesA = [];
  quotationsB = [];
  quotationFilesB = [];
  filesB = [];
  isLoading: boolean = true;

  sourceA = "";
  sourceB = "";
  projectA = "";
  projectB = "";
  selectedQuotationA = "";
  selectedQuotationFileA = "";
  selectedQuotationB = "";
  selectedQuotationFileB = "";
  selectedFileA = '';
  selectedFileB = '';
  ngOnInit() {
    this.getAllProjects();
  }

  getAllProjects() {
    this.isLoading = true;
    this.apiService.getAllProjects().subscribe((projects: any) => {
      
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
      
      this.projects = [];
      if (projects.length > 0) {
        this.projects = projects;
      }
    });
  }

  onProjectAChange() {
    this.sourceA = "";
    this.selectedQuotationA = "";
    this.apiService
      .getQuotationsByProjectID(this.projectA)
      .subscribe((data: any) => {
        this.quotationsA = [];
        this.quotationFilesA = [];
        if (data.length > 0) {
          this.quotationsA = data;
        }
        console.log(data);
      });
  }

  onQuotationAChange() {

    this.sourceA = "";
    this.selectedQuotationFileA = "";
    this.apiService
      .getAllQuotationFiles(this.selectedQuotationA)
      .subscribe((data: any) => {
        this.quotationFilesA = [];
        if (data.length > 0) {
          this.quotationFilesA = data;
        }
      });
  }

  onQuotationFileAChange() {
    this.onQuotationBChange();
    this.sourceA = "";
    this.apiService
      .getAQuotationFile(this.selectedQuotationA, this.selectedQuotationFileA)
      .subscribe((data: any) => {
        this.filesA = data.file;
        console.log(this.filesA);
      });
  }

  onProjectBChange() {
    this.selectedQuotationB = "";
    this.apiService
      .getQuotationsByProjectID(this.projectB)
      .subscribe((data: any) => {
        this.quotationsB = [];
        this.quotationFilesB = [];
        if (data.length > 0) {
          this.quotationsB = data;
        }
      });
  }

  onQuotationBChange() {
    this.sourceB = "";
    this.selectedQuotationFileB = "";
    this.apiService
      .getAllQuotationFiles(this.selectedQuotationB)
      .subscribe((data: any) => {
        this.quotationFilesB = [];
        this.sourceB = "";
        if (data.length > 0) {
          for (var i = 0; i < data.length; i++){
            if (data[i]._id === this.selectedQuotationFileA){
              data.splice(i,1);
            }
          }
          this.quotationFilesB = data;
        }
      });
  }

  onQuotationFileBChange() {

    this.onFileBChange();
    this.apiService
      .getAQuotationFile(this.selectedQuotationB, this.selectedQuotationFileB)
      .subscribe((data: any) => {
        this.filesB = data.file;
      });
  }

  onFileAChange() {
    this.sourceA = this.selectedFileA + `?token=${this.userToken.getToken()}`;
  }

  onFileBChange() {
    this.sourceB = this.selectedFileB + `?token=${this.userToken.getToken()}`;
  }
}
