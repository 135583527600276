import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { UserLoginService } from 'src/app/shared/userlogin.service';

@Component({
    selector: 'approve',
    templateUrl: 'approve.component.html',
    styleUrls: ['approve.component.css']
  })
  export class ApproveComponent {

    leave_requests: Array<object> = [];
    currentUserID = this.userLoginService.getCurrentUserID();
    countLeaveRequest: number = 0;
  
    constructor(public dialogRef: MatDialogRef<ApproveComponent>, 
                @Inject(MAT_DIALOG_DATA) public object: any, 
                private apiService: ApiService, 
                private snackBar: MatSnackBar,
                private userLoginService: UserLoginService) { }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
   
    ngOnInit() {
    
    }
  
    approveAllLeaveRequests() {
      if(this.object.leave_requests.length > 0) {
        for(let i=0; i< this.object.leave_requests.length; i++) {
          this.apiService.approveLeaveRequest(this.object.leave_requests[i]._id).subscribe((response) => {
            console.log(response);
            if(i == this.object.leave_requests.length - 1) {
              this.snackBar.open('Approved successfully', null, {duration: 2000, horizontalPosition: 'right'});
            }
          });
        }
      }
      this.apiService.getLeaveNotification(this.currentUserID).subscribe((response: any) => {
        this.leave_requests = response.leave_requests;
      });
    }
  }