import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { DocumentService } from 'src/app/shared/document.service';

@Component({
    selector: 'delete-dir',
    templateUrl: 'delete-dir.component.html',
    styleUrls: ['delete-dir.component.css']
  })
  export class DeleteDirectoryComponent {
    child_directories: any = [];
    parentID: string = '';
  
    constructor(public dialogRef: MatDialogRef<DeleteDirectoryComponent>, 
                @Inject(MAT_DIALOG_DATA) public dir: any, 
                private apiService: ApiService, 
                private documentService: DocumentService,
                private snackBar: MatSnackBar) { 
                  dialogRef.disableClose = true;
                }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
   
    ngOnInit() {
      this.documentService.getDirectories.subscribe(child_directories => this.child_directories = child_directories);
      this.documentService.getParentID.subscribe(parentID => this.parentID = parentID);
    }
  
    handleDeleteDirectory() {
      this.apiService.deleteDirectory(this.dir.dir_id).subscribe((response: any) => {
        if (response.status == -1) {
          alert(response.message);
        } else {
          this.apiService.getSubItems(this.parentID).subscribe((data: any) => {
            this.documentService.bindSubDir(data.directories);
            this.documentService.directoriesChangeStatus = true;
            
            this.apiService.getDirectories().subscribe((data: any) => {
              if (data.status == 1 && data.directories.length > 0) {
                this.documentService.updateDirBlock(data.directories);
              }
            });
          });
        }
        this.snackBar.open('Deleted successfully', null, {duration: 2000, horizontalPosition: 'right'});
      });
    }
  }