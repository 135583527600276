import { Component, OnInit } from '@angular/core';
import { StaticM } from '../../../../models/staticM.model';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLoginService } from '../../../../shared/userlogin.service';
import { Location, DatePipe } from '@angular/common';
import { Sale } from 'src/app/models/salse.model';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-table-detail',
  templateUrl: './table-detail.component.html',
  styleUrls: ['./table-detail.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ]
})
export class TableDetailComponent implements OnInit {
  today = new Date();
  minDate = new Date(2017, 1, 1);
  maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());

  page_increasement = 0;
  lastPage_check = false;
  currentPage = 1;
  isLoading = true;

  username: string = '';

  id: string = "";
  name: string = "";
  list_detail: Array<any> = [];
  saleRequest = new Sale();

  constructor(private rout: Router,
    private userLoginService: UserLoginService,
    private activateRoute: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private route: Router,
    private location: Location) {

    this.activateRoute.params.subscribe(params => {
      this.id = params.id;
      this.name = params.name;
    });
  }

  ngOnInit() {
    this.saleRequest.page = 1;
    this.saleRequest.count = 10;
    this.saleRequest.from = this.userLoginService.getStartDate();
    this.saleRequest.to = this.userLoginService.getEndDate();

    if (this.saleRequest.from == null) {
      this.saleRequest.from = this.getDefaultStartDate();
    }

    if (this.saleRequest.to == null) {
      this.saleRequest.to = this.getDefaultEndDate();
    }

    this.loadListType();
  }

  getDefaultStartDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-01';
  }

  getDefaultEndDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let dd: any = this.daysInMonth(mm, yyyy);

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-' + dd;
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  //previous button click
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.saleRequest.page = this.currentPage;
    this.loadListType();
  }

  //next button click
  nextClick() {
    this.currentPage = this.currentPage + 1;
    this.saleRequest.page = this.currentPage;
    this.loadListType();
  }

  // start date selector
  onValueStartChange(date: any): void {
    this.saleRequest.from = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.saleRequest.page = 1;
    this.currentPage = 1;

    this.userLoginService.setStartDate(this.datePipe.transform(date.value, 'yyyy-MM-dd'));

    this.loadListType();
  }

  // end date selector
  onValueEndChange(date: any): void {
    this.saleRequest.to = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.saleRequest.page = 1;
    this.currentPage = 1;
    this.userLoginService.setEndDate(this.datePipe.transform(date.value, 'yyyy-MM-dd'));

    this.loadListType();
  }

  // list of userLoginService
  loadListType() {
    this.isLoading = true;
    console.log(this.id);
    this.userLoginService.getDetailIncomes(this.saleRequest, this.id).subscribe((Response: any) => {

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

      if (Response['status'] == 401) {
        this.route.navigateByUrl('/');
      }

      this.list_detail = Response.sale_by_date; 
      this.page_increasement = (this.currentPage - 1) * this.saleRequest.count;
    });

    this.saleRequest.export = false;
  }

  onSelectedChange(event: any) {
    this.saleRequest.count = event.target.value;
    this.saleRequest.page = 1;
    this.currentPage = 1;
    this.loadListType();
  }
}
