import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component, OnInit, Injectable, Input } from '@angular/core';
import { UserInfo } from '../user.component';
import { UserService } from 'src/app/shared/user.service';

@Component({
    selector: 'create-user',
    templateUrl: 'create-user.component.html',
    styleUrls: ['create-user.component.css']
  })
  export class CreateUserComponent implements OnInit{
  
    roles: Array<object> = [];
    positions: Array<object> = [];
    offices: Array<object> = [];
    hide = true;
  
    // ===== Form Validation ======================================================
    nameFormControl: FormControl = new FormControl('', [
      Validators.required,
      Validators.pattern(/^\S*$/)
    ]);
    firstNameFormControl = new FormControl('', [Validators.required]);
    lastNameFormControl = new FormControl('', [Validators.required]);
    emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email
    ]);
    passwordFormControl: FormControl = new FormControl('', [
      Validators.required, 
      Validators.minLength(6)
    ]);
    phoneFormControl = new FormControl('', [
      Validators.required
    ]);
    addressFormControl = new FormControl('', [Validators.required]);
    roleFormControl = new FormControl('', [Validators.required]);
    positionFormControl = new FormControl('', [Validators.required]);
    officeFormControl = new FormControl('', [Validators.required]);
  
    userFormGroup: FormGroup = new FormGroup({
      name: this.nameFormControl,
      firstname: this.firstNameFormControl,
      lastname: this.lastNameFormControl,
      email: this.emailFormControl,
      password: this.passwordFormControl,
      phone: this.phoneFormControl,
      address: this.addressFormControl,
      role: this.roleFormControl,
      position: this.positionFormControl,
      office: this.officeFormControl
    });
  
    getNameErrorMessage() {
      return this.nameFormControl.hasError('required') ? 'You must enter a value' :
        this.nameFormControl.hasError('pattern') ? 'Format must not allow space' : '';
    }
    getEmailErrorMessage() {
      return this.emailFormControl.hasError('required') ? 'You must enter a value' :
        this.emailFormControl.hasError('email') ? 'Not a valid email' : '';
    }
    getPhoneErrorMessage() {
      return this.phoneFormControl.hasError('required') ? 'You must enter a value' :
        this.phoneFormControl.hasError('pattern') ? 'Format must be (xxx) xxx-xxxx' : '';
    }
    getPasswordErrorMessage() {
      return this.passwordFormControl.hasError('required') ? 'You must enter a value' : 
        this.passwordFormControl.hasError('minlength') ? 'Password must be at least 6 characters long' : '';
    }
    getRequiredErrorMessage(field) {
      return this.userFormGroup.get(field).hasError('required') ? 'You must enter a value' : '';
    }
    // ===========================================================================
  
    constructor(public dialogRef: MatDialogRef<CreateUserComponent>, 
                @Inject(MAT_DIALOG_DATA) public user: UserInfo, 
                private apiService: ApiService,  
                private userService: UserService,
                private snackBar: MatSnackBar) {
                  dialogRef.disableClose = true;
                }
  
    onNoClick(): void {
      this.dialogRef.close();
    }

    ngOnInit() {
      this.getAllRoles();
      this.getAllPositions();
      this.getAllOffices();
    }
  
    getAllRoles() {
      this.apiService.getAllRoles().subscribe((data: any) => {
        if (data.status == 1 && data.roles.length > 0) {
          this.roles = [];
          this.roles = data.roles;
          this.user.role = data.roles[0]._id;
        }
      });
    }

    getAllPositions() {
      this.apiService.getAllPositions().subscribe((data: any) => {
        if (data.status == 1) {
          this.positions = [];
          this.positions = data.positions;
          this.user.position = data.positions[0]._id;
        }
      });
    }

    getAllOffices() {
      this.apiService.getAllOffices().subscribe((data: any) => {
        if (data.status == 1) {
          this.offices = [];
          this.offices = data.offices;
          this.user.office = data.offices[0]._id;
        }
      });
    }
  
    handleCreateUserInfo() {
      if(this.userFormGroup.valid) {
        this.apiService.createUser(this.user).subscribe((response: any) => {
          if(response.status == 1) {
            this.apiService.getAllUsers().subscribe((data: any) => {
              if (data.status == 1 && data.users.length > 0) {
                this.userService.bindUsers(data.users);
              }
            });
            this.dialogRef.close();
            this.snackBar.open('Created successfully', null, {duration: 2000, horizontalPosition: 'right'});
          } else {
            this.snackBar.open('Create failed!', null, {duration: 2000, horizontalPosition: 'right'});
          }
        });
      }
    }
}








 