import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';


@Pipe({
  name: 'dateRange'
})
export class DateRangePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    const pipe = new DatePipe('en-US');

    if (Array.isArray(value)) {
      if ((value as string[]).length === 2) {
        const [startDate, endDate] = value;
        if (startDate && endDate) {

          const start = {
            day: pipe.transform(startDate, 'dd'),
            month: pipe.transform(startDate, 'MMMM'),
            year: pipe.transform(startDate, 'yyyy')
          };


          const end = {
            day: pipe.transform(endDate, 'dd'),
            month: pipe.transform(endDate, 'MMMM'),
            year: pipe.transform(endDate, 'yyyy')
          };


          if (start.year === end.year) {
            if (start.month === end.month) {
              if (start.day === end.day) {
                return `${start.day} ${start.month} ${start.year}`;
              }
              return `${start.day} - ${end.day} ${start.month} ${start.year}`;
            } else {
              return `${start.day} ${start.month} - ${end.day} ${start.month} ${start.year}`;
            }
          } else {
            return `${start.day} ${start.month} ${start.year} - ${end.day} ${end.month} ${end.year}`;
          }
        }

        if (startDate) {
          return pipe.transform(startDate, 'dd MMMM yyyy');
        }

      }
    } else {
      return value;
    }

    return '(Not specified)';
  }
}
