import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component, ViewChild, ElementRef } from '@angular/core';
// import { DirInfo } from '../top-url.component';
import { DocumentService } from 'src/app/shared/document.service';
import { filter, map, pairwise } from 'rxjs/operators';
import { ContentScrollService } from 'src/app/shared/content-scroll.service';

@Component({
  selector: 'add-member',
  templateUrl: 'add-member.component.html',
  styleUrls: ['add-member.component.scss']
})
export class AddMemberComponent {

  scrollCallback;
  groups: Array<object> = [];
  addMemberFormGroup: FormGroup;
  selectedGroup: string = '';
  groupMembers: Array<object> = [];
  groupMembersAfterFiltered: Array<object> = [];
  selectedNewMembers: Array<object> = [];
  isLoading: boolean = false;
  temporaryNewMemberIDs: Array<string> = [];
  isStartNewPage: boolean = false;
  // leaveRequestsLoading = true;

  private currentPage = 1;
  private limit = 10;
  private isGotAllContact: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private apiService: ApiService,
    private documentService: DocumentService,
    private scroll: ContentScrollService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar) {
    dialogRef.disableClose = true;

    this.addMemberFormGroup = this.formBuilder.group({
      group_id: ['',],
      selectedNewMembers: this.formBuilder.array([])
    });

    this.groupMembers = dialogData.firstPageContacts;
    this.temporaryNewMemberIDs = dialogData.temporaryNewMemberIDs;
    this.groupMembersAfterFiltered = this.groupMembers.filter((item: any) => this.temporaryNewMemberIDs.indexOf(item._id) < 0);
    
    this.scrollCallback = this.paginateContacts.bind(this);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.selectedGroup = '';
    this.getAllGroups();
  }

  getContactNextPageAuto(limit: number, currentPage: number) {
    this.apiService.getAllContacts(limit, currentPage).subscribe((data: any) => {
      if(data.length != 0) {
        this.groupMembers = data;
        this.temporaryNewMemberIDs = this.dialogData.temporaryNewMemberIDs;
        this.groupMembersAfterFiltered.push(...this.groupMembers.filter((item: any) => this.temporaryNewMemberIDs.indexOf(item._id) < 0));
      }
      
      if(this.groupMembersAfterFiltered.length < 9 || data.length == 0) {
        this.getContactNextPageAuto(limit, currentPage + 1);
      } else {
        this.currentPage = currentPage + 1;
        this.isStartNewPage = true;
      }
    });
  }

  paginateContacts() {
    if(!this.isGotAllContact) {
      return this.apiService.getAllContacts(this.limit, this.currentPage).do(this.processData);
    } 
    else {
      return this.apiService.getAllContacts(this.limit, this.currentPage);
    } 
  }

  private processData = (data) => {
    if(data.length == 0) this.isGotAllContact = true;
    if(this.currentPage > 1) {
      this.groupMembers = data.filter((item: any) => this.temporaryNewMemberIDs.indexOf(item._id) < 0);
      this.groupMembersAfterFiltered.push(...this.groupMembers);
    }
    this.currentPage++;
  }

  getAllGroups() {
    this.apiService.getAllGroups().subscribe((data: any) => {
      this.groups = data;

      this.addMemberFormGroup.patchValue({
        group_ids: '',
      });
    });
  }

  refreshListContacts() {
    if (this.selectedGroup !== '') {
      this.apiService.getGroupById(this.selectedGroup).subscribe((data: any) => {
        this.groupMembers = data.contacts;
        this.groupMembersAfterFiltered = this.groupMembers.filter((item: any) => this.temporaryNewMemberIDs.indexOf(item._id) < 0);
      });
    } else {
      this.isGotAllContact = false;
      this.currentPage = 1;
      this.groupMembers = this.dialogData.firstPageContacts;
      this.groupMembersAfterFiltered = this.groupMembers.filter((item: any) => this.temporaryNewMemberIDs.indexOf(item._id) < 0);
      if(this.groupMembersAfterFiltered.length < 9) this.getContactNextPageAuto(this.limit, this.currentPage + 1);
    }
  }

  getAllContacts(limit: number = 10, currentPage = 1) {
    this.isLoading = true;
    this.apiService.getAllContacts(limit, currentPage).subscribe((data: any) => {
      this.groupMembers = data;

      this.groupMembersAfterFiltered = this.groupMembers.filter((item: any) => this.temporaryNewMemberIDs.indexOf(item._id) < 0);
      this.isLoading = false;
    });
  }

  @ViewChild('ref') ref;
  onCheckBoxChange(selectedNewMemberID: string, event) {
    const selectedMemberFormArray = <FormArray>this.addMemberFormGroup.controls.selectedNewMembers;

    if (event._checked) {
      selectedMemberFormArray.push(new FormControl(selectedNewMemberID));
    } else {
      let index = selectedMemberFormArray.controls.findIndex(x => x.value == selectedNewMemberID);
      selectedMemberFormArray.removeAt(index);
    }

    this.selectedNewMembers = this.addMemberFormGroup.value.selectedNewMembers;
  }
}