import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { CompanyService } from '../company.service';

@Component({
  selector: 'app-delete-company-dialog',
  templateUrl: './delete-company-dialog.component.html',
  styleUrls: ['./delete-company-dialog.component.scss']
})
export class DeleteCompanyDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteCompanyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private companyService: CompanyService,
    private snackBar: MatSnackBar) { 
      dialogRef.disableClose = true;
    }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleDeleteCompany() {
    this.apiService.deleteCompany(this.object.id).subscribe(
      (response: any) => {
        this.apiService.getAllCompanies().subscribe((data: any) => {
          this.companyService.bindCompanies(data);
        });
        this.snackBar.open("Deleted successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Delete failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }

}
