import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { ProjectFileService } from "../project-file.service";

@Component({
  selector: "app-edit-project-file-dialog",
  templateUrl: "./edit-project-file-dialog.component.html",
  styleUrls: ["./edit-project-file-dialog.component.scss"]
})
export class EditProjectFileDialogComponent implements OnInit {
  selectedProjectFile: any;
  files: any = [];
  fileName = "";
  oldFileArray: any;
  deleteFileList: any;
  displayFiles: any = [];
  remainFiles: any;
  tmpFiles: any;

  constructor(
    public dialogRef: MatDialogRef<EditProjectFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private projectFileService: ProjectFileService,
    private snackBar: MatSnackBar
  ) { 
    dialogRef.disableClose = true;
  }

  titleFormControl: FormControl = new FormControl("", [Validators.required]);
  descriptionFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  fileNameFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);

  fileFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);

  projectFileFormGroup: FormGroup = new FormGroup({
    title: this.titleFormControl,
    description: this.descriptionFormControl,
    file_name: this.fileNameFormControl,
    file: this.fileFormControl
  });

  getRequiredErrorMessage(field) {
    return this.projectFileFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  ngOnInit() {
    this.projectFileService.getSelectedProjectFile.subscribe(
      selectedProjectFile => {
        this.selectedProjectFile = selectedProjectFile;
      }
    );

    this.oldFileArray = [];
    if (this.object.projectFile.files.length != 0) {
      this.oldFileArray = this.getFileNameFromLink(this.object.projectFile.files);
    }

    this.displayFiles = [];
    this.displayFiles = this.object.projectFile.files;
    console.log("display file: ");
    console.log(this.object.projectFile);
    this.remainFiles = [];
    this.deleteFileList = [];
    for (let i = 0; i < this.oldFileArray.length; i++) {
      this.remainFiles.push(this.oldFileArray[i]);
    }
  }

  fileChanged(fileEvent: Event) {
    const target = fileEvent.target as HTMLInputElement;

    this.fileName = target.files[0].name;
    let changedFiles: any = Array.from(target.files);
    
    for (let i = 0; i < changedFiles.length; i++) {
      this.displayFiles.push(changedFiles[i]);
      this.files.push(changedFiles[i]);
    }
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.projectFileFormGroup.invalid) {
        this.dialogRef.close();
      }
    }
  }

  getFileNameFromLink(fileArray: any) {
    let tmpArray = [];
    fileArray.forEach((file) => {
      tmpArray.push(file);
    });

    return tmpArray;
  }

  onDelete(file: any) {
    console.log(file);
    var index = this.displayFiles.indexOf(file);
    if (index > -1) {
      this.displayFiles.splice(index, 1);
    }
    var indexForOld = this.remainFiles.indexOf(file);
    if (indexForOld > -1) {
      this.deleteFileList.push(this.remainFiles[indexForOld]);
      this.remainFiles.splice(indexForOld, 1);
    }
    if (this.files !== undefined && this.files.length > 0) {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].name === file.name) {
          this.files.splice(i, 1);
        }
      }
      if (this.files.length <= 0) {
        this.fileName = "";
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.apiService
      .getAllProjectFiles(this.object.project._id)
      .subscribe((data: any) => {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (this.selectedProjectFile._id === data[i]._id) {
              this.selectedProjectFile.title = data[i].title;
              this.selectedProjectFile.description = data[i].description;
              this.selectedProjectFile.project = data[i].project;
              this.selectedProjectFile.files = data[i].files;
              this.selectedProjectFile.createdAt = data[i].createdAt;
              this.selectedProjectFile.updatedAt = data[i].updatedAt;
            }
          }
        }
      });
  }

  handleEditProjectFile() {
    let newProjectFileFormData = new FormData();
    newProjectFileFormData.append(
      "project_id",
      this.object.projectFile.project._id
    );
    newProjectFileFormData.append("title", this.object.projectFile.title);
    newProjectFileFormData.append(
      "description",
      this.object.projectFile.description
    );
    if (this.files !== undefined) {
      for (let i = 0; i < this.files.length; i++) {
        newProjectFileFormData.append("file", this.files[i]);
      }
    }
    console.log("delete file: ");
    console.log(this.deleteFileList);
    if (this.deleteFileList !== undefined) {
      let deletedFile: any;
      let emptyDeletedFile: any;
      for (let i = 0; i < this.deleteFileList.length; i++) {
        deletedFile = {
          _id: this.deleteFileList[i]._id,
          name: this.deleteFileList[i].name
        }
        newProjectFileFormData.append("delete_list", JSON.stringify(deletedFile));
      }

      emptyDeletedFile = {_id: null};
      if(this.deleteFileList.length == 1) {
        newProjectFileFormData.append("delete_list", JSON.stringify(emptyDeletedFile));
      } else if(this.deleteFileList.length == 0) {
        newProjectFileFormData.append("delete_list", JSON.stringify(emptyDeletedFile));
        newProjectFileFormData.append("delete_list", JSON.stringify(emptyDeletedFile));
      }
    }
    console.log("remaining file: ");
    console.log(this.remainFiles);
    if (this.remainFiles !== undefined) {
      let remainingFile: any;
      let emptyRemainingFile: any;
      for (let i = 0; i < this.remainFiles.length; i++) {
        remainingFile = {
          _id: this.remainFiles[i]._id,
          name: this.remainFiles[i].name
        } 
        newProjectFileFormData.append("remain_list", JSON.stringify(remainingFile));
      }

      emptyRemainingFile = {_id: null};
      if(this.remainFiles.length == 1) {
        newProjectFileFormData.append("remain_list", JSON.stringify(emptyRemainingFile));
      } else if(this.remainFiles.length == 0) {
        newProjectFileFormData.append("remain_list", JSON.stringify(emptyRemainingFile));
        newProjectFileFormData.append("remain_list", JSON.stringify(emptyRemainingFile));
      }
    }


    this.apiService
      .editProjectFile(this.object.projectFile._id, newProjectFileFormData)
      .subscribe(
        response => {
          this.snackBar.open("Updated successfully", null, {
            duration: 2000,
            horizontalPosition: "right"
          });
          this.apiService
            .getAllProjectFiles(this.object.projectFile.project._id)
            .subscribe((data: any) => {
              if (data.length > 0) {
                this.projectFileService.bindProjectFiles(data);
              }
            });
        },
        error => {
          this.snackBar.open("Update failed!", null, {
            duration: 2000,
            panelClass: ["red-snackbar"],
            horizontalPosition: "right"
          });
        }
      );
  }
}
