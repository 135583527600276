import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/shared/api.service";
import { CompanyService } from "./company.service";
import { CompanyPagination } from "src/app/models/company.model";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { CreateCompanyDialogComponent } from "./create-company-dialog/create-company-dialog.component";
import { EditCompanyDialogComponent } from "./edit-company-dialog/edit-company-dialog.component";
import { DeleteCompanyDialogComponent } from "./delete-company-dialog/delete-company-dialog.component";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styleUrls: ["./company.component.scss"]
})
export class CompanyComponent implements OnInit {
  companies: any;
  isLoading = true;
  page_increasement = 0;
  searchKeyword = '';
  companyPagination = new CompanyPagination();

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private companyService: CompanyService,
    private route: Router
  ) { }

  ngOnInit() {
    this.getAllCompanies();
    this.companyService.getAllCompanies.subscribe(
      companies => (this.companies = companies)
    );
  }

  getAllCompanies() {
    this.apiService.getAllCompanies().subscribe(
      (data: any) => {

        setTimeout(() => {
          this.isLoading = false;
        }, 500);

        this.companies = [];
        if (data.length > 0) {
          this.companies = data;
          console.log(this.companies);

        }
      },
      (error) => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    );
  }

  openCreateCompanyDialog(): void {
    const dialogRef = this.dialog.open(CreateCompanyDialogComponent, {
      width: "400px",
      height: "auto",
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  openEditCompanyDialog(company: any) {
    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      width: "400px",
      height: "auto",
      data: {
        company: company
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  openDeleteCompanyDialog(companyID: string) {
    const dialogRef = this.dialog.open(DeleteCompanyDialogComponent, {
      width: "400px",
      height: "auto",
      data: {
        id: companyID
      }
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  handleSearch() {
    this.loadList();
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.handleSearch();
    }
  }

  onSearchChange(event) {
    if (event.target.value === '') {
      this.handleSearch();
    }
  }

  // count selector
  selectCountHandler(event: any) {
    this.companyPagination.count = event.target.value;

    this.loadList();
  }

  currentPage = 1;
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.companyPagination.page = this.currentPage;
    this.loadList();
  }

  nextClick() {
    this.currentPage = this.currentPage + 1;
    this.companyPagination.page = this.currentPage;
    this.loadList();
  }

  loadList() {
    this.apiService
      .getListCompanies(this.companyPagination, this.searchKeyword)
      .subscribe(Response => {
        this.page_increasement =
          (this.currentPage - 1) * this.companyPagination.count;
        if (Response["status"] == 401) {
          this.route.navigateByUrl("/");
        } else {
          this.companies = Response;
        }
      });
    this.companyPagination.export = false;
  }
}

export interface CompanyInfo {
  company_name: string;
  address: string;
  website: string;
  VATIN: string;
}
