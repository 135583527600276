export class ProjectFilePagination {
    count: number;
    export: boolean;
    page: number;
    constructor() {
        this.count = 10;
        this.export = false;
        this.page = 1;
    }
}
