import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { UserLoginService } from "src/app/shared/userlogin.service";
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";

@Component({
  selector: "app-menu-left",
  templateUrl: "./menu-left.component.html",
  styleUrls: ["./menu-left.component.scss"],
  animations: [
    trigger("subItem", [
      state("void", style({ opacity: 0, transform: "translateX(-50px)" })),
      transition("void => *", animate(".2s ease"))
    ])
  ]
})
export class MenuLeftComponent implements OnInit {
  @Input("hideText") public hideText = false;
  @Input("phone") public phone = false;
  @Input("hideMenuBar") public hideMenuBar = false;
  @Output() valueChange = new EventEmitter();

  adminRoleName = "Admin";
  accountantRoleName = "Accountant";
  staffRoleName = "Staff";
  managerRoleName = "Manager";
  CTORoleName = "CTO";
  CEORoleName = "CEO";

  currentUserRoleName: string;

  // All routes
  private menuItems: MenuItem[] = [
    {
      title: "របាយការណ៍ហិរញ្ញវត្ថុ",
      routerLink: "dashboard/financial-report",
      icon: "fas fa-file-invoice-dollar",
      roles: [
        this.adminRoleName,
        this.CEORoleName,
        this.CTORoleName
      ]
    },
    {
      title: "របាយការណ៍",
      icon: "fas fa-file-invoice-dollar",
      roles: [
        this.adminRoleName,
        this.CEORoleName,
        this.CTORoleName,
        this.accountantRoleName,
        this.managerRoleName
      ],
      exact: true,
      expanded: true,
      children: [
        {
          title: "ចំណូល",
          routerLink: "dashboard/income",
          icon: "fa fa-hand-holding-usd",
          roles: [
            this.adminRoleName,
            this.CEORoleName,
            this.CTORoleName,
            this.accountantRoleName,
            this.managerRoleName
          ]
        },
        {
          title: "ចំណាយ",
          routerLink: "dashboard/outcome",
          icon: "fa fa-file-invoice-dollar",
          roles: [
            this.adminRoleName,
            this.CEORoleName,
            this.CTORoleName,
            this.accountantRoleName,
            this.managerRoleName
          ]
        }
      ]
    },
    {
      title: "ឯកសារ",
      routerLink: "dashboard/documents",
      icon: "fas fa-file-pdf",
      roles: [
        this.adminRoleName,
        this.CEORoleName,
        this.CTORoleName,
        this.accountantRoleName
      ]
    },
    {
      title: "សម្រង់តម្លៃ",
      icon: "fas fa-file-invoice-dollar",
      roles: [
        this.adminRoleName,
        this.CEORoleName,
        this.managerRoleName
      ],
      exact: true,
      expanded: true,
      children: [
        {
          title: "ក្រុមហ៊ុន",
          routerLink: "dashboard/companies",
          icon: "fa fa-building",
          roles: [
            this.adminRoleName,
            this.CEORoleName,
            this.managerRoleName
          ]
        },
        {
          title: "គម្រោង",
          routerLink: "dashboard/projects",
          icon: "fa fa-project-diagram",
          roles: [
            this.adminRoleName,
            this.CEORoleName,
            this.managerRoleName
          ]
        },
        // {
        //   title: "អ្នកផ្គត់ផ្គង់",
        //   routerLink: "dashboard/suppliers",
        //   icon: "fa fa-user",
        //   roles: [
        //     this.adminRoleName,
        //     this.CEORoleName,
        //     this.CTORoleName,
        //     this.accountantRoleName,
        //     this.managerRoleName
        //   ]
        // },
        {
          title: "ព័ត៌មានសម្រង់តម្លៃ",
          routerLink: "dashboard/quotations",
          icon: "fa fa-info-circle",
          roles: [
            this.adminRoleName,
            this.CEORoleName,
            this.managerRoleName
          ]
        },
        {
          title: "ប្រៀបធៀបព័ត៌មានសម្រង់តម្លៃ",
          routerLink: "dashboard/compare_quotations",
          icon: "fa fa-question-circle",
          roles: [
            this.adminRoleName,
            this.CEORoleName,
            this.managerRoleName
          ]
        }
      ]
    },
    // {
    //   title: 'ការិយាល័យ',
    //   routerLink: 'dashboard/offices',
    //   icon: 'fas fa-building'
    // },
    {
      title: "ថ្ងៃសម្រាក​​សាធារណៈ",
      routerLink: "dashboard/public-holidays",
      icon: "fas fa-calendar-alt",
      roles: [
        this.adminRoleName,
        this.CEORoleName,
        this.CTORoleName,
        this.accountantRoleName,
        this.managerRoleName,
        this.staffRoleName
      ]
    },
    {
      title: "វត្តមាន",
      icon: "fas fa-calendar-check",
      roles: [
        this.adminRoleName,
        this.CEORoleName,
        this.CTORoleName,
        this.accountantRoleName,
        this.managerRoleName,
        this.staffRoleName
      ],
      exact: true,
      expanded: true,
      children: [
        {
          title: "វត្តមាន",
          routerLink: "dashboard/attendances",
          icon: "fas fa-calendar-check",
          roles: [
            this.adminRoleName,
            this.CEORoleName,
            this.CTORoleName,
            this.accountantRoleName,
            this.managerRoleName,
            this.staffRoleName
          ]
        },
        {
          title: "របាយការណ៍វត្តមាន",
          routerLink: "dashboard/attendances-report",
          icon: "fas fa-calendar-check",
          roles: [
            this.adminRoleName,
            this.CEORoleName,
            this.CTORoleName,
            this.accountantRoleName,
            this.managerRoleName
          ]
        }
      ]
    },
    {
      title: "អ្នកប្រើប្រាស់",
      routerLink: "dashboard/users",
      icon: "fa fa-user",
      roles: [this.adminRoleName]
    },
    {
      title: "តួនាទី",
      routerLink: "dashboard/positions",
      icon: "far fa-address-card",
      roles: [
        this.adminRoleName,
        // this.CEORoleName,
        // this.CTORoleName,
        // this.accountantRoleName,
        // this.managerRoleName
      ]
    },
    {
      title: "ទំនាក់ទំនង",
      routerLink: "dashboard/contact",
      icon: "fas fa-address-book",
      roles: [
        this.adminRoleName,
        this.CEORoleName,
        this.CTORoleName,
        this.managerRoleName
      ]
    }
  ];

  // Array object that we use.
  displayMenuItems: MenuItem[] = [];

  constructor(private userLoginService: UserLoginService) { }

  ngOnInit() {
    this.currentUserRoleName = this.userLoginService.getCurrentUserRoleName();
    this.displayMenuItems = this.traverseMenuItems(this.menuItems);
  }

  /**
   * Expand when click on item which has children
   * @param item
   */
  menuClick(item: MenuItem) {
    if (!item.routerLink) {
      item.expanded = !item.expanded;
    }
  }

  hideNavBar() {
    this.hideMenuBar = true;
    this.valueChange.emit(this.hideMenuBar);
  }

  /**
   * Filter menu to be displayed
   * @param menuItems
   */
  private traverseMenuItems(menuItems: MenuItem[]) {
    return menuItems.filter(item => {
      if (item.children) {
        item.children = this.traverseMenuItems(item.children);
      }

      if (!item.roles) {
        return true;
      }

      if (!this.currentUserRoleName) {
        return true;
      }

      return item.roles.includes(this.currentUserRoleName);
    });
  }
}

export interface MenuItem {
  title: string; // Title to be displayed
  exact?: boolean; // Highlight when active or not
  routerLink?: string; // Link to redirect when click
  children?: MenuItem[]; // Children of the menu
  roles?: string[]; // Allowed roles for the menu
  icon?: string; // Icon to be displayed
  expanded?: boolean; // Show children or not
}
