import { Component, OnInit } from '@angular/core';
import { Sale } from '../../../../models/salse.model';
import { UserLoginService } from '../../../../shared/userlogin.service';
import * as dayjs from 'dayjs'
import { Router, RoutesRecognized } from '@angular/router';
import { NgxUiLoaderDemoService } from 'src/app/shared/ngx-ui-loader.service';

import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter, MatTabChangeEvent } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatePipe } from '@angular/common';
import { ExcelService } from 'src/app/shared/excel.service';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ],
})
export class IncomeComponent implements OnInit {


  TAB_INDEX_DEPOSIT = 5;
  TAB_INDEX_TOP_UP = 6;

  today = new Date();
  minDate = new Date(2017, 1, 1);
  maxDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  bsValue = new Date();
  selected = new Sale();
  selectedDetail = new Sale();
  selectedPoint = new Sale();

  listType: Array<any> = [];
  listDetailIncome: Array<any> = [];
  listPoint: Array<any> = [];
  listDailyTopup: Array<any> = [];
  listDailyDeposit: Array<any> = [];
  selectedType;
  isLoading = true;
  isCheckedSummary: boolean = true;
  isCheckedPoint: boolean = false;
  isCheckedTopup: boolean = false;
  isCheckedDeposit: boolean = false;
  isCheckedDetail: boolean = false;

  page_increasement = 0;
  lastPage_check = false;
  currentPage = 1;

  page_increasement_detail = 0;
  lastPage_check_detail = false;
  currentPageDetail = 1;

  currentTabIndex = 0;

  page = 1;  // current page
  count = 20; // total items per page

  listDeposit = [];
  listTopup = [];

  constructor(private userLoginService: UserLoginService,
    private route: Router,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    public demoService: NgxUiLoaderDemoService) { }

  ngOnInit() {
    this.selected.page = 1;
    this.selected.count = 10;

    this.selected.from = this.getDefaultStartDate();
    this.selectedDetail.from = this.getDefaultStartDate();

    this.selected.to = this.getDefaultEndDate();
    this.selectedDetail.to = this.getDefaultEndDate();

    this.userLoginService.setStartDate(this.datePipe.transform(new Date(this.getDefaultStartDate()), 'yyyy-MM-dd'));
    this.userLoginService.setEndDate(this.datePipe.transform(new Date(this.getDefaultEndDate()), 'yyyy-MM-dd'));

    if (this.isCheckedSummary) this.loadListType();
    else this.loadDetailIncomes();
  }

  onTabChanged(event: MatTabChangeEvent) {
    this.page = 1;
    this.currentTabIndex = event.index;

    if (event.index == 0) {
      this.isCheckedSummary = true;
      this.isCheckedPoint = false;
      this.isCheckedDetail = false;
      this.isCheckedTopup = false;
      this.isCheckedDeposit = false;
      this.loadListType();
    } else if (event.index == 1) {
      this.isCheckedSummary = false;
      this.isCheckedPoint = false;
      this.isCheckedDetail = true;
      this.isCheckedTopup = false;
      this.isCheckedDeposit = false;
      this.loadDetailIncomes();
    } else if(event.index == 2) {
      this.isCheckedPoint = true;
      this.isCheckedSummary = false;
      this.isCheckedDetail = false;
      this.isCheckedTopup = false;
      this.isCheckedDeposit = false;
      this.loadPoint();
    } else if(event.index == 3) {
      this.isCheckedSummary = false;
      this.isCheckedPoint = false;
      this.isCheckedDetail = false;
      this.isCheckedTopup = true;
      this.isCheckedDeposit = false;
      this.loadDailyTopup();
    } else if(event.index == 4) {
      this.isCheckedSummary = false;
      this.isCheckedPoint = false;
      this.isCheckedDetail = false;
      this.isCheckedTopup = false;
      this.isCheckedDeposit = true;
      this.loadDailyDeposit();
    } else if(event.index == this.TAB_INDEX_DEPOSIT) {
      this.loadDeposit();
    } else if(event.index == this.TAB_INDEX_TOP_UP) {
      this.loadTopup();
    }
  }

  getDefaultStartDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-01';
  }

  getDefaultEndDate() {
    let today = new Date();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let dd: any = this.daysInMonth(mm, yyyy);

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-' + dd;
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  // userLoginService selector
  selectTypeHandler(event: any) {
    this.selected.type = event.target.value;
    this.selectedDetail.type = event.target.value;
    this.selected.page = 1;
    this.currentPage = 1;
    this.selectedDetail.page = 1;
    this.currentPageDetail = 1;

    if (this.isCheckedSummary) this.loadListType();
    else this.loadDetailIncomes();
  }

  // count selector
  selectCountHandler(event: any) {
    this.selected.count = event.target.value;
    this.selected.page = 1;
    this.currentPage = 1;
    this.loadListType();
  }

  // count selector
  selectCountHandlerDetail(event: any) {
    this.selectedDetail.count = event.target.value;
    this.selectedDetail.page = 1;
    this.currentPageDetail = 1;
    this.loadDetailIncomes();
  }

  // start date selector
  onValueStartChange(date: any): void {
    this.selected.from = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.selectedDetail.from = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.selectedPoint.from = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.selected.page = 1;
    this.currentPage = 1;
    this.selectedDetail.page = 1;
    this.currentPageDetail = 1;
    this.userLoginService.setStartDate(this.datePipe.transform(date.value, 'yyyy-MM-dd'));

    if (this.isCheckedSummary) this.loadListType();
    else if(this.isCheckedDetail) this.loadDetailIncomes();
    else if(this.isCheckedPoint) this.loadPoint();
    else if(this.isCheckedTopup) this.loadDailyTopup();
    else if(this.isCheckedDeposit) this.loadDailyDeposit();
  }

  // end date selector
  onValueEndChange(date: any): void {
    this.selected.to = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.selectedDetail.to = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.selectedPoint.to = this.datePipe.transform(date.value, 'yyyy-MM-dd');
    this.selected.page = 1;
    this.currentPage = 1;
    this.selectedDetail.page = 1;
    this.currentPageDetail = 1;
    this.userLoginService.setEndDate(this.datePipe.transform(date.value, 'yyyy-MM-dd'));

    if (this.isCheckedSummary) this.loadListType();
    else if(this.isCheckedDetail) this.loadDetailIncomes();
    else if(this.isCheckedPoint) this.loadPoint();
    else if(this.isCheckedTopup) this.loadDailyTopup();
    else if(this.isCheckedDeposit) this.loadDailyDeposit();
  }

  onDownloadClick() {
    this.selected.export = true;
    const url = this.userLoginService.getUrlLink(this.selected);
    window.open(url, "_blank");

    this.selected.export = false;
  }

  onDownloadClickDetail() {
    this.selectedDetail.export = true;
    const url = this.userLoginService.getUrlLinkDetail(this.selectedDetail);
    window.open(url, "_blank");

    this.selectedDetail.export = false;
  }

  onDownloadPointClick() {
    this.selectedPoint.export = true;
    const url = this.userLoginService.getUrlLinkPoint(this.selectedPoint);
    window.open(url, "_blank");

    this.selectedPoint.export = false;
  }

  onDownloadTopupClick() {
    this.selectedPoint.export = true;
    const url = this.userLoginService.getUrlLinkTopup(this.selectedPoint);
    window.open(url, "_blank");

    this.selectedPoint.export = false;
  }

  onDownloadDepositClick() {
    this.selectedPoint.export = true;
    const url = this.userLoginService.getUrlLinkDeposit(this.selectedPoint);
    window.open(url, "_blank");

    this.selectedPoint.export = false;
  }

  //previous button click
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.selected.page = this.currentPage;
    this.loadListType();
  }

  //next button click
  nextClick() {
    this.currentPage = this.currentPage + 1;
    this.selected.page = this.currentPage;
    this.loadListType();
  }

  //previous button click
  previousClickDetail() {
    this.currentPageDetail = this.currentPageDetail - 1;
    this.selectedDetail.page = this.currentPageDetail;
    this.loadDetailIncomes();
  }

  //next button click
  nextClickDetail() {
    this.currentPageDetail = this.currentPageDetail + 1;
    this.selectedDetail.page = this.currentPageDetail;
    this.loadDetailIncomes();
  }

  // list of userLoginService
  loadListType() {
    this.isLoading = true;
    this.userLoginService.getListType(this.selected).subscribe((Response: any) => {

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

      if (Response['status'] == 401) {
        this.route.navigateByUrl('/');
      }

      this.listType = Response.sale_by_tours;
      this.page_increasement = (this.currentPage - 1) * this.selected.count;

    });

    this.selected.export = false;
  }

  loadDetailIncomes() {
    this.isLoading = true;
    this.userLoginService.getDetailIncomes(this.selectedDetail).subscribe((Response: any) => {

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

      if (Response['status'] == 401) {
        this.route.navigateByUrl('/');
      }

      this.listDetailIncome = Response.sale_by_date;
      this.page_increasement_detail = (this.currentPageDetail - 1) * this.selectedDetail.count;
    });

    this.selectedDetail.export = false;
  }

  loadPoint() {
    this.isLoading = true;
    this.userLoginService.getPointList(this.selectedPoint).subscribe((Response: any) => {

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

      if (Response['status'] == 401) {
        this.route.navigateByUrl('/');
      }

      this.listPoint = Response.data;
    });

    this.selectedDetail.export = false;
  }

  loadDailyTopup() {
    this.isLoading = true;
    this.userLoginService.getListTopup(this.selected.from, this.selected.to).subscribe((Response: any) => {

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

      if (Response['status'] == 401) {
        this.route.navigateByUrl('/');
      }

      this.listDailyTopup = Response.daily_topup;
    });

    this.selectedDetail.export = false;
  }

  loadDailyDeposit() {
    this.isLoading = true;
    this.userLoginService.getListDeposit(this.selected.from, this.selected.to).subscribe((Response: any) => {

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

      if (Response['status'] == 401) {
        this.route.navigateByUrl('/');
      }

      this.listDailyDeposit = Response.daily_deposit;
    });

    this.selectedDetail.export = false;
  }

  getToday() {
    let today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (dd < 10) {
      dd = '0' + dd
    }

    if (mm < 10) {
      mm = '0' + mm
    }

    return yyyy + '-' + mm + '-' + dd;
  }

  

  loadDeposit() {
    this.isLoading = true;
    this.listDeposit = [];
    this.userLoginService.getDeposit(this.selected.from, this.selected.to, this.page).subscribe((Response: any) => {

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

      if (Response['status'] == 401) {
        this.route.navigateByUrl('/');
      }

      this.listDeposit = Response.deposits;
    });

    this.selectedDetail.export = false;
  }

  loadTopup() {
    this.isLoading = true;
    this.listDeposit = [];
    this.userLoginService.getTopup(this.selected.from, this.selected.to, this.page).subscribe((Response: any) => {

      setTimeout(() => {
        this.isLoading = false;
      }, 500);

      if (Response['status'] == 401) {
        this.route.navigateByUrl('/');
      }

      this.listTopup = Response.top_up;
    });

    this.selectedDetail.export = false;
  }

  nextPage() {
    this.page++;
    this.loadDataOnPageChange();
  }

  backPage() {
    if(this.page > 1) this.page--;
    this.loadDataOnPageChange();
  }

  loadDataOnPageChange() {
    if(this.currentTabIndex == this.TAB_INDEX_DEPOSIT) {
      this.loadDeposit();
    } else if(this.currentTabIndex == this.TAB_INDEX_TOP_UP) {
      this.loadTopup();
    }
  }

  onDownloadDepositHistoryClick() {
    const url = this.userLoginService.getURLDeposit(this.selected.from, this.selected.to);
    window.open(url, "_blank");
  }

  onDownloadTopupHistoryClick(){
    const url = this.userLoginService.getURLTopup(this.selected.from, this.selected.to);
    window.open(url, "_blank");
  }
}
