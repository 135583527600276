import { Component, OnInit } from "@angular/core";
import { QuotationPagination } from "src/app/models/quotation.model";
import { MatDialog, TooltipPosition } from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { QuotationService } from "./quotation.service";
import { Router } from "@angular/router";
import { CreateQuotationDialogComponent } from "./create-quotation-dialog/create-quotation-dialog.component";
import { DeleteQuotationDialogComponent } from "./delete-quotation-dialog/delete-quotation-dialog.component";
import { EditQuotationDialogComponent } from "./edit-quotation-dialog/edit-quotation-dialog.component";
import { CreateQuotationFileDialogComponent } from "./create-quotation-file-dialog/create-quotation-file-dialog.component";
import { QuotationFileDisplayDialogComponent } from "./quotation-file-display-dialog/quotation-file-display-dialog.component";

@Component({
  selector: "app-quotation",
  templateUrl: "./quotation.component.html",
  styleUrls: ["./quotation.component.scss"]
})
export class QuotationComponent implements OnInit {
  isLoading = true;
  quotations: any;
  quotationPagination = new QuotationPagination();
  page_increasement = 0;
  searchKeyword: string = "";
  issuedChecked = false;
  recievedChecked = false;
  issued = "issued";
  recieved = "recieved";
  type = "";
  selectedProjectID = "0";
  projects = [];
  dummyProject = {_id: "0", name: "All"};

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private quotationService: QuotationService,
    private route: Router
  ) {}

  ngOnInit() {
    this.getAllQuotations();
    this.quotationService.getAllQuotations.subscribe(
      quotations => (this.quotations = quotations)
    );
    this.getProjects();
  }

  getAllQuotations() {
    this.isLoading = true;
    this.apiService.getAllQuotations().subscribe(
      (data: any) => {

        setTimeout(() => {
          this.isLoading = false;
        }, 500);
        
        if (data.length > 0) {
          this.quotations = [];
          this.quotations = data;
          console.log(this.quotations);
          
        }
      },
      (error: any) => {
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      }
    );
  }

  getProjects(){
    this.apiService.getAllProjects().subscribe(
      (data: any) => {
        this.projects = [];
        if (data.length > 0){
          this.projects = data;
          this.projects.unshift(this.dummyProject);
        }
        
      }
    )
  }

  openCreateQuotationDialog(): void {
    const dialogRef = this.dialog.open(CreateQuotationDialogComponent, {
      width: "990px",
      height: "auto",
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  openDisplayFileDialog(quotation: any) {
    const dialogRef = this.dialog.open(QuotationFileDisplayDialogComponent, {
      width: "1600px",
      height: "auto",
      data: {
        quotation: quotation
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  openEditQuotationDialog(quotation: any): void {
    this.quotationService.bindSelectedQuotation(quotation);
    const dialogRef = this.dialog.open(EditQuotationDialogComponent, {
      width: "800px",
      height: "auto",
      data: {
        quotation: quotation
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  openDeleteQuotationDialog(quotationID: string): void {
    const dialogRef = this.dialog.open(DeleteQuotationDialogComponent, {
      width: "400px",
      height: "auto",
      data: {
        quotation_id: quotationID
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  openCreateQuotationFileDialog(quotationID: string) {
    const dialogRef = this.dialog.open(CreateQuotationFileDialogComponent, {
      width: "800px",
      height: "auto",
      data: {
        quotation_id: quotationID
      }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  handleSearch() {
    this.loadList();
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.handleSearch();
    }
  }

  onSearchChange(event) {
    if (event.target.value === "") {
      this.handleSearch();
    }
  }

  

  // filter section

  onProjectAChange(){
    this.loadList();
  }

  onIssuedCheckboxChange(event) {
    if (event.checked) {
      this.issuedChecked = true;
      if (this.recievedChecked) {
        // this.recievedChecked = false;
        this.type = "";
        this.loadList();
      }
      else{
        this.type = "issued";
        this.loadList();
      }
    } else {
      this.issuedChecked = false;
      if (this.recievedChecked) {
        // this.recievedChecked = false;
        this.type = "recieved";
        this.loadList();
      }
      else{
        this.type = "";
        this.loadList();
      }
    }
  }

  onRecievedCheckboxChange(event) {
    if (event.checked) {
      this.recievedChecked = true;
      if (this.issuedChecked) {
        // this.issuedChecked = false;
        this.type = "";
        this.loadList();
      }
      else {
        this.type = "recieved";
        this.loadList();
      }
    } else {
      this.recievedChecked = false;
      if (this.issuedChecked) {
        // this.issuedChecked = false;
        this.type = "issued";
        this.loadList();
      }
      else {
        this.type = "";
        this.loadList();
      }
    }
  }

  // count selector
  selectCountHandler(event: any) {
    this.quotationPagination.count = event.target.value;
    this.loadList();
  }

  currentPage = 1;
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.quotationPagination.page = this.currentPage;
    this.loadList();
  }

  nextClick() {
    this.currentPage = this.currentPage + 1;
    this.quotationPagination.page = this.currentPage;
    this.loadList();
  }

  loadList() {
    this.apiService
      .getListQuotations(this.quotationPagination, this.searchKeyword, this.selectedProjectID, this.type)
      .subscribe(Response => {
        this.page_increasement =
          (this.currentPage - 1) * this.quotationPagination.count;
        if (Response["status"] == 401) {
          this.route.navigateByUrl("/");
        } else {
          this.quotations = Response;
        }
      });
    this.quotationPagination.export = false;
  }
}

export interface QuotationInfo {
  title: string;
  description: string;
  quotation_number: string;
  attendant_id: string;
  attendant_name: string;
  attendant_phone_number: string;
  attendant_email: string;
  attendant_company_id: string;
  status: string;
  type: string;
  project_id: string;
  supplier_id: string;
  customer_id: string;
}

export interface QuotationFileInfo {
  quotation_id: string;
  revision: string;
  total_price: number;
  parent: string;
  issued_date: string;
  expired_date: string;
  file: File;
}
