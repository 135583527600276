import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { QuotationInfo } from "../quotation.component";
import { ApiService } from "src/app/shared/api.service";
import { QuotationService } from "../quotation.service";
import { FormControl, Validators, FormGroup } from "@angular/forms";

@Component({
  selector: "app-create-quotation-dialog",
  templateUrl: "./create-quotation-dialog.component.html",
  styleUrls: ["./create-quotation-dialog.component.scss"]
})
export class CreateQuotationDialogComponent implements OnInit {
  projects: any;
  suppliers: any;
  customers: any;
  types = ["recieved", "issued"];
  statuses = ["open", "close"];
  isRecieved = true;
  isIssued = false;
  firstTime = true;
  attendants: any;
  newAttendant = {_id: '0', name: 'Create new attendant'};
  constructor(
    public dialogRef: MatDialogRef<CreateQuotationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public quotation: QuotationInfo,
    private apiService: ApiService,
    private quotationService: QuotationService,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }


  titleFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  descriptionFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  quotationNoFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  typeFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  statusFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  projectFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  supplierFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);
  customerFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);

  attendantFormControl: FormControl = new FormControl("");
  attendantNameFormControl: FormControl = new FormControl("");
  phoneNumberFormControl: FormControl = new FormControl("");
  emailFormControl: FormControl = new FormControl("");

  


  quotationFormGroup: FormGroup = new FormGroup({
    title: this.titleFormControl,
    description: this.descriptionFormControl,
    quotation_no: this.quotationNoFormControl,
    type: this.typeFormControl,
    status: this.statusFormControl,
    project: this.projectFormControl,
    supplier: this.supplierFormControl,
    customer: this.customerFormControl,
    attendant_id: this.attendantFormControl,
    attendant_name: this.attendantNameFormControl,
    attendant_phone_number: this.phoneNumberFormControl,
    attendant_email: this.emailFormControl
  });

  getRequiredErrorMessage(field) {
    return this.quotationFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  getEmailErrorMessage() {
    return this.emailFormControl.hasError("required")
      ? "You must enter a value"
      : this.emailFormControl.hasError("email")
      ? "Not a valid email"
      : "";
  }


  ngOnInit() {
    this.quotation.type = this.types[0];
    this.quotation.status = this.statuses[0];
    this.getProjects();
    this.getCompanies();
  }


  keyDownFunction(event) {
    if(event.keyCode == 13) {
      if (!this.quotationFormGroup.invalid){
        this.dialogRef.close();
        this.handleCreateProject();
      }
    }
  }

  getProjects(){
    this.apiService.getAllProjects().subscribe(
      (projects) => {
        this.projects = [];
        this.projects = projects;
        this.quotation.project_id = projects[0]._id;
        
      }
    )
  }

  getSuppliers(){
    this.apiService.getAllSuppliers().subscribe(
      (suppliers) => {
        this.suppliers = [];
        this.suppliers = suppliers;
        this.quotation.supplier_id = suppliers[0]._id;
        if (this.firstTime) {
          this.getAttendants();
        }
      }
    )
  }

  onTypeChange(event){
    if (event.value === "recieved"){
      this.isRecieved = true;
      this.isIssued = false;
      this.onSupplierChange(null);
    }
    else{
      this.isRecieved = false;
      this.isIssued = true;
      this.onCustomerChange(null);
    }
  }

  getAttendants() {
    if (this.isIssued) {
      this.apiService.getAttendantsByCompany(this.quotation.customer_id).subscribe(
        (data: any) => {
          this.attendants = [];
          this.attendants = data;
          this.attendants.push(this.newAttendant);
        }
      );
    }

    if (this.isRecieved) {
      this.apiService.getAttendantsByCompany(this.quotation.supplier_id).subscribe(
        (data: any) => {
          this.attendants = [];
          this.attendants = data;
          this.attendants.push(this.newAttendant);
        }
      );
    }
    this.firstTime = false;
  }

  onCustomerChange(event) {
    this.getAttendants();
  }

  onSupplierChange(event) {
    this.getAttendants();
  }

  onAttendantChange(event) {
    if (event.value === '0') {
      this.quotation.attendant_name = '';
      this.quotation.attendant_phone_number = '';
      this.quotation.attendant_email = '';
      this.attendantNameFormControl.setValidators([Validators.required]);
      this.phoneNumberFormControl.setValidators([Validators.required]);
      this.emailFormControl.setValidators(null);
    } else {
      this.attendantNameFormControl.setValidators(null);
      this.phoneNumberFormControl.setValidators(null);
      this.emailFormControl.setValidators(null);
      const selectedAtt = this.attendants.filter(item => item._id === event.value)[0];
      this.quotation.attendant_name = selectedAtt.name;
      this.quotation.attendant_phone_number = selectedAtt.phone_number;
      this.quotation.attendant_email = selectedAtt.email;
    }
    console.log(this.quotation);
    this.quotationFormGroup.updateValueAndValidity();
  }

  getCustomers(){
    this.apiService.getAllCustomers().subscribe(
      (customers) => {
        this.customers = [];
        this.customers = customers;
        this.quotation.customer_id = customers[0]._id;
        if (this.firstTime) {
          this.getAttendants();
        }
      }
    )
  }

  getCompanies(){
    this.apiService.getAllCompanies().subscribe(
      (data: any) => {
        this.customers = [];
        this.suppliers = [];
        this.customers = data;
        this.suppliers = this.customers;
        this.quotation.customer_id = this.customers[0]._id;
        this.quotation.supplier_id = this.suppliers[0]._id;
        if (this.firstTime){
          this.getAttendants();
        }
      }
    )
  }

  handleCreateProject(){

    if (this.quotation.type === 'recieved') {
      this.quotation.attendant_company_id = this.quotation.supplier_id;
    } else {
      this.quotation.attendant_company_id = this.quotation.customer_id;
    }
    if (this.quotation.attendant_id === '0') {
      this.quotation.attendant_id = undefined;
    }
    
    this.apiService.createQuotation(this.quotation).subscribe(
      response => {
        this.apiService.getAllQuotations().subscribe((data: any) => {
          if (data.length > 0) {
            this.quotationService.bindQuotations(data);
          }
        });
        this.snackBar.open("Created successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Create failed!", null, {
          duration: 2000,
          panelClass: ['red-snackbar'],
          horizontalPosition: "right"
        });
      }
    );
  }


}
