import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApiService} from '../../../../shared/api.service';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-office-delete-dialog',
  templateUrl: './office-delete-dialog.component.html',
  styles: ['button { outline: none }']
})
export class OfficeDeleteDialogComponent implements OnDestroy {

  subscription = new Subscription();
  isLoading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public officeId: string,
              private ref: MatDialogRef<OfficeDeleteDialogComponent>,
              private api: ApiService) {

  }


  deleteOffice() {
    if (!this.officeId) {
      // TODO: Show error message
      return;
    }




    this.isLoading = true;
    this.api.deleteOffice(this.officeId).subscribe(response => {
      if (response.status) {
        this.ref.close(true);
      } else {
        this.isLoading = false;
        // TODO: show error message;
      }
    }, error => {
      this.isLoading = false;
      // TODO: show error message;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
