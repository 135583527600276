import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CustomerService {
  customer: Array<object> = [];
  selectedCustomer: any;
  private bs_all_customers = new BehaviorSubject<any>(this.customer);
  private bs_selected_customer = new BehaviorSubject<any>(this.selectedCustomer);



  getAllCustomers = this.bs_all_customers.asObservable();
  getSelectedCustomer = this.bs_selected_customer.asObservable();

  constructor() {}

  bindCustomers(customers: any) {
    this.bs_all_customers.next(customers);
  }

  bindSelectedCustomer(customer: any){
    this.bs_selected_customer.next(customer);
  }
}
