import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {OfficeModel} from '../../../../models/office.model';
import {ApiService} from '../../../../shared/api.service';
import {of, Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-office-dialog',
  templateUrl: './office-dialog.component.html',
  styleUrls: ['./office-dialog.component.css'],
})
export class OfficeDialogComponent implements OnDestroy {


  dialogTitle = 'Create office';
  subscription = new Subscription();
  form: FormGroup;
  isLoading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { office: OfficeModel, parent: OfficeModel },
              private api: ApiService,
              private ref: MatDialogRef<OfficeDialogComponent>,
              private formBuilder: FormBuilder) {

    this.form = formBuilder.group({
      name: ['', Validators.required],
      kh_name: ['', Validators.required],
      description: ['']
    });

    if (this.data) {
      if (this.data.office) {
        this.dialogTitle = 'Edit office';
        this.form.patchValue(this.data.office as any);
        console.log(this.data.office);
      }
    }

  }

  saveClick() {

    if (this.form.invalid) {
      return;
    }

    if (this.data) {
      if (this.data.office) {
        this.editOffice(this.data.office);
        return;
      }
    }

    this.createOffice();
  }

  editOffice(office: OfficeModel) {
    this.isLoading = true;
    this.form.disable();
    this.subscription.add(this.api.editOffice(office._id, {
      name: this.form.value.name,
      kh_name: this.form.value.kh_name,
      description: this.form.value.description,
    })
      .subscribe(response => {
        console.log(response);
        this.ref.close(response);
      }, error => {
        this.isLoading = false;
        this.form.enable();
      }));
  }

  createOffice() {

    let parentId = null;

    if (this.data) {
      if (this.data.parent) {
        parentId = this.data.parent._id;
      }
    }

    this.isLoading = true;
    this.form.disable();
    this.subscription.add(this.api.createOffice({
      name: this.form.value.name,
      kh_name: this.form.value.kh_name,
      description: this.form.value.description,
      parent_id: parentId,
    }).subscribe(response => {
      this.ref.close(response);
    }, (err) => {
      // TODO: Show error message
      this.isLoading = false;
      this.form.enable();
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
