import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Component } from '@angular/core';
import { UserService } from 'src/app/shared/user.service';
import { UserLoginService } from 'src/app/shared/userlogin.service';


@Component({
  selector: 'change-password',
  templateUrl: 'change-password.component.html',
  styleUrls: ['change-password.component.css']
})
export class ChangePasswordComponent {

  oldPassword: string = "";
  newPassword: string = "";
  confirmPassword: string = "";
  userID: string = "";
  hide_new = true;
  hide_old = true;
  hide_confirm = true;
  checkBothPassword: boolean = false;
  checkConfirmPassword: boolean = false;
  checkWrongPassword: boolean = false;

  // ===== Form Validation ======================================================
  oldPasswordFormControl: FormControl = new FormControl('', [
    Validators.required, 
    Validators.minLength(6)
  ]);

  newPasswordFormControl: FormControl = new FormControl('', [
    Validators.required, 
    Validators.minLength(6)
  ]);

  confirmPasswordFormControl: FormControl = new FormControl('', [
    Validators.required, 
    Validators.minLength(6)
  ]);

  changePasswordFormGroup: FormGroup = new FormGroup({
    old_password: this.oldPasswordFormControl,
    new_password: this.newPasswordFormControl,
    confirm_password: this.confirmPasswordFormControl
  });

  getOldPasswordErrorMessage() {
    return this.oldPasswordFormControl.hasError('required') ? 'You must enter a value' : 
      this.oldPasswordFormControl.hasError('minlength') ? 'Password must be at least 6 characters long' : '';
  }

  getNewPasswordErrorMessage() {
    return this.newPasswordFormControl.hasError('required') ? 'You must enter a value' : 
      this.newPasswordFormControl.hasError('minlength') ? 'Password must be at least 6 characters long' : '';
  }

  getConfirmPasswordErrorMessage() {
    return this.confirmPasswordFormControl.hasError('required') ? 'You must enter a value' : 
      this.confirmPasswordFormControl.hasError('minlength') ? 'Password must be at least 6 characters long' : '';
  }
  // ===========================================================================

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>, 
              public userService: UserService, 
              private apiService: ApiService,
              private userLoginService: UserLoginService,
              private snackBar: MatSnackBar) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.userID = this.userLoginService.getCurrentUserID();
  }

  handleChangePassword() {
    this.checkWrongPassword = false;
    if(this.oldPassword === this.newPassword) {
      this.checkBothPassword = true;
    } else if(this.confirmPassword !== this.newPassword) {
      this.checkConfirmPassword = true;
      this.confirmPassword = '';
    } else {
      this.apiService.changeOwnPassword(this.userID, this.newPassword, this.oldPassword).subscribe((response: any) => {
        if(response.status === -1) {
          this.checkWrongPassword = true;
          this.checkConfirmPassword = false;
          this.oldPassword = '';
        } else {
          this.snackBar.open('changed successfully', null, {duration: 2000, horizontalPosition: 'right'});
          this.dialogRef.close();
        }
      });
    }
  }
}
