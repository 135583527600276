import {HostListener, Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {share} from 'rxjs/operators';

@Injectable({ providedIn: 'root'})
export class ContentScrollService {

  private _scrollSubject = new Subject<Event>();

  public emitScroll(event: Event) {
    this._scrollSubject.next(event);
  }

  public getContentScrollObservable() {
    return this._scrollSubject.asObservable();
  }

}
