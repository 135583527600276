import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { UserInfo, UserComponent } from '../user.component';
import { UserService } from 'src/app/shared/user.service';


@Component({
  selector: 'edit-user',
  templateUrl: 'edit-user.component.html',
  styleUrls: ['edit-user.component.css']
})
export class EditUserComponent {

  roles: Array<object> = [];
  positions: Array<object> = [];
  offices: Array<object> = [];
  selectedUser: any;
  officeID: string = '';
  positionID: string = '';
  roleID: string = '';

  // ===== Form Validation ======================================================
  nameFormControl: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\S*$/)
  ]);
  firstNameFormControl = new FormControl('', [Validators.required]);
  lastNameFormControl = new FormControl('', [Validators.required]);
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  phoneFormControl = new FormControl('', [
    Validators.required
  ]);
  addressFormControl = new FormControl('', [Validators.required]);
  roleFormControl = new FormControl('', [Validators.required]);
  positionFormControl = new FormControl('', [Validators.required]);
  officeFormControl = new FormControl('', [Validators.required]);

  userFormGroup: FormGroup = new FormGroup({
    name: this.nameFormControl,
    firstname: this.firstNameFormControl,
    lastname: this.lastNameFormControl,
    email: this.emailFormControl,
    phone: this.phoneFormControl,
    address: this.addressFormControl,
    role: this.roleFormControl,
    position: this.positionFormControl,
    office: this.officeFormControl
  });

  getNameErrorMessage() {
    return this.nameFormControl.hasError('required') ? 'You must enter a value' :
      this.nameFormControl.hasError('pattern') ? 'Format must not allow space' : '';
  }
  getEmailErrorMessage() {
    return this.emailFormControl.hasError('required') ? 'You must enter a value' :
      this.emailFormControl.hasError('email') ? 'Not a valid email' : '';
  }
  getPhoneErrorMessage() {
    return this.phoneFormControl.hasError('required') ? 'You must enter a value' :
      this.phoneFormControl.hasError('pattern') ? 'Format must be (xxx) xxx-xxxx' : '';
  }
  getRequiredErrorMessage(field) {
    return this.userFormGroup.get(field).hasError('required') ? 'You must enter a value' : '';
  }
  // ===========================================================================

  constructor(public dialogRef: MatDialogRef<EditUserComponent>, 
              public userService: UserService, 
              private apiService: ApiService,
              private snackBar: MatSnackBar) { 
                dialogRef.disableClose = true;
              }

  ngOnInit() {
    this.userService.getSelectedUser.subscribe((selectedUser) => {
      this.selectedUser = selectedUser;
      if(this.selectedUser.office != null){
        this.officeID = this.selectedUser.office._id;
      } 
      if(this.selectedUser.position != null){
        this.positionID = this.selectedUser.position._id;
      } 
      if(this.selectedUser.role != null){
        this.roleID = this.selectedUser.role._id;
      } 
    });
    this.getAllRoles();
    this.getAllPositions();
    this.getAllOffices();
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.apiService.getAllUsers().subscribe((data: any) => {
      if (data.status == 1 && data.users.length > 0) {
        for(let i = 0; i< data.users.length; i++) {
          if(this.selectedUser._id == data.users[i]._id) {
            this.selectedUser.name = data.users[i].name;
            this.selectedUser.first_name = data.users[i].first_name;
            this.selectedUser.last_name = data.users[i].last_name;
            this.selectedUser.email = data.users[i].email;
            this.selectedUser.telephone = data.users[i].telephone;
            this.selectedUser.address = data.users[i].address;
            this.selectedUser.role = data.users[i].role;
            this.selectedUser.position = data.users[i].position;
            this.selectedUser.office = data.users[i].office;
          }
        }
      }
    });
  }

  getAllRoles() {
    this.apiService.getAllRoles().subscribe((data: any) => {
      if (data.status == 1 && data.roles.length > 0) {
        this.roles = [];
        this.roles = data.roles;
      }
    });
  }

  getAllPositions() {
    this.apiService.getAllPositions().subscribe((data: any) => {
      if (data.status == 1) {
        this.positions = [];
        this.positions = data.positions;
      }
    });
  }

  getAllOffices() {
    this.apiService.getAllOffices().subscribe((data: any) => {
      if (data.status == 1) {
        this.offices = [];
        this.offices = data.offices;
      }
    });
  }

  handleEditUserInfo() {
    if(this.userFormGroup.valid) {
      let newUser = {
        name: this.selectedUser.name,
        first_name: this.selectedUser.first_name,
        last_name: this.selectedUser.last_name,
        email: this.selectedUser.email,
        pwd: this.selectedUser.pwd,
        telephone: this.selectedUser.telephone,
        address: this.selectedUser.address,
        role: this.roleID,
        position: this.positionID,
        office: this.officeID
      };
      this.apiService.editUser(this.selectedUser._id, newUser).subscribe((response: any) => {
        if(response.status == 1) {
          this.snackBar.open('Updated successfully', null, {duration: 2000, horizontalPosition: 'right'});
        } else {
          this.snackBar.open('Update failed!', null, {duration: 2000, horizontalPosition: 'right'});
        }
      });
    }
  }
}
