import {Component, Inject, OnDestroy} from '@angular/core';
import {ApiService} from '../../../../shared/api.service';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {PublicHolidayModel} from '../../../../models/public-holiday.model';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-holiday-delete-dialog',
  templateUrl: './holiday-delete-dialog.component.html',
  styleUrls: ['./holiday-delete-dialog.component.scss'],
})
export class HolidayDeleteDialogComponent implements OnDestroy {
  subscription = new Subscription();
  isLoading = false;


  constructor(private api: ApiService,
              private ref: MatDialogRef<HolidayDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public holiday: PublicHolidayModel,
              private snackBar: MatSnackBar) {
                ref.disableClose = true;
  }

  onNoClick(): void {
    this.ref.close();
  }

  deleteHoliday() {
    if (!this.holiday) {
      this.snackBar.open('Delete failed!', null, {duration: 2000, horizontalPosition: 'right'});
      return;
    }

    this.isLoading = true;
    this.api.deletePublicHoliday(this.holiday._id).subscribe(response => {
      if (response.status) {
        this.snackBar.open('Deleted successfully', null, {duration: 2000, horizontalPosition: 'right'});
        this.ref.close(true);
      } else {
        this.isLoading = false;
        this.snackBar.open('Delete failed!', null, {duration: 2000, horizontalPosition: 'right'});
      }
    }, error => {
      this.isLoading = false;
      this.snackBar.open('Delete failed!', null, {duration: 2000, horizontalPosition: 'right'});
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
