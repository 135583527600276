import { Component, OnInit, Injectable, Directive } from '@angular/core';
import { ApiService } from '../../../../../shared/api.service';
import { DocumentService } from '../../../../../shared/document.service';

@Component({
  selector: 'app-directory-block',
  templateUrl: './directory-block.component.html',
  styleUrls: ['./directory-block.component.css'],
})
export class DirectoryBlockComponent implements OnInit {

  directories: Array<object> = [];
  subDirectories: Array<object> = [];
  //activeDirectory: string = "";
  isUpdated: boolean;
  dir_name: string;
  dir_id: any;
  isShow: boolean;
  show: boolean = true;
  sub_item: Array<object>;
  files: Array<object> = [];
  listSelectedID: Array<string> = [];


  constructor(private apiService: ApiService, private documentService: DocumentService) {
    this.apiService.isUpdated.subscribe(value => {
      this.isUpdated = value;
    });
  }

  ngOnInit() {
    this.documentService.newListDir.subscribe(directories => this.directories = directories);
    this.documentService.getListSelectedID.subscribe((listSelectedID: any) => {
      this.listSelectedID = listSelectedID;
    });
    this.showSubDefaultDir();
  }

  public showSubDefaultDir() {
    this.apiService.getDirectories().subscribe((data: any) => {
      if (data.status == 1 && data.directories.length > 0) {
        this.directories = [];
        this.documentService.activeDirectory = data.directories[0]._id;
        this.directories = data.directories;
        this.subDirectories = [];

        this.subDirectories.push(data.directories[0].childs);
        this.documentService.bindSubDir(this.subDirectories[0]);
        this.documentService.bindClickDirectory(data.directories[0]);
        this.documentService.bindParentID(data.directories[0]._id);
        this.documentService.updateDirBlock(data.directories);

        this.apiService.getSubItems(data.directories[0]._id).subscribe((data: any) => {
          this.documentService.bindFiles(data.files);
        });

        let arrPath = ["", data.directories[0].name];
        this.documentService.bindPath(arrPath);
      }
    });
  }

  selectDirectory(obj: object) {
    this.apiService.getSubItems(obj[1]).subscribe((data: any) => {
      if (data.directories != []) this.documentService.bindSubDir(data.directories);
      if (data.files != []) this.documentService.bindFiles(data.files);
      this.documentService.bindParentID(obj[1]);
      for (let i = 0; i < this.directories.length; i++) {
        if (this.recursiveBinding(this.directories[i], obj[1])) {
          break;
        };
      }
    });
  }

  recursiveBinding(dir: any, obj_id: any) {
    if (dir._id == obj_id) {
      this.documentService.bindClickDirectory(dir);
      let getPath = dir.path.split('/');
      getPath.push(dir.name);
      this.documentService.bindPath(getPath);
      return true;
    } else if (dir.childs != []) {
      for (let i = 0; i < dir.childs.length; i++) {
        if (this.recursiveBinding(dir.childs[i], obj_id)) {
          break;
        };
      }
    }
  }
}

