import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { DocumentService } from 'src/app/shared/document.service';

@Component({
    selector: 'download-file',
    templateUrl: 'download-file.component.html',
    styleUrls: ['download-file.component.css']
  })
  export class DownloadFileComponent {
  
    constructor(public dialogRef: MatDialogRef<DownloadFileComponent>, 
      @Inject(MAT_DIALOG_DATA) public object: any, 
      private apiService: ApiService, 
      private documentService: DocumentService) { 
        dialogRef.disableClose = true;
      }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
   
    ngOnInit() {
      
    }
  
    handleDownloadFile() {
      this.apiService.downloadFile(this.object.file);
    }
  }