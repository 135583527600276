import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { UserService } from 'src/app/shared/user.service';

@Component({
    selector: 'delete-contact',
    templateUrl: 'delete-contact.component.html',
    styleUrls: ['delete-contact.component.scss']
  })
  export class DeleteContactComponent {

    isDeletedContact: boolean = false;
  
    constructor(public dialogRef: MatDialogRef<DeleteContactComponent>, 
                @Inject(MAT_DIALOG_DATA) public object: any, 
                private apiService: ApiService, 
                private userService: UserService,
                private snackBar: MatSnackBar) { 
                  dialogRef.disableClose = true;
                }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
   
    ngOnInit() {
    }
  
    handleDeleteContact() {
      this.isDeletedContact = true;
      this.apiService.deleteContact(this.object.contact_id).subscribe((response: any) => {
        this.snackBar.open('Deleted successfully', null, {duration: 2000, horizontalPosition: 'right'});
      });
    }
  }