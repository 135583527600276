import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatDatepickerInputEvent
} from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { ProjectService } from "../project.service";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-edit-project-dialog",
  templateUrl: "./edit-project-dialog.component.html",
  styleUrls: ["./edit-project-dialog.component.scss"],
  providers: [DatePipe]
})
export class EditProjectDialogComponent implements OnInit {
  selectedProject: any;
  customers: any;
  currentDate = new Date();
  maxDate: Date;
  minDate: Date;
  start_date: Date = this.currentDate;
  end_date: Date = this.currentDate;

  constructor(
    public dialogRef: MatDialogRef<EditProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private projectService: ProjectService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar
  ) {
    dialogRef.disableClose = true;
  }

  projectNameFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  descriptionFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  projectManagerFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  customerFormControl: FormControl = new FormControl("", [Validators.required]);
  startDateFormControl: FormControl = new FormControl(this.currentDate, [
    Validators.required
  ]);
  endDateFormControl: FormControl = new FormControl("", [Validators.required]);

  projectFormGroup: FormGroup = new FormGroup({
    name: this.projectNameFormControl,
    description: this.descriptionFormControl,
    manager: this.projectManagerFormControl,
    customer: this.customerFormControl,
    start_date: this.startDateFormControl,
    end_date: this.endDateFormControl
  });

  ngOnInit() {
    console.log(this.object);

    this.projectService.getSelectedProject.subscribe(
      selectedProject => (this.selectedProject = selectedProject)
    );
    this.getCustomers();
  }

  getCustomers() {
    this.apiService.getAllCompanies().subscribe(response => {
      this.customers = [];
      this.customers = response;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.apiService.getAllCustomers().subscribe((data: any) => {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (this.selectedProject._id == data[i]._id) {
            this.selectedProject.name = data[i].name;
            this.selectedProject.description = data[i].description;
            this.selectedProject.manager = data[i].manager;
            this.selectedProject.customer = data[i].customer;
            this.selectedProject.start_date = data[i].start_date;
            this.selectedProject.end_date = data[i].end_date;
          }
        }
      }
    });
  }

  startDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.start_date = event.value;
    this.minDate = new Date(event.value);
  }

  endDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.end_date = event.value;
    this.maxDate = new Date(event.value);
  }

  handleEditProject() {
    let newProject = {
      name: this.object.project.name,
      description: this.object.project.description,
      manager: this.object.project.manager,
      customer_id: this.object.project.customer._id,
      start_date: this.datePipe.transform(this.object.project.start_date, 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.object.project.end_date, 'yyyy-MM-dd')
    };
    this.apiService.editProject(this.object.project._id, newProject).subscribe(
      response => {
        this.snackBar.open("Updated successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
        this.apiService.getAllProjects().subscribe((data: any) => {
          if (data.length > 0) {
            this.projectService.bindProjects(data);
          }
        });
      },
      error => {
        this.snackBar.open("Update failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      if (!this.projectFormGroup.invalid) {
        this.dialogRef.close();
        this.handleEditProject();
      }
    }
  }
}
