import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLoginService } from '../shared/userlogin.service';
import { Sale } from '../models/salse.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../shared/user.service';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  username;
  password;
  serverError: string = "";
  sale = new Sale();
  returnUrl: string = '';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userService: UserService, 
              private userLoginService: UserLoginService, 
              private spinner: NgxSpinnerService) { }

  ngOnInit() {

    this.userLoginService.getListType(this.sale).subscribe( Response => {
      if(Response['status'] == 401){
        this.router.navigateByUrl('/');
      } else {
        // this.router.navigateByUrl('admin/dashboard/income');
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.userLoginService.setReturnURL(this.returnUrl);
      }
    }); 
  }
  
  loginUser(){
    this.spinner.show();
    this.userLoginService.login(this.username, this.password).subscribe((response : any) => {
      if(response['status'] == 1){
        this.userLoginService.setToken(response['token']);
        this.userLoginService.setUsername(response['user']['first_name'] + " " + response['user']['last_name']);
        this.userLoginService.setCurrentUserID(response.user._id);
        this.userLoginService.setCurrentUserRoleName(response.user.role.role_name);

        console.log("Return URL: " + this.returnUrl)

        if(this.userLoginService.getCurrentUserRoleName() !== "Staff" && this.userLoginService.getCurrentUserRoleName() !== "Manager"){
          this.router.navigateByUrl('admin/dashboard/income');
        } 
        else if(this.returnUrl == '/') {
          this.router.navigateByUrl('admin/dashboard/attendances');
        } 
        else {
          this.router.navigateByUrl('admin/dashboard/leave-requests');
        }
        
        this.spinner.hide();
  
      }else{
        this.serverError = response['message'];
        this.spinner.hide();
      }
    })

    
  }


}
