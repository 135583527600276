import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  users: Array<object> = [];
  positions: Array<object> = [];
  selectedUser: any;
  selectedPosition: any;

  private bs_all_users = new BehaviorSubject<any>(this.users);
  private bs_all_positions = new BehaviorSubject<any>(this.positions);
  private bs_selected_user = new BehaviorSubject<any>(this.selectedUser);
  private bs_selected_position = new BehaviorSubject<any>(this.selectedPosition);

  getAllUsers = this.bs_all_users.asObservable();
  getAllPositions = this.bs_all_positions.asObservable();
  getSelectedUser = this.bs_selected_user.asObservable();
  getSelectedPosition = this.bs_selected_position.asObservable();
  
  bindUsers(users: any) {
    this.bs_all_users.next(users);
  }

  bindPositions(positions: any) {
    this.bs_all_positions.next(positions);
  }
  
  bindSelectedUser(user: any) {
    this.bs_selected_user.next(user);
  }

  bindSelectedPosition(position: any) {
    this.bs_selected_position.next(position);
  }
}
