import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName'
})
export class FileNamePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let stringArray = value.split("/");
    let result = stringArray.pop();
    return result;
  }

}
