import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { DocumentService } from 'src/app/shared/document.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'rename-dir',
  templateUrl: 'rename-dir.component.html',
  styleUrls: ['rename-dir.component.css']
})
export class RenameDirectoryComponent {

  parentID: string = '';
  dirName: string = '';

  // ===== Form Validation ======================================================
  nameFormControl = new FormControl('', [Validators.required]);

  dirFormGroup: FormGroup = new FormGroup({
    name: this.nameFormControl
  });

  getRequiredErrorMessage(field) {
    return this.dirFormGroup.get(field).hasError('required') ? 'You must enter a value' : '';
  }
  // ===========================================================================

  constructor(public dialogRef: MatDialogRef<RenameDirectoryComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private documentService: DocumentService,
    private snackBar: MatSnackBar) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.dirName = this.object.dir.name;
    this.documentService.getParentID.subscribe(parentID => this.parentID = parentID);
  }

  handleRenameDirectory(dir: any) {
    if (this.dirFormGroup.valid) {
      this.apiService.getDirectories().subscribe((data: any) => {
        if (data.status == 1 && data.directories.length > 0) {
          for (let i = 0; i < data.directories.length; i++) {
            if (this.recursiveBinding(data.directories[i], dir._id)) {
              break;
            };
          }
          this.documentService.updateDirBlock(data.directories);
        }
      });
    }
  }

  recursiveBinding(dir: any, obj_id: any) {
    if (dir._id == obj_id) {
      this.documentService.bindClickDirectory(dir);
      let newDirectory = {
        name: this.dirName,
        path: dir.path,
        parent: this.parentID,
        order_number: dir.order_number
      }
      this.apiService.renameItem(newDirectory, obj_id).subscribe((response: any) => {
        this.apiService.getSubItems(this.parentID).subscribe((data: any) => {
          this.documentService.bindSubDir(data.directories);
        });
        this.documentService.directoriesChangeStatus = true;

        this.apiService.getDirectories().subscribe((data: any) => {
          if (data.status == 1 && data.directories.length > 0) {
            this.documentService.updateDirBlock(data.directories);
          }
        });
        this.dialogRef.close();
        if (response.status == 1) {
          this.snackBar.open('Renamed successfully', null, { duration: 2000, horizontalPosition: 'right' });
        } else {
          this.snackBar.open(response.message, null, { duration: 2000, horizontalPosition: 'right' });
        }
      });
      return true;
    } else if (dir.childs != []) {
      for (let i = 0; i < dir.childs.length; i++) {
        if (this.recursiveBinding(dir.childs[i], obj_id)) {
          break;
        };
      }
    }
  }
}