import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Inject, Component } from '@angular/core';
import { DirInfo } from '../top-url.component';
import { DocumentService } from 'src/app/shared/document.service';

@Component({
  selector: 'create-dir',
  templateUrl: 'create-dir.component.html',
  styleUrls: ['create-dir.component.css']
})
export class CreateDirectoryComponent {
  clicked_dir: any;
  selectedIDList: Array<string> = [];

  // ===== Form Validation ======================================================
  nameFormControl = new FormControl('', [Validators.required]);

  dirFormGroup: FormGroup = new FormGroup({
    name: this.nameFormControl
  });

  getRequiredErrorMessage(field) {
    return this.dirFormGroup.get(field).hasError('required') ? 'You must enter a value' : '';
  }
  // ===========================================================================

  constructor(public dialogRef: MatDialogRef<CreateDirectoryComponent>,
    @Inject(MAT_DIALOG_DATA) public dir: DirInfo,
    private apiService: ApiService,
    private documentService: DocumentService,
    private snackBar: MatSnackBar) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.documentService.clickedDir.subscribe(clicked_dir => this.clicked_dir = clicked_dir);
  }

  handleCreateDirectory() {
    if (this.dirFormGroup.valid) {
      let directory = {
        name: this.dir.name,
        path: `${this.clicked_dir.path}/${this.clicked_dir.name}`,
        parent: `${this.clicked_dir._id}`,
        childs: []
      };
      if (this.clicked_dir.path == "/") {
        directory = {
          name: this.dir.name,
          path: `/${this.clicked_dir.name}`,
          parent: `${this.clicked_dir._id}`,
          childs: []
        };
      }

      this.apiService.createDirectory(directory).subscribe((response: any) => {
        this.apiService.getDirectories().subscribe((data: any) => {
          if (data.status == 1 && data.directories.length > 0) {
            this.documentService.updateDirBlock(data.directories);
          }
        });
        this.apiService.getSubItems(this.clicked_dir._id).subscribe((data: any) => {
          this.documentService.bindSubDir(data.directories);
          this.documentService.directoriesChangeStatus = true;

          this.selectedIDList = [];
          this.apiService.getDirectories().subscribe((data: any) => {
            if (data.status == 1 && data.directories.length > 0) {
              for (let i = 0; i < data.directories.length; i++) {
                this.selectedIDList = [];
                if (this.recursiveFindDir(data.directories[i], this.clicked_dir._id)) {
                  break;
                }
              }
            }
          });
        });
        this.dialogRef.close();
        if (response.status == 1) {
          this.snackBar.open('Created successfully', null, { duration: 2000, horizontalPosition: 'right' });
        } else {
          this.snackBar.open(response.message, null, { duration: 2000, horizontalPosition: 'right' });
        }
      });
    }
  }

  recursiveFindDir(dir: any, obj_id: string) {
    this.selectedIDList.push(dir._id);
    if (dir._id == obj_id) {
      console.log(this.selectedIDList);
      this.documentService.bindListSelectedID(this.selectedIDList);
      return true;
    } else if (dir.childs != []) {
      for (let i = 0; i < dir.childs.length; i++) {
        this.selectedIDList.splice(-1, 1).push(dir.childs[i]._id);
        if (this.recursiveFindDir(dir.childs[i], obj_id)) {
          break;
        };
      }
    }
  }
}