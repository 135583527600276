import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { CustomerInfo } from "../../customer.component";
import { ApiService } from "src/app/shared/api.service";
import { CustomerService } from "../../customer.service";

@Component({
  selector: "app-create-customer",
  templateUrl: "./create-customer.component.html",
  styleUrls: ["./create-customer.component.scss"]
})
export class CreateCustomerComponent implements OnInit {

  attendants: any;
  newAttendant = {_id: '0', name: 'New'};
  selectedAttendant: any;
  ngOnInit() {
    this.getAllAttendants();
    this.checkIfNeededToAddValidation();
  }

  constructor(
    public dialogRef: MatDialogRef<CreateCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public customer: CustomerInfo,
    private apiService: ApiService,
    private customerService: CustomerService,
    private snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef
  ) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    this.cdRef.detectChanges();
  }

  companyNameFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  attendantFormControl: FormControl = new FormControl("");
  phoneNumberFormControl: FormControl = new FormControl("");
  emailFormControl: FormControl = new FormControl("");
  companyAddressFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);
  websiteFormControl: FormControl = new FormControl("", [
    Validators.nullValidator
  ]);

  attendantSelectFormControl: FormControl = new FormControl("", [
    Validators.required
  ]);

  customerFormGroup: FormGroup = new FormGroup({
    company_name: this.companyNameFormControl,
    attendant: this.attendantFormControl,
    phone_number: this.phoneNumberFormControl,
    email: this.emailFormControl,
    company_address: this.companyAddressFormControl,
    website: this.websiteFormControl
  });

  getRequiredErrorMessage(field) {
    return this.customerFormGroup.get(field).hasError("required")
      ? "You must enter a value"
      : "";
  }

  getEmailErrorMessage() {
    return this.emailFormControl.hasError("required")
      ? "You must enter a value"
      : this.emailFormControl.hasError("email")
      ? "Not a valid email"
      : "";
  }

  handleCreateCustomer() {

    if (this.selectedAttendant !== "0") {
      this.customer.attendant_id = this.selectedAttendant;
      this.customer.name = undefined;
      this.customer.email = undefined;
      this.customer.phone_number = undefined;
    }
    this.apiService.createCustomer(this.customer).subscribe(
      response => {
        this.apiService.getAllCustomers().subscribe((data: any) => {
          if (data.length > 0) {
            this.customerService.bindCustomers(data);
          }
        });
        this.snackBar.open("Created successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Create failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }

  keyDownFunction(event) {
    if(event.keyCode == 13) {
      if (!this.customerFormGroup.invalid){
        this.dialogRef.close();
        this.handleCreateCustomer();
      }
    }
  }

  getAllAttendants() {
    this.attendants = [];
    this.apiService.getAllAttendants().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.attendants = data;
          this.attendants.push(this.newAttendant);
        }
      },
      (error) => {
        this.attendants = [];
      }
    );
  }

  onAttendantChange(event) {
    this.selectedAttendant = event.value;
    if (this.selectedAttendant === "0" ) {
      this.attendantFormControl.setValidators([Validators.required]);
      this.phoneNumberFormControl.setValidators([Validators.required]);
      this.emailFormControl.setValidators([Validators.required, Validators.email]);
    } else {
      this.attendantFormControl.setValidators(null);
      this.phoneNumberFormControl.setValidators(null);
      this.emailFormControl.setValidators(null);
    }
    this.customerFormGroup.updateValueAndValidity();
    console.log("update validity");
  }

  checkIfNeededToAddValidation(){
    if (this.attendants.length === 1){
      this.attendantFormControl.setValidators([Validators.required]);
      this.phoneNumberFormControl.setValidators([Validators.required]);
      this.emailFormControl.setValidators([Validators.required, Validators.email]);
    }
    else {
      this.attendantFormControl.setValidators(null);
      this.phoneNumberFormControl.setValidators(null);
      this.emailFormControl.setValidators(null);
    }
    this.customerFormGroup.updateValueAndValidity();
  }
  
}
