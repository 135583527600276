import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from "@angular/material";
import { ApiService } from "src/app/shared/api.service";
import { CustomerService } from "../customer.service";

@Component({
  selector: "app-delete-customer-dialog",
  templateUrl: "./delete-customer-dialog.component.html",
  styleUrls: ["./delete-customer-dialog.component.scss"]
})
export class DeleteCustomerDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any,
    private apiService: ApiService,
    private customerService: CustomerService,
    private snackBar: MatSnackBar
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

  handleDeleteCustomer() {
    this.apiService.deleteCustomer(this.object.customer_id).subscribe(
      (response: any) => {
        this.apiService.getAllCustomers().subscribe((data: any) => {
          this.customerService.bindCustomers(data);
        });
        this.snackBar.open("Deleted successfully", null, {
          duration: 2000,
          horizontalPosition: "right"
        });
      },
      error => {
        this.snackBar.open("Delete failed!", null, {
          duration: 2000,
          panelClass: ["red-snackbar"],
          horizontalPosition: "right"
        });
      }
    );
  }
}
