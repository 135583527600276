import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { CreateCustomerComponent } from "./create-customer-dialog/create-customer/create-customer.component";
import { ApiService } from "src/app/shared/api.service";
import { CustomerService } from "./customer.service";
import { CustomerPagination } from "src/app/models/customer.model";
import { Router } from "@angular/router";
import { DeleteCustomerDialogComponent } from "./delete-customer-dialog/delete-customer-dialog.component";
import { EditCustomerDialogComponent } from "./edit-customer-dialog/edit-customer-dialog.component";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"]
})
export class CustomerComponent implements OnInit {
  customers: any;
  isLoading = true;
  page_increasement = 0;
  customerPagination = new CustomerPagination();
  searchKeyword: string = "";

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private customerService: CustomerService,
    private route: Router
  ) {}

  ngOnInit() {
    this.getAllCustomers();
    this.customerService.getAllCustomers.subscribe(
      customers => (this.customers = customers)
    );
  }

  getAllCustomers() {
    this.isLoading = true;
    this.apiService.getAllCustomers().subscribe(
      (data: any) => {
        if (data.length > 0) {
          this.customers = [];
          this.customers = data;
          console.log(this.customers);
          this.isLoading = false;
        }
      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }

  openCreateCustomerDialog(): void {
    const dialogRef = this.dialog.open(CreateCustomerComponent, {
      width: "800px",
      height: "auto",
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  openDeleteCustomerDialog(customerID: string): void {
    const dialogRef = this.dialog.open(DeleteCustomerDialogComponent, {
      width: "400px",
      height: "auto",
      data: {
        customer_id: customerID
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  openEditCustomerDialog(customer: any): void {
    this.customerService.bindSelectedCustomer(customer);
    const dialogRef = this.dialog.open(EditCustomerDialogComponent, {
      width: "800px",
      height: "auto",
      data: {
        customer: customer
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.loadList();
    });
  }

  handleSearch(){
    this.loadList();
  }

  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this.handleSearch();
    }
  }

  onSearchChange(event){
    if (event.target.value === ''){
      this.handleSearch();
    }
  }

  // count selector
  selectCountHandler(event: any) {
    this.customerPagination.count = event.target.value;

    this.loadList();
  }

  currentPage = 1;
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.customerPagination.page = this.currentPage;
    this.loadList();
  }

  nextClick() {
    this.currentPage = this.currentPage + 1;
    this.customerPagination.page = this.currentPage;
    this.loadList();
  }

  loadList() {
    this.apiService
      .getListCustomers(this.customerPagination, this.searchKeyword)
      .subscribe(Response => {
        this.page_increasement =
          (this.currentPage - 1) * this.customerPagination.count;
        if (Response["status"] == 401) {
          this.route.navigateByUrl("/");
        } else {
          this.customers = Response;
        }
      });
    this.customerPagination.export = false;
  }
}

export interface CustomerInfo {
  company_name: string;
  name: string;
  attendant_id: string;
  phone_number: string;
  email: string;
  company_address: string;
  website: string;
}
