import { Component, OnInit, Inject } from "@angular/core";
import { QuotationFilePagination } from "src/app/models/quotation-file.model";
import { ApiService } from "src/app/shared/api.service";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { QuotationFileService } from "../quotation-file.service";
import { DeleteQuotationFileDialogComponent } from "../delete-quotation-file-dialog/delete-quotation-file-dialog.component";
import { EditQuotationFileDialogComponent } from "../edit-quotation-file-dialog/edit-quotation-file-dialog.component";
import { UserLoginService } from "src/app/shared/userlogin.service";

@Component({
  selector: "app-quotation-file-display-dialog",
  templateUrl: "./quotation-file-display-dialog.component.html",
  styleUrls: ["./quotation-file-display-dialog.component.scss"]
})
export class QuotationFileDisplayDialogComponent implements OnInit {
  quotationFilePagination = new QuotationFilePagination();
  isLoading = true;
  quotationFiles: any;
  page_increasement = 0;
  fileLinks: any;

  constructor(
    private userToken: UserLoginService,
    private dialog: MatDialog,
    private apiService: ApiService,
    private route: Router,
    private quotationFileService: QuotationFileService,
    public dialogRef: MatDialogRef<QuotationFileDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public object: any
  ) {}

  ngOnInit() {
    this.getAllQuotationFiles();
    this.quotationFileService.getAllQuotationFiles.subscribe(quotationFiles => {
      this.quotationFiles = quotationFiles;
    });
  }

  getAllQuotationFiles() {
    this.isLoading = true;
    this.apiService.getAllQuotationFiles(this.object.quotation._id).subscribe(
      (quotation_files: any) => {
        if (quotation_files.length > 0) {
          this.quotationFiles = [];
          this.quotationFiles = quotation_files;
        }
        this.isLoading = false;
        console.log(this.quotationFiles);
      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }

  // count selector
  selectCountHandler(event: any) {
    this.quotationFilePagination.count = event.target.value;
    this.loadList();
  }

  currentPage = 1;
  previousClick() {
    this.currentPage = this.currentPage - 1;
    this.quotationFilePagination.page = this.currentPage;
    this.loadList();
  }

  openDeleteQuotationFileDialog(quotationFile: any): void {
    const dialogRef = this.dialog.open(DeleteQuotationFileDialogComponent, {
      width: "400px",
      height: "auto",
      data: {
        quotation_file: quotationFile
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  openEditQuotationFileDialog(quotationFile: any) {
    const dialogRef = this.dialog.open(EditQuotationFileDialogComponent, {
      width: "800px",
      height: "auto",
      data: {
        quotation_file: quotationFile
      }
    });
    dialogRef.afterClosed().subscribe(result => {});
  }

  downloadQuotationFile(quotationFile: any) {
    this.apiService.downloadQuotationFile(
      quotationFile.file,
      quotationFile.revision
    );
  }

  viewFileInNewTap(quotationFile: any) {
    window.open(quotationFile.file, "_blank");
  }

  // downloadFile(data: any) {
  //   console.log(data);

  //   const blob = new Blob([data], { type: "blob" });
  //   const url = window.URL.createObjectURL(blob);
  //   window.open(url);
  // }

  nextClick() {
    console.log("next is clicked");
    this.currentPage = this.currentPage + 1;
    this.quotationFilePagination.page = this.currentPage;
    this.loadList();
  }

  loadList() {
    this.apiService
      .getListQuotationFiles(
        this.quotationFilePagination,
        this.object.quotation._id
      )
      .subscribe(Response => {
        this.page_increasement =
          (this.currentPage - 1) * this.quotationFilePagination.count;
        if (Response["status"] == 401) {
          this.route.navigateByUrl("/");
        } else {
          this.quotationFiles = Response;
        }
      });
    this.quotationFilePagination.export = false;
  }

  getFileLink(projectFile: any) {
    this.fileLinks = [];
    this.fileLinks = projectFile.file;
  }

  viewFile(file: any) {
    window.open(file.url + `?token=${this.userToken.getToken()}`, "_blank");
  }

  downloadFile(file: any) {
    this.apiService.downloadQuotationFile(file.url + `?token=${this.userToken.getToken()}`, file.name);
  }
}
