import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ApiService } from 'src/app/shared/api.service';
import { Component, Inject } from '@angular/core';
import { UserService } from 'src/app/shared/user.service';


@Component({
  selector: 'edit-position',
  templateUrl: 'edit-position.component.html',
  styleUrls: ['edit-position.component.css']
})
export class EditPositionComponent {

  selectedPosition: any;
  
  // ===== Form Validation ======================================================
  nameFormControl: FormControl = new FormControl('', [Validators.required]);
  nameEnFormControl = new FormControl('', [Validators.required]);
  descriptionFormControl = new FormControl('', [Validators.required]);

  positionFormGroup: FormGroup = new FormGroup({
    name: this.nameFormControl,
    name_en: this.nameEnFormControl,
    description: this.descriptionFormControl
  });

  getRequiredErrorMessage(field) {
    return this.positionFormGroup.get(field).hasError('required') ? 'You must enter a value' : '';
  }
  // ===========================================================================

  constructor(public dialogRef: MatDialogRef<EditPositionComponent>, 
              @Inject(MAT_DIALOG_DATA) public object: any, 
              public userService: UserService, 
              private apiService: ApiService,
              private snackBar: MatSnackBar) { 
                dialogRef.disableClose = true;
              }

  ngOnInit() {
    this.userService.getSelectedPosition.subscribe((selectedPosition) => {
      this.selectedPosition = selectedPosition;
    });
    this.getAllPositions();
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.apiService.getAllPositions().subscribe((data: any) => {
      if (data.status == 1 && data.positions.length > 0) {
        for(let i = 0; i< data.positions.length; i++) {
          if(this.selectedPosition._id == data.positions[i]._id) {
            this.selectedPosition.name = data.positions[i].name;
            this.selectedPosition.name_en = data.positions[i].name_en;
            this.selectedPosition.description = data.positions[i].description;
          }
        }
      }
    });
  }

  handleEditPosition() {
    if(this.positionFormGroup.valid) {
      let newPosition = {
        name: this.object.position.name,
        name_en: this.object.position.name_en,
        description: this.object.position.description
      };
      this.apiService.editPosition(this.object.position._id, newPosition).subscribe((response: any) => {
        if(response.status == 1) {
          this.snackBar.open('Updated successfully', null, {duration: 2000, horizontalPosition: 'right'});
        } else {
          this.snackBar.open('Update failed!', null, {duration: 2000, horizontalPosition: 'right'});
        }

        this.getAllPositions();
        this.dialogRef.close();
      });
    }
  }

  getAllPositions() {
    this.apiService.getAllPositions().subscribe((data: any) => {
      if (data.status == 1 && data.positions.length > 0) {
        this.userService.bindPositions(data.positions);
      }
    });
  }
}
